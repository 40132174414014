import React from "react";
import { Link } from "react-router-dom"

const BuyOrder_Accepted = (props) => { 

    const closeBuyAccepted = () => {
        window.location.reload()
        // document.getElementById("buyorder-accepted").style.display = "none"
    }

    return (
        <>
                <div className="w3-modal" id="buyorder-accepted">
                    <div className="offer--details-modal2 w3-modal-content w3-animate-bottom">
                        <i className="offer--details-modal2-icon1 fa-solid fa-thumbs-up"></i>
                        <p className="offer--details-modal2-t1">BuyOrder Accepted</p>
                        <p className="offer--details-modal2-t2">Please go to your profile (Buy Orders) to check if the buyer has Paid your order</p>
                        <Link to="../profile" className="link"><button onClick={closeBuyAccepted} className="offer--details-modal2-btn1">Go to Profile</button></Link>
                        <button onClick={props.openedBuyOrderAccepted} className="offer--details-modal2-btn2">Close</button>
                    </div>
                </div>
        </>
    )
}

export default BuyOrder_Accepted