import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import Home_Header from "../components/Home_Header"
import Home_Footer from "../components/Home_Footer"
import { useConnect, useSwitchNetwork, useSignMessage, useBalance, useAccount, useDisconnect } from 'wagmi'
import { verifyMessage } from 'ethers/lib/utils'

const ConnectWallet = (props) => {

    const [ theAddress, setTheAddress ] = useState("")
    const [ theBalance, setTheBalance ] = useState("")

    const headerToOthers1 = (addressData) => {
        setTheAddress(addressData)
    }

    const { connect, connectors, error, isLoading, pendingConnector } = useConnect({
        onSuccess(data) {
          console.log(data)
            setTimeout(() => {
                signMessage()
            }, 2000)
        localStorage.setItem("connectedAddress", data.account)
        console.log(data.account)
        console.log(data.chain.id)
            if (data.chain.id !== "80001") {
                console.log("incorrect network")
                setTimeout(() => {
                    blaFunc()
                }, 2000)
            }
        },
      })

      const { address, isConnected, isDisconnected } = useAccount()

    useEffect(() => {
        if (isConnected) {
            setTheAddress(address)
            console.log(address)
          }
    }, [isConnected])

      const { chains, theError, theIsLoading, pendingChainId, switchNetwork } =
      useSwitchNetwork({
        chainId: 80001,
      })

      const { data, signError, isLoading: signIsLoading, signIsSuccess, signMessage } = useSignMessage({
        message: "P2PFi",
        onSuccess(data, variables) {
            // Verify signature when sign message succeeds
            const address = verifyMessage(variables.message, data)
            console.log(address)
            // newButton()
          },
      })

    const blaFunc = () => {
        document.querySelector("#switchNetwork").click()
    }

    const { disconnect } = useDisconnect()


    return(
        <>

                <Home_Header 
                account = {theAddress}
                headerToOthers1 = {headerToOthers1}
                apiProfileImage = {props.apiProfileImage}
                apiUsername = {props.apiUsername}
                />
                    <div className="connect" style={{"marginTop": "62px"}}>

                        {/* New Button to go to Profile */}

                        {
                            isConnected ? 
                            <>
                                <div id="newButton" style={{"display": "block"}}>
                                <p className="connect--t1">Connect Wallet</p>
                                <p className="connect--t2">You are already connected to a wallet. Be sure to confirm the error message below.</p>
                                    <Link to="../profile" className="link">
                                    <div className="connect--div1 w3-animate-left">
                                        <img src="/images/injected.png"
                                        className="connect--img1"
                                        alt=""
                                        />
                                        <span className="connect--t3">Go to Profile</span>
                                        <p className="connect--tag w3-right">Popular</p>
                                    </div>
                                    </Link>

                                    <Link to="../settings" className="link">
                                        <div className="connect--div1 w3-animate-right">
                                            <img src="/images/gear.png"
                                            className="connect--img2"
                                            alt=""
                                            />
                                            <span className="connect--t3">Go to Settings</span>
                                        </div>
                                    </Link>
                                        <div onClick={() => disconnect()} className="connect--div2 w3-animate-right">
                                            {/* <img src="/images/wifi.webp"
                                            className="connect--img2"
                                            alt=""
                                            /> */}
                                            <i className="fa-brands fa-connectdevelop connect--img2" style={{"fontSize": "28px"}}></i>
                                            <span className="connect--t3" style={{"position": "relative", "top": "-3px"}}>Disconnect</span>
                                        </div>
                                        {
                                            signIsLoading ? <p style={{"position": "relative", "top": "14px", "color": "red", "textAlign": "center"}}>! Check Wallet for Signature Message !</p> : ""
                                        }
                                </div>
                            </>
                            :
                            <>
                                <p className="connect--t1">Connect Wallet</p>
                                <p className="connect--t2">You are not connected yet, you can select a provider and connect now.</p>
                                <button style={{"filter": "blur(100px)"}} id="switchNetwork" onClick={() => switchNetwork()}>SwithBtn</button>
                                <div id="enableEthereumButton">
                                    {connectors.map((connector) => (
                                        <>
                                        <div 
                                        disabled={!connector.ready}
                                        key={connector.id}
                                        onClick={() => connect({ connector })} 
                                        className="connect--div2 w3-animate-right" 
                                        style={{"marginBottom": "24px"}}
                                        >
                                            <img src={connector.name == "MetaMask" && "/images/metamask.png" || connector.name == "Coinbase Wallet" && "/images/coinbase.png" || connector.name == "WalletConnect" && "/images/walletconnect.png" || connector.name == "Injected" && "/images/injected.png" }
                                            className="connect--img2"
                                            alt=""
                                            />
                                            <span className="connect--t3">{connector.name}</span>
                                            {/* <span id="theConnect" style={{"color": "transparent", "backgroundColor": "transparent", "filter": "blur(0px)", "position": "relative", "top": "-34px"}}><ConnectButton /></span> */}
                                            {!connector.ready && ' (unsupported)'}
                                            {isLoading &&
                                            connector.id === pendingConnector?.id &&
                                            <span className="loader2" style={{"position": "relative", "top": "6px", "left": "32px"}}></span>}
                                            {connector.name == "MetaMask" ? <p className="connect--tag w3-right">Popular</p> : ""}
                                        </div>
                                        

                                        </>
                                    ))}
                                </div>
                            </>
                        }


                        {props.errorMessage ? (
                        <p className="error w3-animate-bottom" style={{"color": "red"}} align="center">
                            {props.errorMessage}
                        </p>
                        ) : null}
                    </div>
                <Home_Footer />
        </>
    )
}

export default ConnectWallet