import React from "react";
import { Link } from "react-router-dom"

const SellOrder_Cancelled = (props) => { 

    const closeSellCancel = () => {
        document.getElementById("sellorder-cancelled").style.display = "none"
        // window.location.reload()
    }

    return (
        <>
                <div className="w3-modal" id="sellorder-cancelled">
                    <div className="offer--details-modal2 w3-modal-content w3-animate-bottom">
                        <i className="offer--details-modal2-icon1 fa-solid fa-thumbs-up"></i>
                        <p className="offer--details-modal2-t1">SellOrder Cancelled</p>
                        <p className="offer--details-modal2-t2">You have cancelled your sell order with ID {props.mySellOrderID}</p>
                        <Link to="../profile" className="link"><button className="offer--details-modal2-btn1">Go to Profile</button></Link>
                        <button onClick={props.openedSellOrderCancel} className="offer--details-modal2-btn2">Close</button>
                    </div>
                </div>
        </>
    )
}

export default SellOrder_Cancelled