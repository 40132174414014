import React from "react";
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {useState, useEffect } from "react";
import {useAccount } from "wagmi"

import Home from "./Home"
import ConnectWallet from "./ConnectWallet";
import CreateSellOffer from "./offers/CreateSellOffer";
import BuyOffer from "./offers/BuyOffer";
import OfferDetails from "./offers/OfferDetails";
import Profile from "../pages/profile/Profile"
import Address from "./address/Address";
import Settings from "./settings/Settings";
import Coming_Soon from "./Coming_Soon";
import Privacy from "../pages/legals/Privacy";
import Terms from "../pages/legals/Terms";
import Risk from "../pages/legals/Risk";
import RoadMap from "../pages/RoadMap";
import NoPage from "./NoPage";

import axios from "axios";

function Main() {

  const {address, isConnected, isDisconnected } = useAccount()
  const [account, setAccount] = useState(null);
  console.log(account);

  useEffect(() => {
    if (isConnected) {
      // props.mainToApp(address)
      setAccount(address)
    }
  }, [])

  const [ isUser, setIsUser ] = useState([]);
  const [ isUser1, setIsUser1 ] = useState([]);
  const [ addressArray, setAddressArray ] = useState([])

    const baseURL = "https://p2pfi.xyz"

  useEffect(() => {

    const apiIsQuery = async () => {
      axios.get(baseURL + "/api/users")
        .then(async (response) => {
          console.log(response);
          console.log(response.data.User[0].username);
          const newResponse = response.data.User;
          console.log(newResponse);
          setIsUser1(newResponse)
          setAddressArray(newResponse)

          const hehehe = newResponse.filter((mapped) => {
            return (mapped.walletAddress).toLocaleLowerCase() == (account).toLocaleLowerCase()
          })
          console.log(hehehe);
          setIsUser(hehehe);

          // let newWallet = ""

          newResponse.forEach((data) => {
            console.log(`${data.username}`)
            console.log(`${data.username} has ${data.bannerImage} stars with ${data.walletAddress}`);


            // const delayApi = () => {

              setTimeout(function() {
                
                if((data.walletAddress).includes(account)) {
                  console.log("Account exists")
                } else {
                  console.log("Account does not exists")
                    const newApiQuery = async () => {
                        await axios.post("https://p2pfi.xyz/api/user", {
                          username: "Unknown",
                          email: "unknown@unknown.com",
                          walletAddress: account,
                          profileImage: "#F7F8F9",
                          bannerImage: "#F7F8F9"
                        })
                      .then((response) => {
                        console.log(response);
                      }, (error) => {
                        console.log(error);
                      });
                    }
                    newApiQuery()
                }

              }, 100)

            // }
            // setTimeout(delayApi, 10000);
          });
        }, (error) => {
          console.log(error);
        });
    }
    apiIsQuery()

}, [account]);


const [ apiBannerImage, setApiBannerImage ] = useState("")
const [ apiCreated_at, setApiCreated_at ] = useState("")
const [ apiEmail, setApiEmail ] = useState("")
const [ apiId, setApiId ] = useState("")
const [ apiProfileImage, setApiProfileImage ] = useState("")
const [ apiUpdated_at, setApiUpdated_at ] = useState("")
const [ apiUsername, setApiUsername ] = useState("")
const [ apiWalletAddress, setApiWalletAddress ] = useState("")
const [ apiBankName1, setApiBankName1 ] = useState("")
const [ apiAccountNumber1, setApiAccountNumber1 ] = useState("")
const [ apiAccountName1, setApiAccountName1 ] = useState("")
const [ apiTwitterUrl, setApiTwitterUrl ] = useState("")



  useEffect(() => {

    if(isDisconnected) {
      setApiBannerImage("")
      setApiCreated_at("")
      setApiEmail("")
      setApiId("")
      setApiProfileImage("")
      setApiUpdated_at("")
      setApiUsername("")
      setApiWalletAddress("")
      setApiBankName1("")
      setApiAccountNumber1("")
      setApiAccountName1("")
      setApiTwitterUrl("")
    } else {
      isUser.map((data) => {

        setApiBannerImage(data.bannerImage)
        setApiCreated_at(data.created_at)
        setApiEmail(data.email)
        setApiId(data.id)
        setApiProfileImage(data.profileImage)
        setApiUpdated_at(data.updated_at)
        setApiUsername(data.username)
        setApiWalletAddress(data.walletAddress)
        setApiBankName1(data.bankName1)
        setApiAccountNumber1(data.accountNumber1)
        setApiAccountName1(data.accountName1)
        setApiTwitterUrl(data.twitterURL)
      
        return;
      })
    }

  })

 
    return(
        <Router>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/connect" element={<ConnectWallet 
                apiUsername = {apiUsername}
                apiProfileImage = {apiProfileImage}
              />} />
              <Route exact path="/sell-offers" element={<CreateSellOffer 
                apiUsername = {apiUsername}
                apiProfileImage = {apiProfileImage}
              />} />

              <Route exact path="/buy-offers" element={<BuyOffer 
                apiUsername = {apiUsername}
                apiProfileImage = {apiProfileImage}
              />} />

              <Route exact path="/offer-details" element={<OfferDetails 
                apiUsername = {apiUsername}
                apiProfileImage = {apiProfileImage}
              />} />
              
              <Route exact path="/profile" element={<Profile 
                    key = {apiWalletAddress}
                    apiBannerImage = {apiBannerImage}
                    apiCreated_at = {apiCreated_at}
                    apiEmail = {apiEmail}
                    apiId = {apiId}
                    apiProfileImage = {apiProfileImage}
                    apiUpdated_at = {apiUpdated_at}
                    apiUsername = {apiUsername}
                    apiWalletAddress = {apiWalletAddress}
                    apiTwitterUrl = {apiTwitterUrl}
              />} />
              <Route path="/address" element={<Address />}>
                    {
                      addressArray ? addressArray.map(data =>
                        (
                          <Route path={"/address/" + data.walletAddress} element={<Address />} />
                      ))
                      :
                      (isUser1).map(data =>
                        (
                          <Route path={"/address/" + data.walletAddress} element={<Address />} />
                      ))
                    }
              </Route>
              <Route exact path="/settings" element={<Settings 
                    key = {apiWalletAddress}
                    apiBannerImage = {apiBannerImage}
                    apiCreated_at = {apiCreated_at}
                    apiEmail = {apiEmail}
                    apiId = {apiId}
                    apiProfileImage = {apiProfileImage}
                    apiUpdated_at = {apiUpdated_at}
                    apiUsername = {apiUsername}
                    apiWalletAddress = {apiWalletAddress}
                    apiTwitterUrl = {apiTwitterUrl}

                    apiBankName1 = {apiBankName1}
                    apiAccountNumber1 = {apiAccountNumber1}
                    apiAccountName1 = {apiAccountName1}
              />} />
              <Route exact path="/coming-soon" element={<Coming_Soon 
                apiUsername = {apiUsername}
                apiProfileImage = {apiProfileImage}
              />} />
              <Route exact path="/privacy" element={<Privacy 
                apiUsername = {apiUsername}
                apiProfileImage = {apiProfileImage}
              />} />
              <Route exact path="/terms" element={<Terms 
                apiUsername = {apiUsername}
                apiProfileImage = {apiProfileImage}
              />} />
              <Route exact path="/risk-warning" element={<Risk 
                apiUsername = {apiUsername}
                apiProfileImage = {apiProfileImage}
              />} />
              <Route exact path="/roadmap" element={<RoadMap 
                apiUsername = {apiUsername}
                apiProfileImage = {apiProfileImage}
              />} />
              <Route exact path="*" element={<NoPage 
                apiUsername = {apiUsername}
                apiProfileImage = {apiProfileImage}
              />} />
            </Routes>
        </Router>
    )
}

export default Main