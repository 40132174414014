import React from "react";
import { Link } from "react-router-dom"

const BuyOrder_Confirmed2 = (props) => { 

    const closeBuyConfirmed = () => {
        // document.getElementById("buyorder-confirmed").style.display = "none"
        window.location.reload()
    }

    return (
        <>
                <div className="w3-modal" id="buyorder-confirmed">
                    <div className="offer--details-modal2 w3-modal-content w3-animate-bottom">
                        <i className="offer--details-modal2-icon1 fa-solid fa-thumbs-up"></i>
                        <p className="offer--details-modal2-t1">BuyOrder Payment Confirmed</p>
                        <p className="offer--details-modal2-t2">You have confirmed payment and coin has been released back to the seller</p>
                        <Link to="../profile" className="link"><button onClick={closeBuyConfirmed} className="offer--details-modal2-btn1">Go to Profile</button></Link>
                        <button onClick={props.openedBuyOrderConfirmed2} className="offer--details-modal2-btn2">Close</button>
                    </div>
                </div>
        </>
    )
}

export default BuyOrder_Confirmed2