import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import Home_Header from "../../components/Home_Header";
import Home_Footer from "../../components/Home_Footer";
import axios from "axios"
import { ethers, FixedNumber } from "ethers";
import { contractAddress } from "../../App"
import { ERC20ABI } from "../../abi/ERC20ABI"
import { cryptoExchangeABI } from "../../abi/cryptoExchangeABI"

import BuyOrder_Accepted from "../../components/modals/BuyOrder_Accepted"
import BuyOrder_Confirmed from "../../components/modals/BuyOrder_Confirmed"
import BuyOrder_Confirmed2 from "../../components/modals/BuyOrder_Confirmed2"
import Offer_Bank_Modal from "../../components/modals/Offer_Bank_Modal"
import Offer_Abeg_Modal from "../../components/modals/Offer_Abeg_Modal"
import SellOrder_Cancelled from "../../components/modals/SellOrder_Cancelled";
import AllErros from "../../components/modals/BuyOrder_Error";

import { useProvider, useContract, useSigner, useAccount } from 'wagmi'
import Web3 from "web3";

import { create as ipfsHttpClient } from "ipfs-http-client";
import UpdateDispute from "../../components/modals/UpdateDispute";


let newApiMonth;
let newApiYear;


function Profile(props) {

    const projectId = process.env.REACT_APP_PROJECT_ID;
    const projectSecretKey = process.env.REACT_APP_PROJECT_KEY;
    const authorization = "Basic " + btoa(projectId + ":" + projectSecretKey);

    const ipfs = ipfsHttpClient({
        url: "https://ipfs.infura.io:5001/api/v0",
        headers: {
          authorization,
        },
      });

    const { isDisconnected } = useAccount()

      useEffect(()=> {
        if (isDisconnected) {
            const urlAddress = window.location.pathname.split("/");
            window.location.href = urlAddress[0] + "/connect"
            console.log(urlAddress[1])
        }
      })

    const [ account, setAccount ] = useState("")

    const headerToOthers1 = (addressData) => {
        setAccount(addressData)
    }

    const [ shareIcon , setShareIcon ] = useState("fa-solid fa-share-nodes")


    const copyProfile = () => {
        navigator.clipboard.writeText(`https://alpha.p2pfi.co/address/${props.apiWalletAddress}`);
        console.log("copied")
        setShareIcon("fa-solid fa-check")
        setTimeout(() => {
            setShareIcon("fa-solid fa-share-nodes")
        }, 2000)
        
    }

    const shareProfile = () => {
        if (navigator.share) {
            navigator.share({
                title: "Wallet Address",
                url: `https://alpha.p2pfi.co/address/${props.apiWalletAddress}`
            })
            .then(() => {
                setShareIcon("fa-solid fa-check")
                setTimeout(() => {
                    setShareIcon("fa-solid fa-share-nodes")
                }, 2000)
            })
        } else {
            copyProfile()
        }
    }

    // const [ metaMethod, setMetaMethod ] = useState("Bank Account")

    // useEffect(() => {
    //     if(props.metadataURI === "0x96ee3279dd30231650e0b4a1a3516ab3dc26b6d3dfcb6ef20fb4329cfc1213e1") {
    //         setMetaMethod("Bank Transfer")
    //     } else {
    //         const getIpfs = async () => {
    //             const tryme = Web3.utils.toUtf8(props.metadataURI)
    //             console.log(props.metadataURI)
    //             console.log(tryme)
                
    //             await fetch("https://p2pfiv1.infura-ipfs.io/ipfs/" + tryme)
    //             .then((r) => r.json())
    //             .then((r) => {
    //                 // console.log(r)
    //                 setMetaMethod(r.method)
    //                 setMetaDescription(r.description)
    //             });
    //         }
    //         getIpfs()
    //     }
    // })

    const provider = useProvider()
    const { data: signer, isError, isLoading } = useSigner()
    console.log(signer)


    const contract = useContract({
        address: contractAddress,
        abi: cryptoExchangeABI,
        signerOrProvider: signer,
      })

    const [ openBuyOrderAccepted, setOpenBuyOrderAccepted ] = useState(false)
    const [ openBuyOrderPay, setOpenBuyOrderPay ] = useState(false)
    const [ openBuyOrderConfirmed, setOpenBuyOrderConfirmed ] = useState(false)
    const [ openBuyOrderConfirmed2, setOpenBuyOrderConfirmed2 ] = useState(false)

    const [ openSellOrderCancel, setOpenSellOrderCancel ] = useState(false)


    const [ openAllErrors, setOpenAllErrors ] = useState(false)



    const openedBuyOrderAccepted = () => {
        setOpenBuyOrderAccepted(current => !current);
    }

    const openedBuyOrderPay = () => {
        setOpenBuyOrderPay(current => !current);
    }

    const openedBuyOrderConfirmed = () => {
        setOpenBuyOrderConfirmed(current => !current);
    }

    const openedBuyOrderConfirmed2 = () => {
        setOpenBuyOrderConfirmed2(current => !current);
    }



    const openedSellOrderCancel = () => {
        setOpenSellOrderCancel(current => !current);
    }



    const openedAllErrors = () => {
        setOpenAllErrors(current => !current);
    }



    const newed = async () => {

        const myDate = await props.apiCreated_at;
        const arr = myDate.split(/ |-/);

        console.log('date: ', arr[0]);
        console.log('month: ', arr[1]);
        console.log('year: ', arr[2]);

        const apiMonth = arr[1];

        console.log("The Month", apiMonth)

        newApiYear = arr[0];



        const getDate = () => {
            if(apiMonth == 1) {
                newApiMonth = "January";
            } else if(apiMonth == 2) {
                newApiMonth = "February";
            } else if(apiMonth == 3) {
                newApiMonth = "March";
            } else if(apiMonth == 4) {
                newApiMonth = "April";
            } else if(apiMonth == 5) {
                newApiMonth = "May";
            } else if(apiMonth == 6) {
                newApiMonth = "June";
            } else if(apiMonth == 7) {
                newApiMonth = "July";
            } else if(apiMonth == 8) {
                newApiMonth = "August";
            } else if(apiMonth == 9) {
                newApiMonth = "September";
            } else if(apiMonth == 10) {
                newApiMonth = "October";
            } else if(apiMonth == 11) {
                newApiMonth = "November";
            } else if(apiMonth == 12) {
                newApiMonth = "December";
            }
        }

        getDate()
        console.log(newApiMonth);

    }
    newed()

    const [ profileHero, setProfileHero ] = useState("#F7F8F9")

    useEffect(() => {
        if(props.apiBannerImage === "green") {
            setProfileHero("green")
        } else if(props.apiBannerImage === "blue") {
            setProfileHero("blue")
        } else if(props.apiBannerImage === "yellow") {
            setProfileHero("yellow")
        } else if(props.apiBannerImage === "orange") {
            setProfileHero("orange")
        } else {
            setProfileHero("#F7F8F9")
        }
    })

    const [ profileImage, setProfileImage ] = useState("#F7F8F9")


    useEffect(() => {
        if(props.apiProfileImage === "green") {
            setProfileImage("green")
        } else if(props.apiProfileImage === "blue") {
            setProfileImage("blue")
        } else if(props.apiProfileImage === "yellow") {
            setProfileImage("yellow")
        } else if(props.apiProfileImage === "orange") {
            setProfileImage("orange")
        } else {
            setProfileImage("#F7F8F9")
        }
    })

    const sell_orders = () => {
        document.getElementById("sell-orders").style.display = "block"
        document.getElementById("buy-orders").style.display = "none"
        document.getElementById("activities").style.display = "none"
        document.getElementById("pending-orders").style.display = "none"
        document.getElementById("sell-orders1").focus();
    }

    const buy_orders = () => {
        document.getElementById("sell-orders").style.display = "none"
        document.getElementById("buy-orders").style.display = "block"
        document.getElementById("activities").style.display = "none"
        document.getElementById("pending-orders").style.display = "none"
        document.getElementById("buy-orders1").focus();
    }

    const activities = () => {
        document.getElementById("sell-orders").style.display = "none"
        document.getElementById("buy-orders").style.display = "none"
        document.getElementById("pending-orders").style.display = "none"
        document.getElementById("activities").style.display = "block"
        document.getElementById("activities1").focus();
    }

    const completed_orders = () => {
        document.getElementById("sell-orders").style.display = "none"
        document.getElementById("buy-orders").style.display = "none"
        document.getElementById("activities").style.display = "none"
        document.getElementById("pending-orders").style.display = "block"
        document.getElementById("pending-orders1").focus();
    }

    const open_profile_menu = () => {
        var x = document.getElementById("profile--open_profile_menu");
        if (x.className.indexOf("w3-show") == -1) {
          x.className += " w3-show";
        } else {
          x.className = x.className.replace(" w3-show", "");
        }
    }


    const [ newSellOffers , setNewSellOffers ] = useState([]);

    useEffect(() => {
        const renderOffers = async () => {
            try {
                const query = await axios.post(
                    "https://api.thegraph.com/subgraphs/name/staa99/p2pfi-mumbai",
                    {
                        query: `{
                            sellOrders {
                                id
                                token {
                                    id
                                    name
                                }
                                seller {
                                    id
                                }
                                preDisputeDuration
                                totalAmount
                                minimumBuyAmount
                                filledAmount
                                dateCreated
                                status
                                feeRate
                                metadataURI
                                buyOrders {
                                    id
                                    status
                                    amount
                                    metadataUri
                                    buyer {
                                        id
                                    }
                                    token {
                                        id
                                        name
                                    }
                                    sellOrder {
                                        id
                                        totalAmount
                                        seller {
                                            id
                                        }
                                        preDisputeDuration
                                    }
                                }
                              } 
                        }`,
                    },
                        {
                            headers: {
                                        "Content-Type": "application/xml"
                                    },
                            withCredentials: false,
                        }
                );


                setNewSellOffers(query.data.data.sellOrders);
                // console.log(sellOffers)

            } catch(err) {
                console.log(err)
            }
        }
        renderOffers()
    }, [account])

    const newFilteredOrders = newSellOffers.filter((data) => {
        return data.status == "Initialized"
    })

    const newNewFilteredOrders = newFilteredOrders.filter((data) => {
        return (data.seller.id).toLocaleLowerCase() == account.toLocaleLowerCase()
    })

    console.log(newNewFilteredOrders)
    







    // Buy Orders List1

    let mySeller;
    let myTotal;

    const newNewFilteredOrders2 = newNewFilteredOrders.map((data) => {
        mySeller = data.seller.id
        myTotal = data.totalAmount
        return data.buyOrders
    })
    console.log(newNewFilteredOrders2);

    const newNewFilteredOrders2b = newNewFilteredOrders2.filter((data) => {
        if (Object.keys(data).length !== 0) {
            return true;
          }
          return false;
    })
    console.log(newNewFilteredOrders2b);

    const newNewFilteredOrders2c = newNewFilteredOrders2b.map((data) => {
        console.log(data)
        return data
    })

    // Concat and apply method for []
    const newNewFilteredOrders2d = [].concat.apply([], newNewFilteredOrders2c)

    console.log(newNewFilteredOrders2d);

    const newNewFilteredOrders2dxx = newNewFilteredOrders2d.filter((data) => {
        return data.status !== "Accepted"
    })

    const [ disputeLoader, setDisputeLoader ] = useState("Dispute")

    const newNewFilteredOrders3 = newNewFilteredOrders2dxx.map((data) => {


        const acceptBuyOrder = async () => {
            try {
                await contract.acceptBuyOrder(data.id)
                .then(results => {
                    console.log(results)
                    openedBuyOrderAccepted()
                    return;
                })
                console.log("Order Accepted")
            } catch(err) {
                console.log(err);
                openedAllErrors()
            }
          }

          const confirmBuyOrder = async () => {
            try {
                await contract.confirmBuyOrder(data.id)
                .then(results => {
                    console.log(results)
                    openedBuyOrderConfirmed()
                    return;
                })
                console.log("Order Confirmed")
            } catch(err) {
                console.log(err);
                openedAllErrors()
            }
          }

        const initiateDispute = async () => {
            setDisputeLoader("Loading..")
            const disputeData = {
                "buyOrderID": data.id,
                "description": "Payment Not Received"
            }
            const makeURI = async () => {
                await ipfs.add(JSON.stringify(disputeData))
                .then((result) => {
                    const thePath = result.path
                    console.log(thePath)
                    const path1 = thePath.toString()
                    const path2 = Web3.utils.asciiToHex(path1)
                    console.log(path2)
                    if(path2 != "undefined") {
                        contract.initiateDispute(data.id, path2)
                        .then(results => {
                            console.log(results)
                            setDisputeLoader("Created")
                        }, (err) => {
                        console.log(err);
                        setDisputeLoader("Error")
                        setTimeout(() => {
                            setDisputeLoader("Dispute")
                        }, 2000)
                        })
                    }
                }, (err) => {
                    console.log(err)
                    setDisputeLoader("Dispute")
                })

            }
            makeURI()
        }


        const resolveDispute = async () => {
            const disputeData1 = {
                "buyOrderID": data.id,
                "description": "Payment Received"
            }
            await ipfs.add(JSON.stringify(disputeData1))
            .then((result) => {
                const thePath = result.path
                console.log(thePath)
                const path1 = thePath.toString()
                const path2 = Web3.utils.asciiToHex(path1)
                console.log(path2)
                if(path2 != "undefined") {
                    contract.payTokensToBuyer(data.id, path2)
                    .then(results => {
                        console.log(results)
                        openedBuyOrderConfirmed()
                        return;
                    }, (err) => {
                    console.log(err);
                    })
                }
            }, (err) => {
                console.log(err)
                openedAllErrors()
            })

         }


         const intensifyDispute = async () => {
            const disputeData2 = {
                "buyOrderID": data.id,
                "description": "Payment Not Successful"
            }
            await ipfs.add(JSON.stringify(disputeData2))
            .then((result) => {
                const thePath = result.path
                console.log(thePath)
                const path1 = thePath.toString()
                const path2 = Web3.utils.asciiToHex(path1)
                console.log(path2)
                if(path2 != "undefined") {
                    contract.returnTokensToSeller(data.id, path2)
                    .then(results => {
                        console.log(results)
                        openedBuyOrderConfirmed2()
                        return;
                    }, (err) => {
                    console.log(err);
                    })
                }
            }, (err) => {
                console.log(err)
                openedAllErrors()
            })

         }



          let orderBtn = "";
          if(data.status === "Initialized") {
            orderBtn = acceptBuyOrder
          } else if (data.status === "Paid") {
            orderBtn = confirmBuyOrder
          } else {
            orderBtn = ""
          }



{/* <button disabled style={{"fontSize": "12px", "position": "relative", "top": "-4px", "left": "12px", "border": "2px solid #ff000080", "background": "#f7f8f9", "padding": "0px 6px", "borderRadius": "4px"}}>Check Tab</button> */}

        return (
            <>
                <tr>
                    <td className="profile--table-row">{data.status}</td>
                    <td className="profile--table-row">{data.token.name}</td>
                    <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "140px",  "paddingBottom": "38px", "paddingLeft": "62px"}} className="profile--table-row">{data.id}</td>
                    <td className="profile--table-row">{(data.sellOrder.totalAmount * 1).toLocaleString("en-US")}</td>
                    <td className="profile--table-row" style={{"paddingLeft": "52px"}}>{(data.amount * 1).toLocaleString("en-US")}</td>
                    <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "200px", "paddingBottom": "38px", "paddingLeft": "14px"}} className="profile--table-row">{data.buyer.id}</td>
                    <td className="profile--table-row">{data.status == "Disputing" ? <>
                    <button onClick={resolveDispute} style={{"fontSize": "12px", "position": "relative", "top": "-4px", "left": "12px", "border": "2px solid green", "background": "green", "color": "#ffffff90", "padding": "0px 6px", "borderRadius": "4px", "cursor": "pointer"}}>Paid?</button>
                    <span style={{"paddingLeft": "4px"}}>
                    <button onClick={intensifyDispute} style={{"fontSize": "12px", "position": "relative", "top": "-4px", "left": "12px", "border": "2px solid #ff000080", "background": "#ff000080", "color": "#00000090", "padding": "0px 6px", "borderRadius": "4px", "cursor": "pointer"}}>Not?</button>
                    </span>
                    </> : <i onClick={orderBtn} class="fa-solid fa-circle-check" style={{"color": "green", "fontSize": "24px"}}></i>}{data.status == "Paid" && <span><button disabled={disputeLoader == "Created"} onClick={initiateDispute} style={{"fontSize": "12px", "position": "relative", "top": "-4px", "left": "12px", "border": "2px solid #ff000080", "background": "#f7f8f9", "padding": "0px 6px", "borderRadius": "4px"}}>{disputeLoader}</button></span>}</td>
                </tr>
                {
                    openBuyOrderAccepted && <BuyOrder_Accepted 
                        openedBuyOrderAccepted = {openedBuyOrderAccepted}
                    />
                }
                {
                    openBuyOrderConfirmed && <BuyOrder_Confirmed 
                        openedBuyOrderConfirmed = {openedBuyOrderConfirmed}
                    />
                }
                                {
                    openBuyOrderConfirmed2 && <BuyOrder_Confirmed2
                        openedBuyOrderConfirmed2 = {openedBuyOrderConfirmed2}
                    />
                }
                {
                openAllErrors && <AllErros
                                    errorID = {data.id}
                                    openedAllErrors = {openedAllErrors}
                                />
                }
            </>
        )
    })











        // Buy Orders List2




        let mySeller2x = "";
        let myTotal2x = "";

        const newNewFilteredOrders2x = newFilteredOrders.map((data) => {
            mySeller2x = data.seller.id
            myTotal2x = data.totalAmount
            return data.buyOrders
        })
        console.log(newNewFilteredOrders2);

        const newNewFilteredOrders2bx = newNewFilteredOrders2x.filter((data) => {
            if (Object.keys(data).length !== 0) {
                return true;
              }
              return false;
        })
        console.log(newNewFilteredOrders2bx);

        const newNewFilteredOrders2cx = newNewFilteredOrders2bx.map((data) => {
            console.log(data)
            return data
        })

        // Concat and apply method for []
        const newNewFilteredOrders2dx = [].concat.apply([], newNewFilteredOrders2cx)

        console.log(newNewFilteredOrders2dx)


        const newNewFilteredOrders2ex = newNewFilteredOrders2dx.filter((data) => {
            return (data.buyer.id).toLocaleLowerCase() == (account).toLocaleLowerCase()
        })

        const newNewFilteredOrders2fx = newNewFilteredOrders2ex.filter((data) => {
            return data.status === "Accepted"
        })

        const newNewFilteredOrders2fx2 = newNewFilteredOrders2ex.filter((data) => {
            return data.status === "Disputing"
        })

        const newNewFilteredOrders2fx3 = [].concat(newNewFilteredOrders2fx, newNewFilteredOrders2fx2)

        const newNewFilteredOrders2gx = newNewFilteredOrders2ex.filter((data) => {
            return data.status === "Completed"
        })


        // Open the Update Dispute Modal

        const [ openUpdatedDispute, setOpenUpdatedDispute ] =  useState(false)
        const openDisputeUpdated = () => {
            setOpenUpdatedDispute(current => !current)
        }


        const newNewFilteredOrders3x = newNewFilteredOrders2fx3.map((data) => {




            let themBtn = "";
            if(data.status === "Accepted") {
              themBtn = openedBuyOrderPay
            } else if (data.status === "Disputing") {
              themBtn = openDisputeUpdated
            }


            return (
                <>
                    <tr>
                        <td className="profile--table-row">{data.status}</td>
                        <td className="profile--table-row">{data.token.name}</td>
                        <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "140px",  "paddingBottom": "38px", "paddingLeft": "62px"}} className="profile--table-row">{data.id}</td>
                        <td className="profile--table-row">{(data.sellOrder.totalAmount * 1).toLocaleString("en-US")}</td>
                        <td className="profile--table-row" style={{"paddingLeft": "52px"}}>{(data.amount * 1).toLocaleString("en-US")}</td>
                        <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "200px", "paddingBottom": "38px", "paddingLeft": "14px"}} className="profile--table-row">{data.buyer.id}</td>
                         <td className="profile--table-row"><i onClick={themBtn} class="fa-solid fa-circle-check" style={{"color": "green", "fontSize": "24px"}}></i></td>
                    </tr>

                {/* Review this later */}

                    {/* {
                        (openBuyOrderPay && (metaMethod == "Bank Account")) ? <Offer_Bank_Modal
                                                buyOrderID = {data.id}
                                                buyAmount = {data.amount}
                                                sellerAddress = {data.sellOrder.seller.id}
                                                orderDuration = {data.sellOrder.preDisputeDuration}
                                                openedBuyOrderPay = {openedBuyOrderPay}
                                            /> 
                                            : 
                                            (openBuyOrderPay && (metaMethod == "Bank Account")) && 
                                            <Offer_Abeg_Modal
                                            buyOrderID = {data.id}
                                            buyAmount = {data.amount}
                                            sellerAddress = {data.sellOrder.seller.id}
                                            orderDuration = {data.sellOrder.preDisputeDuration}
                                            openedBuyOrderPay = {openedBuyOrderPay}
                                        />
                    } */}
                    {
                        openUpdatedDispute && <UpdateDispute
                        theCoin = {data.token.id}
                        buyOrderID = {data.id}
                        buyAmount = {data.amount}
                        sellerAddress = {data.sellOrder.seller.id}
                        orderDuration = {data.sellOrder.preDisputeDuration}
                        openDisputeUpdated = {openDisputeUpdated}
                        /> 
                    }
                    {
                        openBuyOrderPay && <Offer_Bank_Modal
                        theCoin = {data.token.id}
                        buyOrderID = {data.id}
                        buyAmount = {data.amount}
                        sellerAddress = {data.sellOrder.seller.id}
                        orderDuration = {data.sellOrder.preDisputeDuration}
                        openedBuyOrderPay = {openedBuyOrderPay}
                        /> 
                    }
                </>
            )
        })





    // Completed Orders List


    const newBuyOrderList = newNewFilteredOrders2d.filter((data) => {
        return data.status === "Completed"
    })

    const newBuyOrderListB = newNewFilteredOrders2fx.filter((data) => {
        return data.status === "Completed"
    })

    const newBuyOrderListC = newNewFilteredOrders2gx.filter((data) => {
        return data.status === "Completed"
    })

    const concatnewBuyOrderList = newBuyOrderList.concat(newBuyOrderListB, newBuyOrderListC);

     console.log(concatnewBuyOrderList)

    const newBuyOrderList2 = concatnewBuyOrderList.map((data) => {
        return (
            <>
                <tr>
                <td className="profile--table-row">{data.status}</td>
                    <td className="profile--table-row">{data.token.name}</td>
                    <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "140px",  "paddingBottom": "38px", "paddingLeft": "62px"}} className="profile--table-row">{data.id}</td>
                    <td className="profile--table-row">{(data.sellOrder.totalAmount * 1).toLocaleString("en-US")}</td>
                    <td className="profile--table-row" style={{"paddingLeft": "52px"}}>{(data.amount * 1).toLocaleString("en-US")}</td>
                    <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "200px", "paddingBottom": "38px", "paddingLeft": "14px"}} className="profile--table-row">{data.buyer.id}</td>
                     <td className="profile--table-row"><i class="fa-solid fa-circle-check" style={{"color": "green", "fontSize": "24px"}}></i></td>
                </tr>
            </>
        )
    })








    // Sell Orders List


    const trueItem = () => {
        document.getElementById("sell-orders--true-item").style.display = "block";
        document.getElementById("sell-orders--no-item").style.display = "none";
    }

    const noItem = () => {
        document.getElementById("sell-orders--true-item").style.display = "none";
        document.getElementById("sell-orders--no-item").style.display = "block";
    }

    const newListOrders = newNewFilteredOrders.map((data) => {

        const cancelSellOrder = async () => {

            try {
                await contract.cancelSellOrder(data.id)
                .then(response => {
                    console.log(response);
                    openedSellOrderCancel()
                })
                console.log("Order Cancelled")
            } catch(err) {
                console.log(err)
                openedAllErrors()
            }
          }

        if (newNewFilteredOrders !== undefined) {
            trueItem()
            return(
                <>
                    <tr>
                        <td className="profile--table-row" style={{"paddingLeft": "44px"}}><i className="fa-brands fa-nfc-symbol profile--table-icon"></i>{data.id}</td>
                        <td className="profile--table-row">{data.token.name}</td>
                        <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "140px",  "paddingBottom": "38px"}} className="profile--table-row">{"₦" + " " + (data.totalAmount * 0.9 * 720).toLocaleString("en-US")}</td>
                        <td className="profile--table-row">{(data.totalAmount * 1).toLocaleString("en-US")}</td>
                        <td className="profile--table-row" style={{"paddingLeft": "10px"}}>{(data.filledAmount * 1).toLocaleString("en-US")}</td>
                        <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "200px", "paddingBottom": "38px", "paddingLeft": "14px"}} className="profile--table-row">{data.seller.id}</td>
                        <td style={{"whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "200px", "paddingLeft": "32px"}} className="profile--table-row">
                            <button onClick={buy_orders} style={{"border": "none", "backgroundColor": "green", "color": "white", "padding": "2px 12px", "borderRadius": "4px", "marginRight": "6px"}}>
                                View
                            </button>
                            <i onClick={cancelSellOrder} class="fa-solid fa-xmark" style={{"border": "none", "backgroundColor": "red", "color": "white", "padding": "7px 10px", "borderRadius": "100%"}}></i>
                        </td>
                    </tr>
                    {
                        openSellOrderCancel && <SellOrder_Cancelled
                                                    mySellOrderID = {data.id}
                                                    openedSellOrderCancel = {openedSellOrderCancel}
                                                />
                    }
                    {
                    openAllErrors && <AllErros
                                        errorID = {data.id}
                                        openedAllErrors = {openedAllErrors}
                                    />
                    }
                </>
            )
        }
        else {
            noItem()
        }

    })



    const [ search1, setSearch1] = useState('')
    const [ search2, setSearch2] = useState('')
    const [ search3, setSearch3] = useState('')
    const [ search4, setSearch4] = useState('')
    const [ recentlyBought, setRecentlyBought ] = useState('')
    const [ recentlySold, setRecentlySold ] = useState('')
    const [ recentlyBoughtOrSold, setRecentlyBoughtOrSold ] = useState('')
    const [ pendingOrders, setPendingOrders ] = useState('')
    const [ error, setError ] = useState('');

    useEffect(() => {
        setError('');
      }, [search1, recentlyBought])

      useEffect(() => {
        setError('');
      }, [search2, recentlySold])

      useEffect(() => {
        setError('');
      }, [search3, recentlyBoughtOrSold])

      useEffect(() => {
        setError('');
      }, [search4, pendingOrders])

      const handleSubmit1 = async (e) => {
        e.preventDefault();
        console.log(search1, recentlyBought)
        setSearch1('')
        setRecentlyBought('')
      }

      const handleSubmit2 = async (e) => {
        e.preventDefault();
        console.log(search2, recentlyBought)
        setSearch2('')
        setRecentlySold('')
      }

      const handleSubmit3 = async (e) => {
        e.preventDefault();
        console.log(search3, recentlyBought)
        setSearch3('')
        setRecentlyBoughtOrSold('')
      }

      const handleSubmit4 = async (e) => {
        e.preventDefault();
        console.log(search4, pendingOrders)
        setSearch4('')
        setPendingOrders('')
      }

      const hardRefresh = () => {
        window.location.reload()
      }

    return(
        <>
            <Home_Header
                account = {account}
                balance = {props.balance}
                balance2 = {props.balance2}
                headerToOthers1 = {headerToOthers1}
                apiProfileImage = {props.apiProfileImage}
                apiUsername = {props.apiUsername}
            />
            <div className="profile--hero" id="profile--hero" style={{"backgroundColor": `${profileHero}`}}>
                <img src=""
                className="profile--img"
                id="profile-img"
                style={{"backgroundColor": `${profileImage}`}}
                />
                
                {
                    !props.apiUsername ? <p className="profile--t1">New User</p> : <p className="profile--t1">{props.apiUsername}</p>
                }
                <div className="w3-left profile--left">
                    {/* <i className="fa-brands fa-ethereum profile--icon1"></i> */}
                    <img src="/images/matic.png"
                    className="profile--icon1"
                    style={{"width": "26px", "top": "-28px"}}
                    />
                    <span className="profile--t2">{account}</span>
                    <span className="profile--t2b">Joined {(!newApiMonth ? "..." : newApiMonth) + " " + (!newApiYear ? "..." : newApiYear)}{!newApiYear && <button style={{"border": "2px solid #00000030", "background": "#f7f8f9", "fontSize": "12px", "padding": "4px 12px", "borderRadius": "4px", "position": "relative", "top": "-4px", "left": "12px"}} onClick={() => hardRefresh()}>Refresh</button>}</span>
                </div>
                <div className=" w3-hide-small w3-right profile--right">
                    <a href={"https://" + props.apiTwitterUrl} target="_blank"><i className="fa-brands fa-twitter profile--icon2"></i></a>
                    <i onClick={shareProfile} className={shareIcon + " profile--icon2"}></i>
                    <Link to="/settings" className="link"><i className="fa-solid fa-gear profile--icon2"></i></Link>

                    {/* Dropdown with menu button */}

                    {/* <i onClick={open_profile_menu} className="fa-solid fa-ellipsis-vertical profile--icon2b"></i> */}

{/*
                    <div id="profile--open_profile_menu" className="profile--menu w3-dropdown-content w3-bar-block w3-card-4 w3-animate-zoom">
                        <Link to="/settings" className="link"><p className="w3-bar-item"><i className="fa fa-gear profile--icon2c"></i><span className="profile--menu-t">Setting</span></p></Link>
                        <hr/>
                        <Link to="/report" className="link"><p className="w3-bar-item"><i className="fa fa-flag profile--icon2c"></i><span className="profile--menu-t">Report</span></p></Link>
                        <hr/>
                        <Link to="/settings" className="link"><p className="w3-bar-item"><img src="images/profile_featured.png" className="profile--icon2cc profile--icon2c"/><span className="profile--menu-t">Featured items</span></p></Link>
                    </div> */}

                </div>

                <div className="w3-hide-medium w3-hide-large">
                    <i onClick={open_profile_menu} className="w3-right fa-solid fa-ellipsis-vertical profile--icon2b"></i>


                    <div id="profile--open_profile_menu" className="profile--menu w3-dropdown-content w3-bar-block w3-card-4 w3-animate-zoom">
                        <Link to="/settings" className="link"><p className="w3-bar-item"><i className="fa fa-gear profile--icon2c"></i><span className="profile--menu-t">Setting</span></p></Link>
                        <hr/>
                        <a href={"https://" + props.apiTwitterUrl} className="link" target="_blank"><p className="w3-bar-item"><i className="fa-brands fa-twitter profile--icon2c"></i><span className="profile--menu-t">Twitter</span></p></a>
                        <hr/>
                        <p onClick={shareProfile} className="w3-bar-item"><i className={"profile--icon2cc profile--icon2c " + shareIcon}></i><span className="profile--menu-t">Share Profile</span></p>
                    </div>
                </div>

            </div>

            <div className="profile--section">
                <div className="profile--t3-base">
                    <span onClick={sell_orders} tabIndex="0" className="profile--t3 orderss" id="sell-orders1">Sell Orders</span>
                    <span onClick={buy_orders} tabIndex="-1" className="profile--t3 orderss" id="buy-orders1">Buy Orders</span>
                    <span onClick={activities} tabIndex="-2" className="profile--t3 orderss" id="activities1">Activities</span>
                    <span onClick={completed_orders} tabIndex="-3" className="profile--t3 orderss" id="pending-orders1">Completed Orders</span>
                </div>
                <hr/>


                <div className="profile--sell-orders orderss" id="sell-orders">
                    <form onSubmit={handleSubmit1} className="sell--orders-header">
                        <img src="/images/profile_filter.png"
                        className="profile--icon3"
                        />
                        <input
                        className="profile--input-1"
                        type="search"
                        placeholder="Search"
                        onChange={(e) => setSearch1(e.target.value)}
                        value={search1}
                        />
                        <input
                        className="profile--input-2"
                        type="search"
                        placeholder="Recently Sold"
                        onChange={(e) => setRecentlySold(e.target.value)}
                        value={recentlySold}
                        />
                    </form>

                    <div className="profile--table-div">
                        <table className="profile--table" id="sell-orders--true-item">
                            <tbody>

                                <tr>
                                    <th className="profile--table-head-h"><span className="profile--table-head-h-h">Sell OrderID</span></th>
                                    <th className="profile--table-head" style={{"paddingLeft": "14px"}}>Coin Name</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "38px"}}>Price</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "12px"}}>Quantity</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Filled Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "42px"}}>From</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "42px"}}>Orders</th>
                                </tr>
                                {newListOrders}
                            </tbody>
                        </table>
                    </div>

                    <div id="sell-orders--no-item" className="sell--orders-list">
                        <p className="profile--t4">No Items to Display</p>
                    </div>

                </div>

                <div className="profile--buy-orders orderss" id="buy-orders">
                    <form onSubmit={handleSubmit2} className="sell--orders-header">
                        <img src="/images/profile_filter.png"
                        className="profile--icon3"
                        />
                        <input
                        className="profile--input-1"
                        type="search"
                        placeholder="Search"
                        onChange={(e) => setSearch2(e.target.value)}
                        value={search2}
                        />
                        <input
                        className="profile--input-2"
                        type="search"
                        placeholder="Recently Bought"
                        onChange={(e) => setRecentlyBought(e.target.value)}
                        value={recentlySold}
                        />
                    </form>

                    {/* <div className="sell--orders-list">
                        <p className="profile--t4">No Items to Display</p>
                    </div> */}
                    <div className="profile--table-div">
                        <table className="profile--table">
                            <tbody>
                                <tr>
                                    <th className="profile--table-head-h"><span className="profile--table-head-h-h">Status</span></th>
                                    <th className="profile--table-head" style={{"paddingLeft": "14px"}}>Coin Name</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "38px"}}>Buy OrderID</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Total Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "20px"}}>Order Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "32px"}}>Buyer</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Accept | Confirm | Pay</th>
                                    {/* <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Accept | Confirm | Pay</th> */}
                                </tr>
                                {newNewFilteredOrders3}
                                {newNewFilteredOrders3x}
                            </tbody>
                        </table>
                    </div>

                </div>

                <div className="profile--activities orderss" id="activities">
                    <form onSubmit={handleSubmit3} className="sell--orders-header">
                        <img src="/images/profile_filter.png"
                        className="profile--icon3"
                        />
                        <input
                        className="profile--input-1"
                        type="search"
                        placeholder="Search"
                        onChange={(e) => setSearch3(e.target.value)}
                        value={search3}
                        />
                        <input
                        className="profile--input-2"
                        type="search"
                        placeholder="Recently Bought or Sold"
                        onChange={(e) => setRecentlyBoughtOrSold(e.target.value)}
                        value={recentlyBoughtOrSold}
                        />
                    </form>

                    <div className="profile--table-div">
                        <table className="profile--table">
                            <tbody>
                                <strong style={{"fontSize": "20px"}}>Sell Orders</strong>
                                <br/><br/>
                                <tr>
                                    <th className="profile--table-head-h"><span className="profile--table-head-h-h">Sell OrderID</span></th>
                                    <th className="profile--table-head" style={{"paddingLeft": "14px"}}>Coin Name</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "38px"}}>Price</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "12px"}}>Quantity</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Filled Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "42px"}}>From</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "42px"}}>Orders</th>
                                </tr>
                                {newListOrders}
                                <br/><br/><br/><br/>
                                <strong style={{"fontSize": "20px"}}>Buy Orders</strong>
                                <br/><br/>
                                <tr>
                                    <th className="profile--table-head-h"><span className="profile--table-head-h-h">Status</span></th>
                                    <th className="profile--table-head" style={{"paddingLeft": "14px"}}>Coin Name</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "38px"}}>Buy OrderID</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Total Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "20px"}}>Order Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "32px"}}>Buyer</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Accept | Confirm | Pay</th>
                                    {/* <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Accept | Confirm | Pay</th> */}
                                </tr>
                                {newNewFilteredOrders3}
                                {newNewFilteredOrders3x}
                            </tbody>
                        </table>
                    </div>

                </div>

                <div className="profile--pending-orders orderss" id="pending-orders">
                    <form onSubmit={handleSubmit4} className="sell--orders-header">
                        <img src="/images/profile_filter.png"
                        className="profile--icon3"
                        />
                        <input
                        className="profile--input-1"
                        type="search"
                        placeholder="Search"
                        onChange={(e) => setSearch4(e.target.value)}
                        value={search4}
                        />
                        <input
                        className="profile--input-2"
                        type="search"
                        placeholder="Pending Orders"
                        onChange={(e) => setPendingOrders(e.target.value)}
                        value={pendingOrders}
                        />
                    </form>

                    {/* <div className="sell--orders-list">
                        <p className="profile--t4">No Items to Display</p>
                    </div> */}
                    <div className="profile--table-div">
                        <table className="profile--table">
                            <tbody>
                                <tr>
                                    <th className="profile--table-head-h"><span className="profile--table-head-h-h">Status</span></th>
                                    <th className="profile--table-head" style={{"paddingLeft": "14px"}}>Coin Name</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "38px"}}>Order ID</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "20px"}}>Total Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "32px"}}>Order Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "42px"}}>Buyer</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Accept | Confirm | Pay</th>
                                </tr>
                                {newBuyOrderList2}

                            </tbody>
                        </table>
                    </div>

                </div>






            </div>







            <Home_Footer />
        </>
    )
}

export default Profile

// call "placeBuyOrder" to make Order === "Initialized" (on buyer end);
// If Order === "Initialized", call "acceptBuyOrder" on seller end;
// If Order === "Accepted", call "payBuyOrder" on Buyer end;
// If Order === "Paid", call "confirmBuyOrder" on Seller end; 
// Or initiate dispute if buyer has not seen money or if time is more than preDisputeDuration


// Use general list for pending orders with buyer ID == account and seller ID == account

// !!Buy Order for personal buy orders
// !!sell order for personal sell order
// !!activities for sell && buy orders
// !!so you can remove pending orders after that