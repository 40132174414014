import React, { useEffect, useState } from "react";

import BuyOrder_Made from "../../components/modals/BuyOrder_Made"
import AllErros from "../../components/modals/BuyOrder_Error";

import { cryptoExchangeABI } from "../../abi/cryptoExchangeABI"
import { ERC20ABI } from "../../abi/ERC20ABI"
import { contractAddress } from "../../App";

import { useContract, useSigner } from 'wagmi'

import { ethers } from "ethers";
import Web3 from "web3";



function OfferDetails(props) {

    const [ metaDescription, setMetaDescription ] = useState("")
    const [ metaMethod, setMetaMethod ] = useState("")

    useEffect(() => {
        if(props.metadataURI === "0x96ee3279dd30231650e0b4a1a3516ab3dc26b6d3dfcb6ef20fb4329cfc1213e1") {
            setMetaMethod("Bank Transfer")
            setMetaDescription("We are 100% Fast and legit, we send crypto in seconds. We can help purchase crypto in bulks and in unit, more than $6,000 per day.")
        } else {
            // setMetaMethod("Bank Transfer")
            // setMetaDescription("We are 100% Fast and legit, we send crypto in seconds. We can help purchase crypto in bulks and in unit, more than $6,000 per day.")
            // const tryme = ethers.utils.parseBytes32String(props.metadataURI)
            // const tryme1 = Web3.utils.hexToAscii(props.metadataURI)
            const getIpfs = async () => {
                const tryme = Web3.utils.toUtf8(props.metadataURI)
                console.log(props.metadataURI)
                console.log(tryme)
                
                await fetch("https://p2pfiv1.infura-ipfs.io/ipfs/" + tryme)
                .then((r) => r.json())
                .then((r) => {
                    // console.log(r)
                    setMetaMethod(r.method)
                    setMetaDescription(r.description)
                });
            }
            getIpfs()
        }
    }, [])

    const { data: signer, isError, isLoading } = useSigner()
       
    const contract = useContract({
      address: contractAddress,
      abi: cryptoExchangeABI,
      signerOrProvider: signer,
    })

    const [ theLoader, setTheLoader ] = useState("Buy Now")


     const [ openBuyOrderMade, setOpenBuyOrderMade ] = useState(false);

    const openedBuyOrderMade = () => {
        setOpenBuyOrderMade(current => !current);
    }


    const [ theAllErrors, setTheAllErrors ] = useState(false);

    const openedAllErrors = () => {
        setTheAllErrors(current => !current);
    }


    const openSwap2 = () => {
        document.getElementById("swap-2").style.display = "block"
        document.getElementById("swap-1").style.display = "none"
    }

    const openSwap1 = () => {
        document.getElementById("swap-1").style.display = "block"
        document.getElementById("swap-2").style.display = "none"
    }

    const [ rate, setRate ] = useState('');

    const nairaRate = rate * 0.9183 * 710;

    const convertRate = nairaRate.toLocaleString("en-US")

    const handleSubmit = async (e) => {
        setTheLoader(
            <>
                <span className="loader"></span>
            </>
        )
        e.preventDefault();
        console.log(rate + "ETH" + " = " + convertRate + "naira")
        // setRate('')


        const placeBuyOrder = async () => {
            try {
                await contract.placeBuyOrder(props.orderID, rate, props.metadataURI)
                .then(response => {
                    console.log(response);
                    openedBuyOrderMade()
                    console.log("Order Created")
                    setTheLoader("Buy Now")
                })
                
            } catch(err) {
                console.log(err)
                openedAllErrors()
                setTheLoader("Buy Now")
            }
          }
          placeBuyOrder()
        
    }

 

    return(
        <>
            <div id="viewOfferDetails" key={props.orderID}>
                <div className="offer--details">
                    <div className="w3-row offer--details-row">
                        <div className="w3-col offer--details-div1">
                            <div className="w3-left">
                                <i className="offer--details-icon fa-regular fa-circle-user"></i>
                                <span className="offer--details-t1">{props.sellerID}</span><br/><span className="offer--details-t2">xxx trades xx% completion </span>
                            </div>
                            <div className="w3-right">
                                <span className="offer--details-t3" style={{"margin": "0", "marginRight": "42px"}}>xxx Reps</span><br/><span className="offer--details-t4" style={{"position": "relative", "right": "-24px"}}>Order ID: {props.orderID}</span>
                            </div>
                            <br/><br/> <br/>
                            <p className="offer--details-t5">Lowest Purchase Amount :</p>
                            <p className="offer--details-t6">{props.lowestPurchaseAmount} ETH</p>

                            <p className="offer--details-t7">Available :</p>
                            <p className="offer--details-t8">{props.totalPurchaseAmount} ETH</p>

                            <button className="offer--details-btn1">{metaMethod}</button>
                        </div>

                        <form onSubmit={handleSubmit} className="w3-col offer--details-div2">
                           
                            <div className="w3-row" id="swap-1">
                                <div className="w3-left">
                                    <label className="offer--details-t9">Amount in ETH</label>
                                    <br/>
                                    <input
                                    className="offer--details-input2"
                                    placeholder="ETH"
                                    type="number"
                                    autoComplete="off"
                                    onChange={(e) => setRate(e.target.value)}
                                    value={rate}
                                    required
                                    />
                                </div>
                                <i onClick={openSwap2} className="w3-center fa-solid fa-right-left offer--details-icon2"></i>
                                <div className="w3-right">
                                    <label className="offer--details-t9">Amount in Naira</label><br/>
                                    <input
                                    className="offer--details-input2"
                                    placeholder="NGN"
                                    value={convertRate}
                                    disabled
                                    />
                                </div>
                           </div>
                           
                           <div className="w3-row" id="swap-2">
                                <div className="w3-left">
                                    <label className="offer--details-t9">Amount in Naira</label>
                                    <br/>
                                    <input
                                    className="offer--details-input2"
                                    placeholder="NGN"
                                    value={convertRate}
                                    disabled
                                    />
                                </div>
                                <i onClick={openSwap1} className="w3-center fa-solid fa-right-left offer--details-icon2"></i>
                                <div className="w3-right">
                                    <label className="offer--details-t9">Amount in ETH</label><br/>
                                    <input
                                    className="offer--details-input2"
                                    placeholder="ETH"
                                    type="number"
                                    autoComplete="off"
                                    onChange={(e) => setRate(e.target.value)}
                                    value={rate}
                                    required
                                    />
                                </div>
                           </div>


                           <button className="offer--details-btn2">{theLoader}</button>
                           <p className="offer--details-t10">Offer requirements</p>
                           <p className="offer--details-t11">{metaDescription}</p>
                        </form>
                    </div>
                </div>



                {        openBuyOrderMade &&    <BuyOrder_Made
                                                    key = {props.orderID}
                                                    orderID = {props.orderID}
                                                    sellerID = {props.sellerID}
                                                    // openPaymentConfirm = {openPaymentConfirm}
                                                    convertRate = {convertRate}
                                                    realRate = {rate}
                                                    metadataURI = {props.metadataURI}
                                                    newToToken = {props.newToToken}
                                                    openedBuyOrderMade = {openedBuyOrderMade}
                                            />
                }

                {  theAllErrors &&  <AllErros openedAllErrors = {openedAllErrors}/> }


                {/* <div className="w3-modal" id="bank-payment">
                    <div className="offer--details-modal w3-modal-content w3-animate-top">
                        <div className="w3-row">
                            <div className="w3-left">
                                <i className="fa-regular fa-circle-user offer--details-modal-icon1"></i>
                                <span className="offer--details-modal-t1">{props.sellerID}</span>
                                <p className="offer--details-modal-t2">Seen 4 minutes ago</p>
                            </div>
                            <div className="w3-right">
                                <p className="offer--details-modal-t3">NGN 200,000</p>
                                <p className="offer--details-modal-t4">Pay P2PFi.eth</p>
                            </div>
                        </div>
                        <div className="w3-row">
                            <div>
                                <p className="w3-left offer--details-modal-t5">Account Number</p>
                                <p className="w3-right offer--details-modal-t6">045858493</p>
                            </div>
                        </div>
                        <div className="w3-row">
                            <div>
                                <p className="w3-left offer--details-modal-t5">Bank Name</p>
                                <p className="w3-right offer--details-modal-t6">Sterling Bank</p>
                            </div>
                        </div>
                        <div className="w3-row">
                            <div>
                                <p className="w3-left offer--details-modal-t5">Account Name</p>
                                <p className="w3-right offer--details-modal-t6">Gabriel Jesus</p>
                            </div>
                        </div>
                        <div className="w3-row">
                            <div>
                                <p className="w3-left offer--details-modal-t5">Time Remaining</p>
                                <p className="w3-right offer--details-modal-t6">20Mins, 50Secs</p>
                            </div>
                        </div>
                        <button onClick={openPaymentConfirm} className="offer--details-modal-btn1">I have made payment</button>
                    </div>
                </div>

                <div className="w3-modal" id="abeg-payment">
                    <div className="offer--details-modal w3-modal-content w3-animate-top">
                        <p className="w3-left offer--details-modall-t1">{props.sellerID}</p>
                        <br />
                        <hr className="offer--details-modall-hr"/>
                        <p className="offer--details-modall-t2">₦200,000,000.00</p>
                        <div className="w3-center">
                            <button className="offer--details-modall-btn1">@designezemmuo</button>
                            <button className="offer--details-modall-btn2"><i className="fa-regular fa-copy" style={{"marginRight": "6px"}}></i>Copy</button>
                        </div>
                        <button onClick={openPaymentConfirm} className="offer--details-modall-btn3">I have made payment</button>
                    </div>
                </div> */}



                {/* New Commented Code */}



                {/* <div className="w3-modal" id="payment-confirmation">
                    <div className="offer--details-modal2 w3-modal-content w3-animate-bottom">
                        <i className="offer--details-modal2-icon1 fa-solid fa-thumbs-up"></i>
                        <p className="offer--details-modal2-t1">Payment Successful</p>
                        <p className="offer--details-modal2-t2">Please wait while P2PFi receives confirmation from seller to release coins to you.</p>
                        <Link to="../profile" className="link"><button className="offer--details-modal2-btn1">View Transaction History</button></Link>
                        <Link to="../profile" className="link"><button className="offer--details-modal2-btn2">Back to Home</button></Link>
                    </div>
                </div>

                <div className="w3-modal2 w3-right" id="my-notifications">
                    <div className="my-notifications w3-modal-content my-notifcations w3-right w3-animate-right">
                    <i onClick={closeNotification} className="fa-solid fa-xmark w3-right my-rate-us-x"></i>
                        <p className="my-notifications-t1">Notifications</p>
                        <i className="fa-solid fa-bell my-notifications-icon"></i>
                        <span className="my-notifications-t2"><strong>P2PFi.eth</strong> confirmed your payment. <strong onClick={openRateUs} style={{"cursor": "pointer"}}>Rate Now</strong></span>
                    </div>
                </div>

                <div className="w3-modal2 w3-right" id="rate-us">
                    <div className="my-rate-us w3-modal-content w3-right w3-animate-right">
                    <i onClick={closeRateUs} className="fa-solid fa-xmark w3-right my-rate-us-x"></i>
                        <p className="my-rate-us-t1">Rate</p>
                        <p className="my-rate-us-t2">How statisfied are you after trading with <strong>P2PFi.eth</strong> ?</p>
                        <p className="my-rate-us-icons">
                            <span className="my-rate-us-icon">😒</span>
                            <span className="my-rate-us-icon">🥲</span>
                            <span className="my-rate-us-icon">😐</span>
                            <span className="my-rate-us-icon">🙂</span>
                            <span className="my-rate-us-icon">😆</span>
                        </p>
                    </div>
                </div>



                    <div className="w3-modal" id="selloffer--countdown">
                        <div className="selloffer--confirmation w3-modal-content w3-animate-top ">
                            <p className="selloffer--confirmation-icon3 fa-solid" style={{"marginTop": "0px"}} id="timer"></p>
                            <p className="selloffer--confirmation-t1" style={{"marginTop": "-24px"}}>Trade Countdown</p>
                            <p className="selloffer--confirmation-t2">Please wait while P2PFi receives confirmation from seller to release coins to you.</p>
                            <button onClick={closeCountdown} className="selloffer--confirmation-btn1">Cancel Trade</button>
                            <button className="selloffer--confirmation-btn2">View Trade History</button>
                        </div>
                    </div> */}
            </div> 
        </>
    )
}

export default OfferDetails