import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom"
import HomePage_Header from "../components/HomePage_Header"
import Home_Footer from "../components/Home_Footer"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Home(props) {

    gsap.registerPlugin(ScrollTrigger);
    const featureRef = useRef(null);
    const sliderRef = useRef(null);

    // Feature Animes

      useEffect(() => {
        const element = featureRef.current;
        gsap.fromTo(
          element.querySelector(".section1-box1"),
          {
            opacity: 0.6,
            scale: 0.5,
            rotation:-35,
            y: 0
          },
          {
            opacity: 1,
            y: 0,
            scale: 1,
            rotation:0,
            duration: 1,
            ease: "none",
            scrollTrigger: {
              trigger: element.querySelector(".section1"),
              start: "-100px",
              end: "1000px",
              scrub: true,
              // markers: true,
            }
          }
        );
      }, []);
      
      useEffect(() => {
        const element = featureRef.current;
        gsap.fromTo(
          element.querySelector(".section1-box2"),
          {
            opacity: 0.6,
            scale: 0.5,
            rotation:-35,
            y: 0,
            delay: 1
          },
          {
            opacity: 1,
            y: 0,
            scale: 1,
            rotation:0,
            duration: 1,
            ease: "none",
            scrollTrigger: {
              trigger: element.querySelector(".section1"),
              start: "600px",
              end: "1500px",
              scrub: true,
              // markers: true,
            }
          }
        );
      }, []);

      useEffect(() => {
        const element = featureRef.current;
        gsap.fromTo(
          element.querySelector(".section1-box3"),
          {
            opacity: 0.6,
            scale: 0.5,
            rotation:35,
            y: 0,
            delay: 1
          },
          {
            opacity: 1,
            y: 0,
            scale: 1,
            rotation:0,
            duration: 1,
            ease: "none",
            scrollTrigger: {
              trigger: element.querySelector(".section1"),
              start: "100px",
              end: "1100px",
              scrub: true,
              // markers: true,
            }
          }
        );
      }, []);

      useEffect(() => {
        const element = featureRef.current;
        gsap.fromTo(
          element.querySelector(".section1-box4"),
          {
            opacity: 0.6,
            scale: 0.5,
            rotation:35,
            y: 0
          },
          {
            opacity: 1,
            y: 0,
            scale: 1,
            rotation:0,
            duration: 1,
            ease: "none",
            scrollTrigger: {
              trigger: element.querySelector(".section1"),
              start: "800px",
              end: "1500px",
              scrub: true,
              // markers: true,
            }
          }
        );
      }, []);

    // Slider Animes

    useEffect(() => {
      const element = sliderRef.current;
      gsap.fromTo(
        element.querySelector(".home--slideHeadAnime"),
        {
          y: 0,
          scale: 1.25
        },
        {
          y: 0,
          scale: 1,
          duration: 1,
          ease: "none",
          scrollTrigger: {
            trigger: element.querySelector(".sliderAnimes"),
            start: "1650px",
            end: "1900px",
            scrub: true,
            // markers: true,
          }
        }
      );
    }, []);

    useEffect(() => {
      const element = sliderRef.current;
      gsap.fromTo(
        element.querySelector(".home--slideSubAnime"),
        {
          y: 0,
          scale: 0.75
        },
        {
          y: 0,
          scale: 1,
          duration: 1,
          ease: "none",
          scrollTrigger: {
            trigger: element.querySelector(".sliderAnimes"),
            start: "1700px",
            end: "1950px",
            scrub: true,
            // markers: true,
          }
        }
      );
    }, []);

    useEffect(() => {
      const element = sliderRef.current;
      gsap.fromTo(
        element.querySelector(".home--slide1Anime"),
        {
          y: 0,
          x: "-100px",
        },
        {
          opacity: 1,
          y: 0,
          x: 0,
          scale: 1,
          duration: 1,
          ease: "none",
          scrollTrigger: {
            trigger: element.querySelector(".sliderAnimes"),
            start: "1800px",
            end: "2000px",
            scrub: true,
            // markers: true,
          }
        }
      );
    }, []);

    useEffect(() => {
      const element = sliderRef.current;
      gsap.fromTo(
        element.querySelector(".home--slide2Anime"),
        {
          y: 0,
          x: "-100px",
        },
        {
          opacity: 1,
          y: 0,
          x: 0,
          scale: 1,
          duration: 1,
          ease: "none",
          scrollTrigger: {
            trigger: element.querySelector(".sliderAnimes"),
            start: "1900px",
            end: "2100px",
            scrub: true,
            // markers: true,
          }
        }
      );
    }, []);

    useEffect(() => {
      const element = sliderRef.current;
      gsap.fromTo(
        element.querySelector(".home--slide3Anime"),
        {
          y: 0,
          x: "-100px",
        },
        {
          opacity: 1,
          y: 0,
          x: 0,
          scale: 1,
          duration: 1,
          ease: "none",
          scrollTrigger: {
            trigger: element.querySelector(".sliderAnimes"),
            start: "2000px",
            end: "2200px",
            scrub: true,
            // markers: true,
          }
        }
      );
    }, []);

    useEffect(() => {
      const element = sliderRef.current;
      gsap.fromTo(
        element.querySelector(".home--slide4Anime"),
        {
          y: 0,
          x: "-100px",
        },
        {
          opacity: 1,
          y: 0,
          x: 0,
          scale: 1,
          duration: 1,
          ease: "none",
          scrollTrigger: {
            trigger: element.querySelector(".sliderAnimes"),
            start: "2100px",
            end: "2300px",
            scrub: true,
            // markers: true,
          }
        }
      );
    }, []);



    const [ sli1, setSli1 ] = useState("600")
    const [ sli2, setSli2 ] = useState("400")
    const [ sli3, setSli3 ] = useState("400")
    const [ sli4, setSli4 ] = useState("400")


    const open_1 = () => {
        document.getElementById("Slide_1").style.display = "block"
        document.getElementById("Slide_2").style.display = "none"
        document.getElementById("Slide_3").style.display = "none"
        document.getElementById("Slide_4").style.display = "none"
        document.getElementById("home--slide1").focus();
        setSli1("600")
        setSli2("400")
        setSli3("400")
        setSli4("400")
    }

    const open_2 = () => {
        document.getElementById("Slide_1").style.display = "none"
        document.getElementById("Slide_2").style.display = "block"
        document.getElementById("Slide_3").style.display = "none"
        document.getElementById("Slide_4").style.display = "none"
        document.getElementById("home--slide2").focus();
        setSli1("400")
        setSli2("600")
        setSli3("400")
        setSli4("400")
    }

    const open_3 = () => {
        document.getElementById("Slide_1").style.display = "none"
        document.getElementById("Slide_2").style.display = "none"
        document.getElementById("Slide_3").style.display = "block"
        document.getElementById("Slide_4").style.display = "none"
        document.getElementById("home--slide3").focus();
        setSli1("400")
        setSli2("400")
        setSli3("600")
        setSli4("400")
    }

    const open_4 = () => {
        document.getElementById("Slide_1").style.display = "none"
        document.getElementById("Slide_2").style.display = "none"
        document.getElementById("Slide_3").style.display = "none"
        document.getElementById("Slide_4").style.display = "block"
        document.getElementById("home--slide4").focus();
        setSli1("400")
        setSli2("400")
        setSli3("400")
        setSli4("600")
    }

    const join = () => {
        document.getElementById("join").style.display = "block"
    }

    const close_join = () => {
        document.getElementById("join").style.display = "none"
        document.location.reload()
    }

    const hurray = () => {
        document.getElementById("join-wait--t3").style.display = "block"
    }

    


    return(
        <>
            <div className="">
            <HomePage_Header />
                <div className="home--hero">
                    <div className="cubed">
                        <div className="cube">&#x2B22;</div>
                        <div className="cube">&#x2B22;</div>
                        <div className="cube">&#x2B22;</div>
                        <div className="cube">&#x2B22;</div>
                        <div className="cube">&#x2B22;</div>
                        <div className="cube">&#x2B22;</div>
                    </div>
                    <p className="home--t2 w3-animate-right">Buy and sell cryptocurrency in your local currency</p>
                    <Link to="../connect" className="link"><button onClick={join} className="home--btn-1 w3-animate-bottom">Get Started Now</button></Link>
                </div>
                <div className="section1" ref={featureRef}>
                    <div className="w3-row-padding">
                        <div className="home--col-1 section1-box1">
                            <i className="fa-regular fa-circle-user home--col-icon1"></i>
                            <p className="home--t3">No Identity Verification</p>
                            <p className="home--t4">Get started in seconds. No need to wait for approval from a central authority.</p>
                        </div>
                        <div className="home--col-2 section1-box2">
                            <i className="fa-regular fa-circle-user home--col-icon2"></i>
                            <p className="home--t3">Decentralized</p>
                            <p className="home--t4">Trading takes place on a global P2P network of users running their crypto wallets from their individual devices.</p>
                        </div>
                    </div>
                    
                    <div className="home--row w3-row-padding">
                        <div className="home--col-2 section1-box3">
                            <i className="fa-regular fa-circle-user home--col-icon2"></i>
                            <p className="home--t3">Easy to Use</p>
                            <p className="home--t4">We've made all the trading sophistication simple - make your first trade in under 2 minutes!</p>
                        </div>
                        <div className="home--col-1 section1-box4">
                            <i className="fa-regular fa-circle-user home--col-icon1"></i>
                            <p className="home--t3">Safe</p>
                            <p className="home--t4">P2PFi never holds your funds. Traders only authorize the funds they want to sell from their wallets, encouraging safe, successful trades.</p>
                        </div>
                    </div>
                </div>

                <div className="section2">
                    <div className="w3-row">
                        <div className="w3-col l4 home--col-3 sliderAnimes" ref={sliderRef}>
                            <p className="home--t4b home--slideHeadAnime">How to Get Started</p>
                            <p className="home--t4c home--slideSubAnime">With P2PFi, you can start trading in less than 2 minutes:</p>
                            <p className="home--t4d home--slides home--slide1Anime" onClick={open_1} id="home--slide1" style={{"fontWeight": `${sli1}`}}>1. Visit our website, https://p2pfi.co</p>
                            <hr />
                            <p className="home--t4d home--slides home--slide2Anime" onClick={open_2} id="home--slide2" style={{"fontWeight": `${sli2}`}}>2. Connect your crypto wallet to P2PFi by simply clicking "Connect Wallet" button.</p>
                            <hr />
                            <p className="home--t4d home--slides home--slide3Anime" onClick={open_3} id="home--slide3" style={{"fontWeight": `${sli3}`}}>3. Create a new sell order or take an existing sell order from the Order list.</p>
                            <hr />
                            <p className="home--t4d home--slides home--slide4Anime" onClick={open_4} id="home--slide4" style={{"fontWeight": `${sli4}`}}>4. Follow the user interface to finalize the trade.</p>
                            <hr />
                        </div>
                        <div className="w3-col l8 home--col-4">
                            <img src="/images/slider-1.png"
                            className="home--sec2-img-1 w3-animate-left"
                            alt="P2PFi"
                            id="Slide_1"
                            />
                            <img src="/images/slider-2.png"
                            className="home--sec2-img-2 w3-animate-right"
                            alt="P2PFi"
                            id="Slide_2"
                            />
                            <div id="Slide_3">
                                <img src="/images/slider-3.png"
                                className="home--sec2-img-3 w3-animate-bottom"
                                alt="P2PFi"
                                />
                                <img src="/images/slider-4.png"
                                className="home--sec2-img-4 w3-animate-top"
                                alt="P2PFi"
                                />
                            </div>
                            <div id="Slide_4">
                                <img src="/images/slider-5.png"
                                className="home--sec2-img-5 w3-animate-bottom"
                                alt="P2PFi"
                                />
                                <img src="/images/slider-6.png"
                                className="home--sec2-img-6 w3-animate-bottom"
                                alt="P2PFi"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <form action="https://formsubmit.co/demoyou@yahoo.com" method="POST" id="join" className="w3-modal">
                    <div className="w3-modal-content w3-animate-zoom join-wait">
                        <i onClick={close_join} className="fa-solid fa-xmark join-wait--icon"></i>
                        <p className="join-wait--t1">Join Waitlist</p>
                        <p className="join-wait--t2">Be the first to know when we go live.</p>
                        <input
                        className="join-wait--input"
                        type="text"
                        placeholder="Name"
                        name="name"
                        />
                        <input
                        className="join-wait--input"
                        type="text"
                        placeholder="Email Address"
                        name="email"
                        />
                        <input
                        type="hidden"
                        name="_captcha"
                        value="false"
                        />
                        <input
                        type="hidden"
                        name="_next"
                        value="https://p2pfi.herokuapp.com"
                        />
                        <button type="submit" onClick={hurray} id="join-wait--btn" className="join-wait--btn">Join Waitlist</button>
                        <p className="join-wait--t3" id="join-wait--t3">Hurray 🎉 You’ve been added to the waitlist </p>
                    </div>
                </form> */}

                <Home_Footer />
            </div>
        </>
    )
}

export default Home