import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import UpdateDisputeSuccess from "./UpdateDisputeSuccess"
import { cryptoExchangeABI } from "../../abi/cryptoExchangeABI"
import { contractAddress } from "../../App";
import AllErros from "./BuyOrder_Error";
import { useProvider, useContract, useSigner } from 'wagmi'
import Web3 from "web3";
import { create as ipfsHttpClient } from "ipfs-http-client";


const baseURL = "https://p2pfi.xyz"


const UpdateDispute = (props) => {

    const projectId = process.env.REACT_APP_PROJECT_ID;
    const projectSecretKey = process.env.REACT_APP_PROJECT_KEY;
    const authorization = "Basic " + btoa(projectId + ":" + projectSecretKey);

    const ipfs = ipfsHttpClient({
        url: "https://ipfs.infura.io:5001/api/v0",
        headers: {
          authorization,
        },
      });

    const [ buyerAcName, setBuyerAcName ] = useState("")
    const [ buyerBankName, setBuyerBankName ] = useState("")
    const [ buyerAcNumber, setBuyerAcNumber ] = useState("")
    const [ buyerTransRef, setBuyerTransRef ] = useState("")

    const provider = useProvider()
    const { data: signer, isError, isLoading } = useSigner()


    const contract = useContract({
        address: contractAddress,
        abi: cryptoExchangeABI,
        signerOrProvider: signer,
      })

    const [ openAllErros2, setOpenAllErrors2 ] = useState(false)

    const openedAllErrors2 = () => {
        setOpenAllErrors2(current => !current);
    }

    const [ openUpdatedDisputeSuccess, setOpenUpdatedDisputeSuccess ] = useState(false)
    const openedUpdatedDisputeSuccess = () => {
        setOpenUpdatedDisputeSuccess(current => !current)
    }


    const [ theLoader, setTheLoader ] = useState("I have made payment")

    async function updateDispute() {
        try { 
            const disputeData = {
                "buyOrderID": props.buyOrderID,
                "description": "Updating Payment Details",
                "fromAccountNumber": buyerAcNumber,
                "fromBankName": buyerBankName,
                "fromAccountName": buyerAcName,
                "fromTransactionRef": buyerTransRef
            }
            const makeURI = async () => {
                await ipfs.add(JSON.stringify(disputeData))
                .then((result) => {
                    const thePath = result.path
                    console.log(thePath)
                    const path1 = thePath.toString()
                    const path2 = Web3.utils.asciiToHex(path1)
                    console.log(path2)
                    if(path2 != "undefined") {
                        contract.updateDispute(props.buyOrderID, path2)
                        .then(results => {
                            console.log(results)
                            openedUpdatedDispute()
                            setTheLoader("I have made payment")
                            return;
                        }, (err) => {
                        console.log(err);
                        setTheLoader("I have made payment")
                        })
                    }
                }, (err) => {
                    console.log(err)
                    setTheLoader("I have made payment")
                })

            }
            makeURI()
        } catch(err) {
            console.log(err);
            openedAllErrors2()
            setTheLoader("I have made payment")
        }
      }


    const handleSubmit = () => {
        setTheLoader(
            <>
                <span className="loader"></span>
            </>
        )
        setTimeout(() => {
            updateDispute()
        }, 1000)
    }




    const [ apiUsernameX, setApiUsernameX ] = useState("")

      useEffect(() => {

        const apiIsQuery2 = async () => {
          await axios.get(baseURL + "/api/users")
            .then(async (response) => {
              const newResponse = response.data.User;
              const hehehe = newResponse.filter((mapped) => {
                return (mapped.walletAddress).toLocaleLowerCase() === (props.sellerAddress).toLocaleLowerCase()
              })
              const halo = hehehe.map((data) => {
                setApiUsernameX(data.username)

                console.log(data.username, data.bankName1)
                return;
              })
            }, (error) => {
              console.log(error);
            });
        }
        apiIsQuery2()

    }, [props.sellerAddress]);
    

    return(
        <>
            <div className="w3-modal" id="bank-payment">
                <div className="offer--details-modal w3-modal-content w3-animate-top">
                    <div className="w3-row">
                        <div className="w3-left">
                            <i className="fa-regular fa-circle-user offer--details-modal-icon1"></i>
                            <span className="offer--details-modal-t1">{props.buyOrderID}</span>
                            <p className="offer--details-modal-t2">Update Dispute</p>
                        </div>
                        <div className="w3-right">
                            <p className="offer--details-modal-t3">NGN {(props.buyAmount * 710 * 1).toLocaleString("en-US")}</p>
                            <p className="offer--details-modal-t4">To {apiUsernameX}</p>
                        </div>
                    </div>
                    <div style={{"position": "relative", "top": "-20px"}}>
                        <div className="w3-row">
                            <div>
                                <p className="w3-left offer--details-modal-t5">From Account Number</p>
                                <input 
                                className="w3-right offer--details-modal-t6"
                                placeholder=""
                                type="number"
                                onChange={e => setBuyerAcNumber(e.target.value)}
                                value={buyerAcNumber}
                                style={{
                                    "outline": "none",
                                    "border": "none",
                                    "borderBottom": "2px solid #000",
                                    "width": "150px",
                                    "position": "relative",
                                    "top": "8px",
                                    "padding": "2px 6px"
                                }}
                                />
                            </div>
                        </div>
                        <div className="w3-row">
                            <div>
                                <p className="w3-left offer--details-modal-t5">From Bank Name</p>
                                <input 
                                className="w3-right offer--details-modal-t6"
                                placeholder=""
                                onChange={e => setBuyerBankName(e.target.value)}
                                value={buyerBankName}
                                style={{
                                    "outline": "none",
                                    "border": "none",
                                    "borderBottom": "2px solid #000",
                                    "position": "relative",
                                    "width": "150px",
                                    "top": "8px",
                                    "padding": "2px 6px"
                                }}
                                />
                            </div>
                        </div>
                        <div className="w3-row">
                            <div>
                                <p className="w3-left offer--details-modal-t5">From Account Name</p>
                                <input 
                                className="w3-right offer--details-modal-t6"
                                placeholder=""
                                onChange={e => setBuyerAcName(e.target.value)}
                                value={buyerAcName}
                                style={{
                                    "outline": "none",
                                    "border": "none",
                                    "borderBottom": "2px solid #000",
                                    "position": "relative",
                                    "width": "150px",
                                    "top": "8px",
                                    "padding": "2px 6px"
                                }}
                                />
                            </div>
                        </div>

                        <div className="w3-row">
                            <div>
                                <p className="w3-left offer--details-modal-t5">Transaction Ref</p>
                                <input 
                                className="w3-right offer--details-modal-t6"
                                placeholder=""
                                onChange={e => setBuyerTransRef(e.target.value)}
                                value={buyerTransRef}
                                style={{
                                    "outline": "none",
                                    "border": "none",
                                    "borderBottom": "2px solid #000",
                                    "position": "relative",
                                    "top": "8px",
                                    "padding": "2px 6px"
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <button disabled={!buyerAcName || !buyerAcNumber || !buyerBankName || !buyerTransRef} onClick={handleSubmit} className="offer--details-modal-btn1">{theLoader}</button>
                </div>
            </div>

            {
                openUpdatedDisputeSuccess && <UpdateDisputeSuccess openedUpdatedDisputeSuccess = {openedUpdatedDisputeSuccess}/>
            }
            {
                openAllErros2 && <AllErros 
                                    errorID = {props.buyOrderID}
                                    openedAllErrors = {openedAllErrors2}
                                />
            }
        </>
    )
}

export default UpdateDispute