import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom"
import Home_Header from "../../components/Home_Header";
import Home_Footer from "../../components/Home_Footer";

import { contractAddress } from "../../App"
import { ERC20ABI } from "../../abi/ERC20ABI"
import { cryptoExchangeABI } from "../../abi/cryptoExchangeABI"

import { useProvider, useContract, useSigner, useAccount } from 'wagmi'
import { ethers } from "ethers";

import { create as ipfsHttpClient } from "ipfs-http-client";

import Web3 from "web3";



function CreateSellOffer(props) {

    const [ errorMsg, setErrorMsg ] = useState("")

    const projectId = process.env.REACT_APP_PROJECT_ID;
    const projectSecretKey = process.env.REACT_APP_PROJECT_KEY;
    const authorization = "Basic " + btoa(projectId + ":" + projectSecretKey);

    const ipfs = ipfsHttpClient({
        url: "https://ipfs.infura.io:5001/api/v0",
        headers: {
          authorization,
        },
      });

    const { isDisconnected } = useAccount()

    if (isDisconnected) {
        const urlAddress = window.location.pathname.split("/");
        window.location.href = urlAddress[0] + "/connect"
        console.log(urlAddress[1])
    }

    const [ signerID, setSignerID ] = useState()

    const [ account, setAccount ] = useState()

    const headerToOthers1 = (addressData) => {
        setAccount(addressData)
    }

    const [ toToken, setToToken ] = useState("")

    useEffect(() => {
        if (token === "Ethereum") {
            setToToken("0x7ceb23fd6bc0add59e62ac25578270cff1b9f619")
        } else if (token === "USDC") {
            setToToken("0xe11a86849d99f524cac3e7a0ec1241828e332c62")
        }
    })

    

    const provider = useProvider()

    const contract3 = useContract({
        address: contractAddress,
        abi: cryptoExchangeABI,
        signerOrProvider: provider,
      })

      const contract4 = useContract({
        address: contractAddress,
        abi: ERC20ABI,
        signerOrProvider: provider,
      })

    const { data: signer, isError, isLoading } = useSigner()

    console.log(signer) 
       
    const contract = useContract({
      address: contractAddress,
      abi: cryptoExchangeABI,
      signerOrProvider: signer,
    })

    const contract2 = useContract({
        address: contractAddress,
        abi: ERC20ABI,
        signerOrProvider: signer,
      })

    const contract5 = useContract({
        address: toToken,
        abi: ERC20ABI,
        signerOrProvider: signer,
      })

    const contract6 = useContract({
        address: toToken,
        abi: ERC20ABI,
        signerOrProvider: provider,
      })



    const [ theLoader, setTheLoader ] = useState("Create Offer")



    const [ token, setToken] = useState();
    const [ amount, setAmount] = useState();
    const [ LowestAmount, setLowestAmount] = useState();
    const [ waitTime, setWaitTime] = useState();
    const [ method, setMethod] = useState();
    const [ instructions, setInstructions] = useState();
    const [ error, setError ] = useState('');

  //   useEffect(() => {
  //     useRef.current.focus();
  //   }, [])

  const [ lowAmountWarning, setLowAmountWarning ] = useState("")

    useEffect(() => {
      setError('');
    }, [token, amount, LowestAmount, waitTime, method, instructions])

    useEffect(() => {
        if (LowestAmount > amount) {
            setLowAmountWarning("Please Enter Amount greater than Lowest Purchase Amount")
        } else if (LowestAmount < amount) {
            setLowAmountWarning("")
        }
    })

    const handleSubmit = async (e) => {
        setTheLoader(
            <>
                <span className="loader"></span>
            </>
        )
      e.preventDefault();
      console.log(token, amount, LowestAmount, waitTime, method, instructions)
    //   setToken('')
    //   setAmount('')
    //   setLowestAmount('')
    //   setWaitTime('')
    //   setMethod('')
    //   setInstructions('')


    balanceOf()


    }


    const openError = () => {
        document.getElementById("openError").style.display = "block";
        document.getElementById("selloffer--created").style.display = "none"
        document.getElementById("approve-offer").style.display = "none"
    }

    const closeError = () => {
        document.getElementById("openError").style.display = "none";
    }
  


    const openOfferMade = () => {
        document.getElementById("progress2t").style.display = "none"
        document.getElementById("progress2btn").style.display = "none"
        document.getElementById("progress2b").style.display = "none"
        document.getElementById("progress2c").style.display = "block"
        document.getElementById("closeApprove-x").style.display = "block"
        // createSellOrder()
        setTimeout(openOfferCreated, 5000)
    }

    const openOfferCreated = () => {
        document.getElementById("selloffer--created").style.display = "block"
    }

    const closeOfferCreated = () => {
        document.getElementById("selloffer--created").style.display = "none"
    }

    const nextApprove = () => {
        document.getElementById("progress1a").style.display = "none"
        document.getElementById("progress1t").style.display = "none"
        document.getElementById("progress1btn").style.display = "none"
        document.getElementById("progress1b").style.display = "none"
        document.getElementById("progress2a").style.display = "block"
        document.getElementById("progress2b").style.display = "block"
        document.getElementById("progress2t").style.display = "block"
        document.getElementById("progress2btn").style.display = "block"

        createSellOrder()

    }

    const openFirstApprove = () => {
        document.getElementById("approve-offer").style.display = "block"
        document.getElementById("progress2a").style.display = "none"
        document.getElementById("progress1a").style.display = "block"

        if (theAllowance >= amount) {
            openSecondApprove()
        } else {
            approveDeposit()
            // openError()
            // setErrorMsg("Please Wait...Check your Wallet")
        }


    }
    
    const [ theAllowance, setTheAllowance ] = useState(0)

    const checkAllowance = async () => {
          await contract6.allowance(account, contractAddress)
            .then((res) => {
                console.log(res)
                const allHex = res._hex;
                console.log(allHex)
                const finalAllowance = Web3.utils.hexToNumber(allHex)
                console.log(finalAllowance);
                setTheAllowance(finalAllowance)
            }, (err) => {
                console.log(err)
            })
    }

    const openSecondApprove = () => {
        document.getElementById("approve-offer").style.display = "block"
        document.getElementById("progress1a").style.display = "none"
        document.getElementById("progress2a").style.display = "block"

        makeDeposit()
    }

    const closeApprove = () => {
        document.getElementById("approve-offer").style.display = "none"
        window.location.href = "../profile";
    }

    const openConfirm = () => {
        document.getElementById("selloffer--confirmation").style.display = "block"
    }

    const closeConfirm = () => {
        document.getElementById("selloffer--confirmation").style.display = "none"
    }

    const openConfirmSuccess = () => {
        document.getElementById("selloffer--confirmation-success").style.display = "block"
        document.getElementById("selloffer--confirmation").style.display = "none"

    }

    const closeConfirmSuccess = () => {
        document.getElementById("selloffer--confirmation-success").style.display = "none"
    }

    const openCancel = () => {
        document.getElementById("selloffer--cancel").style.display = "block"
        document.getElementById("selloffer--confirmation").style.display = "none"
    }

    const closeCancel = () => {
        document.getElementById("selloffer--cancel").style.display = "none"
    }



    const increment = () => {
        document.getElementById("crement").stepUp();
    }

    const decrement = () => {
        document.getElementById("crement").stepDown();
    }

    //  let getBalance
    const [ contractBalance, setContractBalance ] = useState("Your Balance with us is:    " + (0 * 1).toLocaleString("en-US"))

     async function balanceOf() {

        setTheLoader(
            <>
                <span className="loader"></span>
            </>
        )

        //  const provider = new ethers.providers.Web3Provider(window.ethereum);
        //  const signer = provider.getSigner();
        //  const contract = new ethers.Contract(contractAddress, cryptoExchangeABI, signer);
        
          const getBalance = await contract3.balanceOf(account, toToken)
         .then((response) => {
            console.log(response)
            const asdff = response._hex;
            console.log(asdff)
            const realBalance = Web3.utils.hexToNumber(asdff)

            console.log(realBalance);
   
            setContractBalance("Your Balance with us is:    " + (realBalance * 1).toLocaleString("en-US"))
            checkAllowance()

               if (realBalance >= amount) {
                   createSellOrder()
                   console.log(amount)
                   console.log(realBalance)
               } else if(amount >= realBalance) {
                   console.log(amount)
                  openFirstApprove()
               }
         }, (err) => {
            console.log(err)
         })


     }


    async function makeDeposit() {

        setTheLoader(
            <>
                <span className="loader"></span>
            </>
        )

        // const provider = new ethers.providers.Web3Provider(window.ethereum);
        // const signer = provider.getSigner();
        // const contract = new ethers.Contract(contractAddress, cryptoExchangeABI, signer);
        
        try {
            await contract.deposit(toToken, amount)
            .then(response => {
                nextApprove()
                console.log(response)
                console.log("Deposit Created")
            })
        } catch(err) {
            console.log(err)
            console.log("Deposit Failed");
            openError()
            setTheLoader("Create Offer")
            setErrorMsg(err?.error?.data?.message || err?.code)
        }

    }

    async function approveDeposit() {

        setTheLoader(
            <>
                <span className="loader"></span>
            </>
        )

        // const provider = new ethers.providers.Web3Provider(window.ethereum);
        // const signer = provider.getSigner();
        // const contract = new ethers.Contract(toToken, ERC20ABI, signer);
        
        try {
            await contract5.approve(contractAddress, amount)
            .then(response => {
                console.log(response)
                console.log("Approved")
                setTimeout(() => {
                    openSecondApprove()
                }, 5000)
            })
        } catch(err) {
            console.log(err)
            openError()
            setTheLoader("Create Offer")
            setErrorMsg(err?.error?.data?.message)
        }

    }
    
    const [ metaDataUri, setMetaDataUri ] = useState("")

    useEffect(() => {
        const metaData = {
            "method": method,
            "description": instructions
        }
        const handleBla = async () => {
            const result = await ipfs.add(JSON.stringify(metaData));
            const meme = result.path
            console.log(meme)
            const memeA = meme.toString()
            const meme2 = Web3.utils.asciiToHex(memeA)
            console.log(meme2)
            setMetaDataUri(meme2)
        }
        handleBla()
    })


    async function createSellOrder() {
        console.log(toToken)
        try {
            await contract.placeSellOrder(toToken, waitTime, amount, LowestAmount, metaDataUri)
            .then(response => {
                console.log(response)
                console.log("SellOrder Created")
                setTheLoader("Create Offer")
                openOfferMade()
            })
        } catch(err) {
            console.log(err)
            openError()
            setTheLoader("Create Offer")
            setErrorMsg(err?.error?.data?.message)
        }
      }

      




    return(
        <>
            <Home_Header 
                account = {account}
                balance = {props.balance}
                balance2 = {props.balance2}
                headerToOthers1 = {headerToOthers1}
                apiProfileImage = {props.apiProfileImage}
                apiUsername = {props.apiUsername}
            />
                <div className="sell--offer">
                    <p className="sell--offer-t1">Create New Offer</p>

                    <form onSubmit={handleSubmit} className="sell--offer-div">
                        <p className="sell--offer-t2">Select Coin</p>
                            <select value={token} defaultValue="Select Token" onChange={(e) => setToken(e.target.value)} className="sell--offer-input">                            
                                <option 
                                    value="Null"
                                >Select Token</option>
                                {/* <option 
                                    value="Ethereum"
                                >Ethereum</option> */}
                                <option
                                    value="USDC"
                                >USDC</option>
                            </select>
                        <p><i className="sell--offer-t3">P2PFi currently support coins on the Polygon blockchain</i></p>
                        <p className="sell--offer-t2">Amount</p>
                        <input
                        className="sell--offer-input"
                        placeholder="Enter Coin Amount"
                        type="number"
                        // ref={useRef}
                        autoComplete="off"
                        onChange={(e) => setAmount(e.target.value)}
                        value={amount}
                        required
                        />
                        <p><i className="sell--offer-t3">{lowAmountWarning}</i></p>
                        <p className="sell--offer-tag w3-right">ETH</p>
                        <p className="sell--offer-t2">Lowest Purchase Amount</p>
                        <input 
                        className="sell--offer-input"
                        placeholder="Enter Lowest Purchase Amount"
                        type="number"
                        // ref={useRef}
                        autoComplete="off"
                        onChange={(e) => setLowestAmount(e.target.value)}
                        value={LowestAmount}
                        required
                        />
                        <p className="sell--offer-t2">Offer Wait Time</p>
                        <input
                        type="number"
                        className="sell--offer-input"
                        placeholder="Maximum Wait Time (1hr-5hrs)"
                        min="1"
                        max="5"
                        id="crement"
                        // ref={useRef}
                        autoComplete="off"
                        onChange={(e) => setWaitTime(e.target.value)}
                        value={waitTime}
                        required
                        />
                            <div className="sell--offer-sign w3-right">
                                <span onClick={decrement} className="sell--offer-sign1"><i className="fa-solid fa-minus"></i></span>
                                <span onClick={increment} className="sell--offer-sign2"><i className="fa-solid fa-circle-plus"></i></span>
                            </div>
                        <p className="sell--offer-t2">Select Payment Method</p>
                            <select value={method} onChange={(e) => setMethod(e.target.value)} className="sell--offer-input" required>
                                    <option
                                    value="Null"
                                    >Select Method</option>
                                <option
                                    value="Bank Transfer"
                                    >Bank Transfer</option>
                                {/* <option 
                                    value="Abeg"
                                    >Abeg</option> */}
                            </select>
                        <p className="sell--offer-t2">Trade Instructions</p>
                            <textarea
                            className="sell--offer-textarea" 
                            placeholder="Enter your trade requirements" 
                            // ref={useRef}
                            autoComplete="off"
                            onChange={(e) => setInstructions(e.target.value)}
                            value={instructions}
                            required
                            />       
                        <button className="sell--offer-btn">{theLoader}</button>
                    </form>
                    <p>{error}</p>
                    <p>{contractBalance}</p>
                </div>

                <div className="w3-modal" id="openError">
                    <div className="offer--details-modal2 w3-modal-content w3-animate-bottom">
                        <i className="allErrors--icon fa-solid fa-exclamation"></i>
                        <p className="offer--details-modal2-t1">An Error Occurred</p>
                        <p align="center" style={{"color": "red"}}>{errorMsg}</p>
                        {/* <p className="offer--details-modal2-t2">Amount Too Low / Amount exceeds allowance / Order Pending / Error Creating Order.</p> */}
                        <p className="offer--details-modal2-t2">Checking your wallet might help. If transaction is pending in your wallet, wait till its completed then start again with the same values.</p>
                        <Link to="../profile" className="link"><button className="offer--details-modal2-btn1">Go to profile</button></Link>
                        <button onClick={closeError} className="offer--details-modal2-btn2">Close</button>
                    </div>
                </div>


                    <div className="w3-modal" id="approve-offer">
                        <div className="w3-modal-content selloffer--approve-offer w3-animate-top">
                            <p className=" selloffer--approve-offer-t1">Complete your Offer</p>
                            <i id="closeApprove-x" className="fa-regular fa-circle-xmark w3-right  selloffer--approve-offer-icon1" onClick={closeApprove}></i>
                            <p className=" selloffer--approve-offer-t1b">( Please do not close this modal until transaction is completed. )</p>
                            <div>
                                <div className="w3-left">
                                    <i className="fa-brands fa-ethereum  selloffer--approve-offer-icon2"></i>
                                    <span className="selloffer--approve-offer-t2">{account}</span>
                                    <p className="selloffer--approve-offer-t3">{waitTime + "" + "hours"}</p>
                                </div>
                                <div className="w3-right">
                                    <p className="selloffer--approve-offer-t4">NGN { (amount * 0.9 * 710).toLocaleString("en-US") }</p>
                                    <p className="selloffer--approve-offer-t5">Pay with {method}</p>
                                </div>
                            </div>
                            <hr className="selloffer--approve-offer-hr"/>

                            <div className="w3-row">
                                <div className="w3-col l2">
                                    <img
                                    src="images/progress1a.png"
                                    className="selloffer--approve-offer-imgs"
                                    id="progress1a"
                                    />
                                    <img
                                    src="images/progress2a.png"
                                    className="selloffer--approve-offer-imgs"
                                    id="progress2a"
                                    />
                                </div>
                                <div className="w3-col l10">
                                    <p className="selloffer--approve-offer-t6">Approve Transaction</p>
                                    <p className="selloffer--approve-offer-t7" id="progress1t">You’ll be asked to approve this transaction from your wallet, If there is an error, confirm the transaction is successful in your wallet and try again.</p>
                                    <button className="selloffer--approve-offer-btn" id="progress1btn">Waiting for approval...</button>
                                </div>
                            </div>

                            <div className="w3-row" style={{"marginTop": "24px"}}>
                                <div className="w3-col l2">
                                    <img
                                    src="images/progress1b.png"
                                    className="selloffer--approve-offer-imgs"
                                    id="progress1b"
                                    />
                                    <img
                                    src="images/progress2b.png"
                                    className="selloffer--approve-offer-imgs"
                                    id="progress2b"
                                    />
                                    <img
                                    src="images/progress2a.png"
                                    className="selloffer--approve-offer-imgs"
                                    id="progress2c"
                                    />
                                </div>
                                <div className="w3-col l10">
                                    <p className="selloffer--approve-offer-t6">Confirm Order</p>
                                        <div className="">
                                            <p className="selloffer--approve-offer-t7" id="progress2t">You’ll be asked to review and confirm this transaction from your wallet.</p>
                                            <button className="selloffer--approve-offer-btn" id="progress2btn">Waiting for Confirmation...</button>
                                        </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="w3-modal" id="selloffer--created">
                        <div className="selloffer--confirmation w3-modal-content w3-animate-top ">
                            <i className="selloffer--confirmation-icon2 fa-regular fa-circle-check"></i>
                            <p className="selloffer--confirmation-t1">Offer Created</p>
                            <Link to="../profile" className="link"><button className="selloffer--confirmation-btn1">Go to Profile</button></Link>
                            <button onClick={closeOfferCreated} className="selloffer--confirmation-btn2">Close</button>
                        </div>
                    </div>
                    

                    <div className="w3-modal" id="selloffer--confirmation">
                        <div className="selloffer--confirmation w3-modal-content w3-animate-top ">
                            <i className="selloffer--confirmation-icon1 fa-solid fa-triangle-exclamation"></i>
                            <p className="selloffer--confirmation-t1">Confirm Payment</p>
                            <p className="selloffer--confirmation-t2">Are you sure you have recieved payment? Once confirmed, it can't be undone.</p>
                            <button onClick={openConfirmSuccess} className="selloffer--confirmation-btn1">Confirm Payment</button>
                            {/* <button onClick={openCancel} className="selloffer--confirmation-btn2">Cancel Trade</button> */}
                        </div>
                    </div>

                    <div className="w3-modal" id="selloffer--confirmation-success">
                        <div className="selloffer--confirmation w3-modal-content w3-animate-top ">
                            <i className="selloffer--confirmation-icon2 fa-regular fa-circle-check"></i>
                            <p className="selloffer--confirmation-t1">Payment Succesful</p>
                            <button className="selloffer--confirmation-btn1">View Transaction History</button>
                            <Link to="../profile" className="link"><button onClick={closeConfirmSuccess} className="selloffer--confirmation-btn2">Back to Home</button></Link>
                        </div>
                    </div>
                    
                    <div className="w3-modal" id="selloffer--cancel">
                        <div className="selloffer--confirmation w3-modal-content w3-animate-top ">
                            <i className="selloffer--confirmation-icon1 fa-solid fa-triangle-exclamation"></i>
                            <p className="selloffer--confirmation-t1">Cancel Trade</p>
                            <p className="selloffer--confirmation-t2">Are you sure you want to cancel this trade? Once confirmed, it can't be undone.</p>
                            <button onClick={closeCancel} className="selloffer--confirmation-btn1">Cancel Trade</button>
                            <button onClick={closeCancel} className="selloffer--confirmation-btn2">Close</button>
                        </div>
                    </div>
                    


            <Home_Footer />
        </>
    )
}

export default CreateSellOffer