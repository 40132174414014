import React from "react";
import { Link } from "react-router-dom"

const closeAllErrors = () => {
    // document.getElementById("all-errors").style.display = "none"
    window.location.reload()
}

const AllErros = (props) => { 

    return (
        <>
                <div className="w3-modal" id="all-errors">
                    <div className="offer--details-modal2 w3-modal-content w3-animate-bottom">
                        <i className="allErrors--icon fa-solid fa-exclamation"></i>
                        <p className="offer--details-modal2-t1">An Error Occurred</p>
                        <p className="offer--details-modal2-t2">There was an error in this transaction. Please check your Wallet!</p>
                        <Link to="../profile" className="link"><button onClick={closeAllErrors} className="offer--details-modal2-btn1">Go to Profile</button></Link>
                        <button onClick={props.openedAllErrors} className="offer--details-modal2-btn2">Close</button>
                    </div>
                </div>
        </>
    )
}

export default AllErros