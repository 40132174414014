import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import BuyOrder_Pay_Confirm from "./BuyOrder_Pay_Confirm"
import { cryptoExchangeABI } from "../../abi/cryptoExchangeABI"
import { contractAddress } from "../../App";
import AllErros from "./BuyOrder_Error";
import { useProvider, useContract, useSigner } from 'wagmi'
// import Web3 from "web3";

// import { create as ipfsHttpClient } from "ipfs-http-client";


const baseURL = "https://p2pfi.xyz"


const Offer_Bank_Modal = (props) => {

    const [ theLoader, setTheLoader ] = useState("I have made payment")

    // const projectId = process.env.REACT_APP_PROJECT_ID;
    // const projectSecretKey = process.env.REACT_APP_PROJECT_KEY;
    // const authorization = "Basic " + btoa(projectId + ":" + projectSecretKey);

    // const ipfs = ipfsHttpClient({
    //     url: "https://ipfs.infura.io:5001/api/v0",
    //     headers: {
    //       authorization,
    //     },
    //   });

    const [ delayed, isDelayed ] = useState(false)

    const [ theTime, setTheTime ] = useState(props.orderDuration + ":" + 0 + ":" + 0)
    const startTimer = () => {
        var presentTime = theTime;
        var timeArray = presentTime.split(/[:]+/);
        var h = checkHour((timeArray[0]));
        var m = checkMinute((timeArray[1]))
        var s = checkSecond((timeArray[2] - 1));
        if(m==59 && h>=1){
            m=59;
            h=h-1;
        }
        if(s==59){m=m-1}

        if(h=="00" && m=="00" && s=="00"){
            isDelayed(true)
        return
        }

        // timed.innerHTML =
        // m + ":" + s;
        setTheTime(h + ":" + m + ":" + s)
    }
    setTimeout(() => {
        startTimer()
    }, 1000);

    const checkSecond = (sec) => {
        if (sec < 10 && sec >= 0) {sec = "0" + sec}; // add zero in front of numbers < 10
        if (sec < 0) {sec = "59"};
        return sec;
    }

    const checkMinute = (min) => {
        if (min < 10 && min >= 0) {
            const fmin = "0" + min
            min = fmin.slice(-2)
        }; // add zero in front of numbers < 10
        if (min < 0) {min = "59"};
        return min;
    }

    const checkHour = (h) => {
        if (h < 10 && h >= 0) {
            const fh = "0" + h
            h = fh.slice(-2)
        }; // add zero in front of numbers < 10
        if (h < 0) {h = "59"};
        return h;
    }

    // const initiateDispute = async () => {
    //     const disputeData = {
    //         "orderID": props.buyOrderID,
    //         "description": "Late Payment"
    //     }
    //     const makeURI = async () => {
    //         await ipfs.add(JSON.stringify(disputeData))
    //         .then((result) => {
    //             const thePath = result.path
    //             console.log(thePath)
    //             const path1 = thePath.toString()
    //             const path2 = Web3.utils.asciiToHex(path1)
    //             console.log(path2)

    //             try {
    //                 contract.initiateDispute(props.buyOrderID, path2)
    //                 .then(results => {
    //                     console.log(results)
    //                     alert("Dispute Created")
    //                     // openedBuyOrderConfirmed()
    //                     return;
    //                 })
    //                 console.log("Dispute Created")
    //             } catch(err) {
    //                 console.log(err);
    //                 openedAllErrors2()
    //             }
    //         }, (err) => {
    //             console.log(err)
    //         })

    //     }
    //     makeURI()
    //   }

    const provider = useProvider()
    const { data: signer, isError, isLoading } = useSigner()
    console.log(signer)


    const contract = useContract({
        address: contractAddress,
        abi: cryptoExchangeABI,
        signerOrProvider: signer,
      })

  const [ isUser2, setIsUser2 ] = useState([]);


    const [ openAllErros2, setOpenAllErrors2 ] = useState(false)

    const openedAllErrors2 = () => {
        setOpenAllErrors2(current => !current);
    }

    const [ openPayConfirm, setOpenPayConfirm ] = useState(false)
    const openedPayConfirm = () => {
        setOpenPayConfirm(current => !current)
    }

    async function payBuyOrder() {
        setTheLoader(
            <>
                <span className="loader"></span>
            </>
        )
        try {
            await contract.payBuyOrder(props.buyOrderID)
            .then(response => {
                console.log(response)
                openedPayConfirm()
                setTheLoader("I have made payment")
            });
        } catch(err) {
            console.log(err);
            openedAllErrors2()
            setTheLoader("I have made payment")
        }
      }







      const [ apiUsernameX, setApiUsernameX ] = useState("")
      const [ apiBankName1x, setApiBankName1x ] = useState("")
      const [ apiAccountNumber1x, setApiAccountNumber1x ] = useState("")
      const [ apiAccountName1x, setApiAccountName1x ] = useState("")

      useEffect(() => {

        const apiIsQuery2 = async () => {
          await axios.get(baseURL + "/api/users")
            .then(async (response) => {
              const newResponse = response.data.User;
              const hehehe = newResponse.filter((mapped) => {
                return (mapped.walletAddress).toLocaleLowerCase() === (props.sellerAddress).toLocaleLowerCase()
              })
              const halo = hehehe.map((data) => {
                setApiUsernameX(data.username)
                setApiBankName1x(data.bankName1)
                setApiAccountNumber1x(data.accountNumber1)
                setApiAccountName1x(data.accountName1)

                console.log(data.username, data.bankName1)
                return;
              })
            //   setIsUser2(hehehe);
            }, (error) => {
              console.log(error);
            });
        }
        apiIsQuery2()

    }, [props.sellerAddress]);














    const [ coinName, setCoinName ] = useState("")

    useEffect(() => {
        if(props.theCoin == "0xe11a86849d99f524cac3e7a0ec1241828e332c62") {
            setCoinName("USDC (POLYGON)")
        } else {
            setCoinName("Polygon (MATIC)")
        }
    }, [])










    return(
        <>
            <div className="w3-modal" id="bank-payment">
                <div className="offer--details-modal w3-modal-content w3-animate-top">
                    <div className="w3-row">
                        <div className="w3-left">
                            <i className="fa-regular fa-circle-user offer--details-modal-icon1"></i>
                            <span className="offer--details-modal-t1"><span style={{"fontWeight": "600"}}>Order ID:</span> {props.buyOrderID}</span>
                            <p className="offer--details-modal-t2"><span style={{"fontWeight": "600"}}>For:</span> {coinName}</p>
                        </div>
                        <div className="w3-right">
                            <p className="offer--details-modal-t3">NGN {(props.buyAmount * 710 * 1).toLocaleString("en-US")}</p>
                            <p className="offer--details-modal-t4"><span style={{"fontWeight": "600"}}>Pay</span> {apiUsernameX}</p>
                        </div>
                    </div>
                    <div style={{"position": "relative", "top": "-20px"}}>
                        <div className="w3-row">
                            <div>
                                <p className="w3-left offer--details-modal-t5">Account Number</p>
                                <p className="w3-right offer--details-modal-t6">{apiAccountNumber1x}</p>
                            </div>
                        </div>
                        <div className="w3-row">
                            <div>
                                <p className="w3-left offer--details-modal-t5">Bank Name</p>
                                <p className="w3-right offer--details-modal-t6">{apiBankName1x}</p>
                            </div>
                        </div>
                        <div className="w3-row">
                            <div>
                                <p className="w3-left offer--details-modal-t5">Account Name</p>
                                <p className="w3-right offer--details-modal-t6">{apiAccountName1x}</p>
                            </div>
                        </div>
                        <div className="w3-row">
                            <div>
                                <p className="w3-left offer--details-modal-t5">Time Remaining</p>
                                <p className="w3-right offer--details-modal-t6">{theTime}</p>
                            </div>
                        </div>
                    </div>
                    <button disabled={delayed} onClick={payBuyOrder} className="offer--details-modal-btn1">{theLoader}</button>
                </div>
            </div>

            {
                openPayConfirm && <BuyOrder_Pay_Confirm openedPayConfirm = {openedPayConfirm} closeBankModal = {props.openedBuyOrderPay}/>
            }
            {
                openAllErros2 && <AllErros
                                    errorID = {props.buyOrderID}
                                    openedAllErrors = {openedAllErrors2}
                                />
            }
        </>
    )
}

export default Offer_Bank_Modal