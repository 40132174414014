import React from "react";
import { Link } from "react-router-dom"
import Home_Header from "../components/Home_Header"
import Home_Footer from "../components/Home_Footer"
import { join } from "./Home"

function Coming_Soon(props) {

    const [ account, setAccount ] = useState()

    const headerToOthers1 = (addressData) => {
        setAccount(addressData)
    }

    const join = () => {
        document.getElementById("join").style.display = "block"
    }

    const close_join = () => {
        document.getElementById("join").style.display = "none"
        document.location.reload()
    }

    const hurray = () => {
        document.getElementById("join-wait--t3").style.display = "block"
    }

    const accordion = () => {
        var acc = document.getElementsByClassName("coming--soon-accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
            } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
            }
        });
        }
    }

    return(
        <>
            <Home_Header 
                account = {account}
                headerToOthers1 = {headerToOthers1}
                apiProfileImage = {props.apiProfileImage}
                apiUsername = {props.apiUsername}
            />
                <div className="coming--soon">
                    <div className="w3-row">
                        <div className="w3-col l6 s12 coming--soon-col1">
                            <p className="coming--soon-col1-t1">WE’RE STILL</p>
                            <p className="coming--soon-col1-t2">Filling up the spaces.</p>
                            <button onClick={join} className="coming--soon-col1-btn1">Be the first to know</button>
                            <Link to=".." className="link"><button className="coming--soon-col1-btn2">Go Home</button></Link>
                        </div>
                        <div className="w3-col l6 s12  coming--soon-col2 coming--soon-accordions">
                            <button onClick={accordion} className="coming--soon-accordion">Our Academy</button>
                            <p className="coming--soon-panel"><span className="coming--soon-panel-text">Our crypto community are fill of individuals with a shared interest in cryptocurrency investing. The P2PFi community provide an avenue where investors can share news, exchange ideas or opinions, or ask questions.</span></p>
                            <button onClick={accordion} className="coming--soon-accordion">Our Community</button>
                            <p className="coming--soon-panel"><span className="coming--soon-panel-text">Our crypto community are fill of individuals with a shared interest in cryptocurrency investing. The P2PFi community provide an avenue where investors can share news, exchange ideas or opinions, or ask questions.</span></p>
                            <button onClick={accordion} className="coming--soon-accordion">Our Charity</button>
                            <p className="coming--soon-panel"><span className="coming--soon-panel-text">Our crypto community are fill of individuals with a shared interest in cryptocurrency investing. The P2PFi community provide an avenue where investors can share news, exchange ideas or opinions, or ask questions.</span></p>
                        </div>
                    </div>

                    <form action="https://formsubmit.co/demoyou@yahoo.com" method="POST" id="join" className="w3-modal">
                    <div className="w3-modal-content w3-animate-zoom join-wait">
                        <i onClick={close_join} className="fa-solid fa-xmark join-wait--icon"></i>
                        <p className="join-wait--t1">Join Waitlist</p>
                        <p className="join-wait--t2">Be the first to know when we go live.</p>
                        <input
                        className="join-wait--input"
                        type="text"
                        placeholder="Name"
                        name="name"
                        />
                        <input
                        className="join-wait--input"
                        type="text"
                        placeholder="Email Address"
                        name="email"
                        />
                        <button type="submit" onClick={hurray} id="join-wait--btn" className="join-wait--btn">Join Waitlist</button>
                        <p className="join-wait--t3" id="join-wait--t3">Hurray 🎉 You’ve been added to the waitlist </p>
                    </div>
                </form>



                </div>
            <Home_Footer />
        </>
    )
}

export default Coming_Soon