import React from "react";
import { Link } from "react-router-dom"


function Home_Header() {

    return(
        <>
            <div className="home--header">
                <div className="w3-left home--header-l">
                    <Link to=".." className="link"><img src="/images/main-lg.png"
                    className="home--lg-1 w3-animate-left"
                    alt="P2PFi"
                    /></Link>
                </div>
                <div className="w3-right home--header-r w3-hide-small">
                    <Link to="../connect"><i className="fa-solid fa-wallet home--icon-1 w3-animate-top"></i></Link>
                </div>
            </div>
        </>
    )
}

export default Home_Header