import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Home_Header from "../../components/Home_Header";
import Settings_Sidebar from "../../components/Settings_Sidebar";
import axios from "axios";

import { useAccount } from "wagmi"

    export const open_s_p = () => {
        document.getElementById("s-p").style.display = "block"
        document.getElementById("s-n").style.display = "none"
        document.getElementById("s-a").style.display = "none"
        document.getElementById("s-b").style.display = "none"
        document.getElementById("scripted").focus();
    }

    export const open_s_n = () => {
        document.getElementById("s-p").style.display = "none"
        document.getElementById("s-n").style.display = "block"
        document.getElementById("s-a").style.display = "none"
        document.getElementById("s-b").style.display = "none"
        document.getElementById("scripted2").focus();
    }

    export const open_s_a = () => {
        document.getElementById("s-p").style.display = "none"
        document.getElementById("s-n").style.display = "none"
        document.getElementById("s-a").style.display = "block"
        document.getElementById("s-b").style.display = "none"
        document.getElementById("scripted3").focus();
    }

    export const open_s_b = () => {
        document.getElementById("s-p").style.display = "none"
        document.getElementById("s-n").style.display = "none"
        document.getElementById("s-a").style.display = "none"
        document.getElementById("s-b").style.display = "block"
        document.getElementById("scripted4").focus();
    }


    const accordion = () => {
        var acc = document.getElementsByClassName("settings--account-accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
            } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
            }
        });
        }
    }




function Settings(props) {

    const { isDisconnected } = useAccount()

    if (isDisconnected) {
        const urlAddress = window.location.pathname.split("/");
        window.location.href = urlAddress[0] + "/connect"
        console.log(urlAddress[1])
    }

    const [ theLoader, setTheLoader ] = useState("Save")
    const [ theLoader2, setTheLoader2 ] = useState("Add Bank")
    const [ account, setAccount ] = useState()

    const headerToOthers1 = (addressData) => {
        setAccount(addressData)
    }


    const userRef = useRef()

    const [ bannerColor, setBannerColor ] = useState(props.apiBannerImage);
    const [ profileColor, setProfileColor ] = useState(props.apiProfileImage);
    const [ username, setUsername ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ twitterUrl, setTwitterUrl ] = useState('');
    const [ bankName1, setBankName1 ] = useState('');
    const [ accountName1, setAccountName1 ] = useState('');
    const [ accountNumber1, setAccountNumber1 ] = useState('');

    // useEffect(() => {
    //     setTimeout(() => {
    //         // window.location.reload()
    //     }, 1000)
    // }, [])



    const changeBannerColor1 = () => {
        setBannerColor("#F7F8F9")
    }

    const changeBannerColor2 = () => {
        setBannerColor("green")
    }

    const changeBannerColor3 = () => {
        setBannerColor("blue")
    }

    const changeBannerColor4 = () => {
        setBannerColor("yellow")
    }

    const changeBannerColor5 = () => {
        setBannerColor("orange")
    }



    const changeProfileColor1 = () => {
        setProfileColor("#F7F8F9")
    }

    const changeProfileColor2 = () => {
        setProfileColor("green")
    }

    const changeProfileColor3 = () => {
        setProfileColor("blue")
    }

    const changeProfileColor4 = () => {
        setProfileColor("yellow")
    }

    const changeProfileColor5 = () => {
        setProfileColor("orange")
    }

    const API_URL = "https://p2pfi.xyz/api/users/"


    const handleSubmit = (e) => {
        setTheLoader(
            <>
                <span className="loader"></span>
            </>
        )
        e.preventDefault();
        const newApiQuery = async () => {
            await axios.post(API_URL + props.apiId, {
              username: username,
              email: email,
              twitterURL: twitterUrl,
              walletAddress:  account,
              profileImage: profileColor,
              bannerImage: bannerColor,
              bankName1: props.apiBankName1,
              accountName1: props.apiAccountName1,
              accountNumber1: props.apiAccountNumber1,
            })
          .then((response) => {
            console.log(response);
            setTheLoader("Save")
            setTimeout(() => {
                window.location.reload()
            }, 1000)
          }, (error) => {
            console.log(error);
            setTheLoader("Save")
          });
        } 
        newApiQuery()
    }

    const handleSubmit2 = (e) => {
        setTheLoader2(
            <>
                <span className="loader"></span>
            </>
        )
        e.preventDefault();
        console.log(bankName1, accountName1, accountNumber1)
        const newApiQuery = async () => {
            await axios.post(API_URL + props.apiId, {
                username: props.apiUsername,
                email: props.apiEmail,
                walletAddress: props.apiWalletAddress,
                profileImage: props.apiProfileImage,
                bannerImage: props.apiBannerImage,
                twitterURL: props.apiTwitterUrl,
                bankName1: bankName1,
                accountName1: accountName1,
                accountNumber1: accountNumber1,
            })
          .then((response) => {
            s_b_add_confirm()
            console.log(response);
            setTheLoader2("Add Bank")
            setBankName1("")
            setAccountName1("")
            setAccountNumber1("")
          }, (error) => {
            console.log(error);
            setTheLoader2("Add Bank")
          });
        } 
        newApiQuery()
    }

    const handleDelete = (e) => {
        // e.preventDefault();
        const newApiQuery = async () => {
            await axios.post(API_URL + props.apiId, {
                username: props.apiUsername,
                email: props.apiEmail,
                walletAddress: props.apiWalletAddress,
                profileImage: props.apiProfileImage,
                bannerImage: props.apiBannerImage,
                bankName1: "",
                accountName1: "",
                accountNumber1: "",
                twitterURL: props.apiTwitterUrl,
            })
          .then((response) => {
            console.log(response);
            profile_update_confirm()
          }, (error) => {
            console.log(error);
          });
        } 
        newApiQuery()
    }

    const s_b_delete = () => {
        document.getElementById("s-b-delete").style.display = "block"
    }

    const s_b_delete_confirm = () => {
        handleDelete()
        document.getElementById("s-b-delete-confirm").style.display = "block"
        document.getElementById("s-b-delete").style.display = "none"
    }

    const close_s_b_delete = () => {
        document.getElementById("s-b-delete").style.display = "none"
    }

    const close_s_b_delete_confirm = () => {
        document.getElementById("s-b-delete-confirm").style.display = "none"
    }

    const s_b_add_account = () => {
        document.getElementById("s-b-add-account").style.display = "block"
    }

    const close_s_b_add_account = () => {
        document.getElementById("s-b-add-account").style.display = "none"
    }

    const s_b_add = () => {
        document.getElementById("s-b-add").style.display = "block"
        document.getElementById("s-b-add-account").style.display = "none"
        s_b_add_confirm()
    }

    const s_b_add_confirm = () => {
        document.getElementById("s-b-add-confirm").style.display = "block"
        document.getElementById("s-b-add").style.display = "none"
    }

    const close_s_b_add = () => {
        document.getElementById("s-b-add").style.display = "none"
    }

    const close_s_b_add_confirm = () => {
        document.getElementById("s-b-add-confirm").style.display = "none"
        close_s_b_add_account()
        window.location.reload()
    }




    const profile_update = () => {
        document.getElementById("profile--update").style.display = "block"
        // document.getElementById("s-b-add-account").style.display = "none"
    }

    const profile_update_confirm = () => {
        document.getElementById("profile--update-confirm").style.display = "block"
        document.getElementById("profile--update").style.display = "none"
    }

    const close_profile_update = () => {
        document.getElementById("profile--update").style.display = "none"
    }

    const close_profile_update_confirm = () => {
        document.getElementById("profile--update-confirm").style.display = "none"
        window.location.reload()
    }

    const [ bankImage, setBankImage ] = useState("")

    useEffect(() => {
        const bankImageSrc = () => {
            if(props.apiBankName1 === "Guaranteed Trust Bank") {
                setBankImage("images/bank-images/gtbank.png")
            } else if(props.apiBankName1 === "Wema Bank") {
                setBankImage("images/bank-images/wema.png")
            } else if(props.apiBankName1 === "FCMB") {
                setBankImage("images/bank-images/fcmb.png")
            } else if(props.apiBankName1 ==="Heritage Bank") {
                setBankImage("images/bank-images/heritagebank.png")
            } else if(props.apiBankName1 === "PalmPay") {
                setBankImage("images/bank-images/palmpay.png")
            } else if(props.apiBankName1 === "Kuda Microfinance Bank") {
                setBankImage("images/bank-images/kuda.png")
            }
        }
        bankImageSrc()
    }, [])

    const hardRefresh = () => {
        window.location.reload()
      }

    return(
        <>
            <Home_Header 
                account = {account}
                headerToOthers1 = {headerToOthers1}
                apiProfileImage = {props.apiProfileImage}
                apiUsername = {props.apiUsername}
            />
            <Settings_Sidebar />
            <div className="settings">
                
                <form onSubmit={handleSubmit} className="settings--profile" id="s-p">
                    <div className="w3-row">
                        <div className="w3-col l6 settings--profile-col1">
                            <div className="settings--profile-div1">
                            <p className="settings--profile-t1">Profile {!(props.apiUsername) && <button style={{"border": "2px solid #00000030", "background": "#f7f8f9", "fontSize": "12px", "fontWeight": "400", "padding": "4px 12px", "borderRadius": "4px", "position": "relative", "top": "-4px", "left": "12px"}} onClick={() => hardRefresh()}>Refresh</button>}</p>
                            {/* <button className="settings--profile-btn1 w3-right">Preview</button> */}
                            </div>
                            <p className="settings--profile-t2">Username</p>
                            <input
                                className="settings--profile-input"
                                type="text"
                                placeholder={props.apiUsername}
                                autoComplete="off"
                                onChange={(e) => setUsername(e.target.value)}
                                name = "username"
                                value={username}
                                ref={userRef}
                                required
                            />
                            <p className="settings--profile-t2">Email Address</p>
                            <input
                                className="settings--profile-input"
                                type="email"
                                placeholder={props.apiEmail}
                                autoComplete="off"
                                onChange={(e) => setEmail(e.target.value)}
                                name = "email"
                                value={email}
                                ref={userRef}
                                required
                            />
                            <p className="settings--profile-t2">Twitter Url (Do not add http:// or https://)</p>
                            <input
                                className="settings--profile-input"
                                type="text"
                                placeholder={props.apiTwitterUrl}
                                autoComplete="off"
                                onChange={(e) => setTwitterUrl(e.target.value)}
                                name = "twitterUrl"
                                value={twitterUrl}
                                ref={userRef}
                                required
                            />

                            {/* <div className="settings--profile-div2">
                                <i className="fa-brands fa-twitter settings--profile-icon"></i>
                                <span className="settings--profile-t2">Twitter</span>
                                <button className="settings--profile-btn2 w3-right">Connect</button>
                            </div> */}
                            
                            <p className="settings--profile-t2">Wallet Address</p>
                            <input
                            className="settings--profile-input"
                            type="text"
                            placeholder={ account}
                            />
                            <button className="s-b-add-account-btn">{theLoader}</button>
                            {/* Original Button style is "settings--profile-btn3" */}
                        </div>

                        <div className="w3-col l6 settings--profile-col2">


                            <img src=""
                            className="settings--profile-img"
                            id="settings--profile-img"
                            style={{"backgroundColor": `${profileColor}`}}
                            alt=""
                            />
                            <div className="settings--profile-banner-list">
                                <span onClick={changeProfileColor1} className="settings--profile-banner-list-color1" id="profile--banner-list-color1"></span>
                                <span onClick={changeProfileColor2} className="settings--profile-banner-list-color2" id="profile--banner-list-color2"></span>
                                <span onClick={changeProfileColor3} className="settings--profile-banner-list-color3" id="profile--banner-list-color3"></span>
                                <span onClick={changeProfileColor4} className="settings--profile-banner-list-color4" id="profile--banner-list-color4"></span>
                                <span onClick={changeProfileColor5} className="settings--profile-banner-list-color5" id="profile--banner-list-color5"></span>
                            </div>



                            <p className="settings--profile-t3">Profile Banner</p>
                            <p className="settings--profile-t3-2">Choose Profile Banner Color</p>
                            <div id="banner-color" className="settings--profile-banner" style={{"backgroundColor": `${bannerColor}`}}></div>
                            <div className="settings--profile-banner-list" style={{"zIndex": "1000"}}>
                                <span onClick={changeBannerColor1} className="settings--profile-banner-list-color1" id="profile--banner-list-color1"></span>
                                <span onClick={changeBannerColor2} className="settings--profile-banner-list-color2" id="profile--banner-list-color2"></span>
                                <span onClick={changeBannerColor3} className="settings--profile-banner-list-color3" id="profile--banner-list-color3"></span>
                                <span onClick={changeBannerColor4} className="settings--profile-banner-list-color4" id="profile--banner-list-color4"></span>
                                <span onClick={changeBannerColor5} className="settings--profile-banner-list-color5" id="profile--banner-list-color5"></span>
                            </div>
                        </div>
                    </div>
                </form>

                <div id="s-n" className="settings--notification">
                    <p className="settings--notification-t1">Notification Settings(Coming Soon)</p>
                    {/* <p className="settings--notification-t2">Select which notifications you would like to receive for <span className="settings--notification-t2-t">{ account}</span></p>
                    <div className="settings--notification-tions">
                        <div className="w3-row">
                            <div className="w3-col l1">
                                <input type="checkbox" className="settings--notification-checkbox"/>
                            </div>
                            <div className="w3-col l11">
                                <p className="settings--notification-t3">Coin Sold</p>
                                <p className="settings--notification-t4">When someone purchased one of your coins</p>
                            </div>
                        </div>
                        <hr className="settings--notification-hr"/>
                        <div className="w3-row">
                            <div className="w3-col l1">
                                <input type="checkbox" className="settings--notification-checkbox-2"/>
                            </div>
                            <div className="w3-col l11">
                                <p className="settings--notification-t3-3">Buy Request</p>
                                <p className="settings--notification-t4">When someone bids on one of your coins</p>
                            </div>
                        </div>
                        <hr className="settings--notification-hr"/>
                        <div className="w3-row">
                            <div className="w3-col l1">
                                <input type="checkbox" className="settings--notification-checkbox-2"/>
                            </div>
                            <div className="w3-col l11">
                                <p className="settings--notification-t3-3">Order Expiration</p>
                                <p className="settings--notification-t4">When a timed auction you created ends</p>
                            </div>
                        </div>
                        <hr className="settings--notification-hr"/>
                        <div className="w3-row">
                            <div className="w3-col l1">
                                <input type="checkbox" className="settings--notification-checkbox-2"/>
                            </div>
                            <div className="w3-col l11">
                                <p className="settings--notification-t3-3">Owned Coin Update</p>
                                <p className="settings--notification-t4">When a significant update occurs for one of the coins you have</p>
                            </div>
                        </div>
                        <hr className="settings--notification-hr"/>
                        <div className="w3-row">
                            <div className="w3-col l1">
                                <input type="checkbox" className="settings--notification-checkbox-2"/>
                            </div>
                            <div className="w3-col l11">
                                <p className="settings--notification-t3-3">Successful Purchase</p>
                                <p className="settings--notification-t4">When you successfully buy your coin</p>
                            </div>
                        </div>
                        <hr className="settings--notification-hr"/>
                        <div className="w3-row">
                            <div className="w3-col l1">
                                <input type="checkbox" className="settings--notification-checkbox-2"/>
                            </div>
                            <div className="w3-col l11">
                                <p className="settings--notification-t3-3">P2PFi Newsletter</p>
                                <p className="settings--notification-t4">Occasional updates from the P2PFi team</p>
                            </div>
                        </div>
                    </div>
                    <button className="settings--notification-btn">Save</button> */}
                </div>


                <div id="s-a" className="settings--account">
                    <p className="settings--account-t1">Account Support (Coming Soon)</p>
                    {/* <p className="settings--account-t2">If you need help related to your account, we can help you.</p>

                    <div className="settings--account-accordions">
                        <button onClick={accordion} className="settings--account-accordion">Help with a compromised account</button>
                        <div className="settings--account-panel">
                            <p><span className="settings--account-panel-text">If you believe your account has been compromised, let us know and we can lock your account. This will disable items in your wallet from being bought, sold, or transferred using P2PFi. Learn more.</span><br/>
                                <select name="cars" className="settings--account-select">
                                    <option value="" className="settings--account-option">Describe your issue</option>
                                    <option value="Hi">I feel my account has been compromised</option>
                                </select>
                                <br/>
                            <button className="settings--account-btn">Lock Account</button>
                            </p><br/>
                        </div>
                    </div> */}
                </div>

                <div id="s-b" className="settings--bank">
                    <div className="settings--bank-div">
                        <div className="settings--bank-header">
                            <div className="w3-left">
                                <img src=""
                                className="settings--bank-img1"
                                alt=""
                                style={{"background": `${profileColor}`}}
                                />
                                <span className="settings--bank-t1">{props.apiUsername}</span>
                            </div>
                            <p className="settings--bank-t2 w3-right">{account}</p>
                        </div>

                        <div className="settings--bank-body">
                            <div className="">
                                <img 
                                src={bankImage}
                                className="settings--bank-img2"
                                id="bankImageSrc"
                                />
                                <span>
                                    <span className="settings--bank-t3">{props.apiBankName1}</span><br/>
                                    <span className="settings--bank-t4">{props.apiAccountNumber1} | </span>
                                    <span className="settings--bank-t4">{props.apiAccountName1}</span>
                                </span>
                                <i onClick={s_b_delete} className="fa fa-trash w3-right settings--bank-icon1"></i>
                            </div>
                        </div>
                        <i onClick={s_b_add_account} className="fa fa-square-plus settings--bank-icon2 w3-right"></i>
                    </div>
                </div>



                <div id="s-b-delete" className="w3-modal">
                    <div className="w3-modal-content s-b-delete w3-animate-top">
                        <i className="fa fa-triangle-exclamation s-b-delete-icon1"></i>
                        <p className="s-b-delete-t1">Are you Sure?</p>
                        <button className="s-b-delete-btn1" onClick={s_b_delete_confirm}>Yes I'm sure</button>
                        <p className="s-b-delete-btn2" onClick={close_s_b_delete}>No, Cancel</p>
                    </div>
                </div>

                <div id="s-b-delete-confirm" className="w3-modal">
                    <div className="w3-modal-content s-b-delete-confirm w3-animate-bottom">
                        <i className="fa-regular fa-circle-check s-b-delete-confirm-icon2"></i>
                        <p className="s-b-delete-confirm-t1">Delete Successful</p>
                        <Link to="/profile" className="link"><button className="s-b-delete-confirm-btn1">View Profile</button></Link>
                        <p className="s-b-delete-confirm-btn2" onClick={close_s_b_delete_confirm}>Close</p>
                    </div>
                </div>


                <div id="s-b-add-account" className="w3-modal">
                    <div className="w3-modal-content s-b-add-account w3-animate-zoom">
                        <p className="s-b-add-account-t1">Add Bank Details</p>
                        <p className="s-b-add-account-t2">Fill in your preferred bank account details</p>
                        <form onSubmit={handleSubmit2}>
                            <select required value={bankName1} defaultValue="Select Token" onChange={(e) => setBankName1(e.target.value)} className="s-b-add-account-input">
                                <option value="null">Bank Name</option>
                                <option value="Guaranteed Trust Bank">Guaranteed Trust Bank</option>
                                <option value="Wema Bank">Wema Bank</option>
                                {/* <option value="Abeg">Abeg</option> */}
                                <option value="FCMB">FCMB</option>
                                <option value="Heritage Bank">Heritage Bank</option>
                                <option value="PalmPay">PalmPay</option>
                                <option value="Kuda Microfinance Bank">Kuda Microfinance Bank</option>
                            </select>
                            <input
                            className="s-b-add-account-input"
                            placeholder="Account Number"
                            autoComplete="off"
                            onChange={(e) => setAccountNumber1(e.target.value)}
                            name = "accountNumber1"
                            value={accountNumber1}
                            ref={userRef}
                            required
                            />
                            <input
                            className="s-b-add-account-input"
                            placeholder="Account Name"
                            autoComplete="off"
                            onChange={(e) => setAccountName1(e.target.value)}
                            name = "accountName1"
                            value={accountName1}
                            ref={userRef}
                            required
                            />
                            {/* <p className="s-b-add-account-input2">Account Name</p> */}
                            <button className="s-b-add-account-btn">{theLoader2}</button>
                        </form>
                    </div>
                </div>



                <div id="s-b-add" className="w3-modal">
                    <div className="w3-modal-content s-b-delete w3-animate-top">
                        <i className="fa fa-triangle-exclamation s-b-delete-icon1"></i>
                        <p className="s-b-delete-t1">Confirmation</p>
                        <p>Are you sure the bank details are correct? Once confirmed, it can't be undone.</p>
                        <button className="s-b-delete-btn1" onClick={s_b_add_confirm}>Yes I'm sure</button>
                        <p className="s-b-delete-btn2" onClick={close_s_b_add}>Cancel</p>
                    </div>
                </div>

                <div id="s-b-add-confirm" className="w3-modal">
                    <div className="w3-modal-content s-b-delete-confirm w3-animate-bottom">
                        <i className="fa-regular fa-circle-check s-b-delete-confirm-icon2"></i>
                        <p className="s-b-delete-confirm-t1">Successful</p>
                        <Link to="/profile" className="link"><button className="s-b-delete-confirm-btn1">View Profile</button></Link>
                        <p className="s-b-delete-confirm-btn2" onClick={close_s_b_add_confirm}>Close</p>
                    </div>
                </div>


                <div id="profile--update" className="w3-modal">
                    <div className="w3-modal-content s-b-delete w3-animate-top">
                        <i className="fa fa-triangle-exclamation s-b-delete-icon1"></i>
                        <p className="s-b-delete-t1">Confirmation</p>
                        <p>Are you sure the details are correct? Once confirmed, it can't be undone.</p>
                        <button className="s-b-delete-btn1" onClick={profile_update_confirm}>Yes I'm sure</button>
                        <p className="s-b-delete-btn2" onClick={close_profile_update}>Cancel</p>
                    </div>
                </div>

                <div id="profile--update-confirm" className="w3-modal">
                    <div className="w3-modal-content s-b-delete-confirm w3-animate-bottom">
                        <i className="fa-regular fa-circle-check s-b-delete-confirm-icon2"></i>
                        <p className="s-b-delete-confirm-t1">Successful</p>
                        <Link to="/profile" className="link"><button className="s-b-delete-confirm-btn1">View Profile</button></Link>
                        <p className="s-b-delete-confirm-btn2" onClick={close_profile_update_confirm}>Close</p>
                    </div>
                </div>
                

            </div>
        </>
    )
}

export default Settings