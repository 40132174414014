import React, { useState } from "react";
import { Link } from "react-router-dom"
import Home_Header from "../components/Home_Header";
import Home_Footer from "../components/Home_Footer";

function RoadMap(props) {

    const [ account, setAccount ] = useState()

    const headerToOthers1 = (addressData) => {
        setAccount(addressData)
    }

    const accordion = () => {
        var acc = document.getElementsByClassName("roadmap--accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
            } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
            }
        });
        }
    }


    return(
        <>
            <Home_Header 
                account = {account}
                balance = {props.balance}
                balance2 = {props.balance2}
                headerToOthers1 = {headerToOthers1}
                apiProfileImage = {props.apiProfileImage}
                apiUsername = {props.apiUsername}
            />
                <div className="roadmap">
                    <div className="w3-row">
                        <div className="w3-col l6 m6 s12 roadmap--col1">
                            <p className="roadmap--t1 w3-animate-left">P2PFi ROADMAP</p>
                            <p className="roadmap--t2 w3-animate-left">Our mission is to make cryptocurrency trading private as it should be.</p>
                            <button className="roadmap--btn1 w3-animate-left">Download Whitpaper</button>
                            <Link to=".." className="link"><button className="roadmap--btn2 w3-animate-bottom">Go Home</button></Link>
                        </div>
                        <div className="w3-col l6 m6 s12 roadmap--col2  w3-animate-right">
                            <div className="roadmap--accordions">
                                <button onClick={accordion} className="roadmap--accordion">Product Story</button>
                                <div className="roadmap--panel">
                                    <span className="roadmap--panel-text">
                                    James wants to sell crypto for his national currency (say, Nigerian naira). When James creates a new sell order, he defines the amount of crypto to sell, the price and the minimum amount he Is willing to trade. The other data included in an offer, like the maximum wait time for the trade, the acceptable payment method, will be derived from the account settings.
                                    <br/><br/>
                                    Paul who is looking to buy cryptocurrency using his National currency (say, Nigerian Naira), visits our exchange and finds James sell order amongst the order listings. He defines the amount of crypto he wants to buy, and makes payment in his local currency. Once payment is confirmed by James (the seller), the P2PFi contract releases coin to Paul.
                                    <br/><br/>
                                    That’s the end of the process. No one gets all their funds frozen, no unnecessary KYC, simply connect your wallet and authorize transactions, and you’re good to go.
                                    </span>
                                </div>

                                <button onClick={accordion} className="roadmap--accordion">Phase 1 - Conception</button>
                                <div className="roadmap--panel">
                                    <span className="roadmap--panel-text">
                                        <ul>
                                            <li>Founded P2PFI and complete all legal filings</li>
                                            <li>Brand design and Design system</li>
                                            <li>Built the decentralized peer to peer infrastructure</li>
                                            <li>Published whitepaper, pitchdeck and roadmap</li>
                                            <li>Initial Release for web (on polygon)</li>
                                        </ul>
                                    </span>
                                </div>

                                <button onClick={accordion} className="roadmap--accordion">Phase 2 - Baby steps</button>
                                <div className="roadmap--panel">
                                    <span className="roadmap--panel-text">
                                        <ul>
                                            <li>Community development (& feedback updates)</li>
                                            <li>Business and Revenue Model Strategy</li>
                                            <li>Secure Nevis and/or Estonia crypto license.</li>
                                            <li>Build solid Beta team for P2PFI (from engineering, to design to compliance to support)</li>
                                            <li>Pre-seed fund raising round (Funding goal: $150k-$800k)</li>
                                        </ul>
                                    </span>
                                </div>

                                <button onClick={accordion} className="roadmap--accordion">Phase 3 - Traction-driven Growth</button>
                                <div className="roadmap--panel">
                                    <span className="roadmap--panel-text">
                                        <ul>
                                            <li>Introduce support to 20+ EVM chains</li>
                                            <li>Introduce support to 40+ EVM coins (https://cryptoslate.com/cryptos/evm/)</li>
                                            <li>Introduce support for Ghana, Kenya, Zimbabwe, and Botswana</li>
                                            <li>Release native mobile app</li>
                                            <li>Introduce full service crypto OTC trading for countries not officially supported, and for individuals with extremely large sell/buy orders</li>
                                        </ul>
                                    </span>
                                </div>


                                <button onClick={accordion} className="roadmap--accordion">Phase 4 - Expansion</button>
                                <div className="roadmap--panel">
                                    <span className="roadmap--panel-text">
                                        <ul>
                                            <li>Secure crypto licenses in more countries and introduce more national currencies</li>
                                            <li>Expand support and community management to native languages of every national currencies we introduce (P2PFi Angels Program)</li>
                                            <li>Introduce P2PFi coin, P2PFi stable coins (for national currencies) and privacy blockchain</li>
                                            <li>Introduce P2PFi liquidity investment pool and yield staking farm.</li>
                                        </ul>
                                    </span>
                                </div>


                                <button onClick={accordion} className="roadmap--accordion">Phase 5 - Acquisition and Innovation</button>
                                <div className="roadmap--panel">
                                    <span className="roadmap--panel-text">
                                        <ul>
                                            <li>A decentralized green smart city on 200 acres to accommodate 40,000 residents, 750+ businesses and green open space.</li>
                                            <li>A decentralized wallet service to encrypt crypto transfers on EVM chains via proxy.</li>
                                            <li>A decentralized web casino & gaming service for people who want you gamble but desires to stay anonymous. (Taxes charged, paid by P2PFi to respective government entities)</li>
                                        </ul>
                                    </span>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="roadmap--section">
                        <p className="roadmap--section-t1">Community Feedback</p>
                        <p className="roadmap--section-t2">If you’re fascinated by what we’re trying to do at P2PFi and you want to suggest features, and activities we can introduce to our ecosystem, send a mail to hello@p2pfi.co with the subject: “Roadmap Update”. We would be happy to read your mail.</p>
                        <a href="mailto: hello@p2pfi.co" className="link"><button className="roadmap--section-btn">Send us a Mail</button></a>
                    </div>

                </div>
            <Home_Footer />
        </>
    )
}

export default RoadMap