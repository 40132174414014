import React from "react";
import { Link } from "react-router-dom"

const BuyOrder_Made = (props) => { 

    // const closeBuyMade = () => {
    //     window.location.reload()
    //     // document.getElementById("buyorder-made").style.display = "none"
    // }

    return (
        <>
                <div className="w3-modal" id="buyorder-made">
                    <div className="offer--details-modal2 w3-modal-content w3-animate-bottom">
                        <i className="offer--details-modal2-icon1 fa-solid fa-thumbs-up"></i>
                        <p className="offer--details-modal2-t1">Buy Order Created</p>
                        <p className="offer--details-modal2-t2">Please go to your profile (Buy Orders) to check if the seller has Accepted your order</p>
                        <Link to="../profile" className="link"><button className="offer--details-modal2-btn1">Go to Profile</button></Link>
                        <button onClick={props.openedBuyOrderMade} className="offer--details-modal2-btn2">Close</button>
                    </div>
                </div>
        </>
    )
}

export default BuyOrder_Made