import React, { useEffect, useState } from "react";
import Home_Header from "../../components/Home_Header";
import Home_Footer from "../../components/Home_Footer";
import OfferDetails from "./OfferDetails";
import axios from "axios"

import { useAccount } from "wagmi"

function BuyOffer(props) {

    const { isDisconnected } = useAccount()

    if (isDisconnected) {
        const urlAddress = window.location.pathname.split("/");
        window.location.href = urlAddress[0] + "/connect"
        console.log(urlAddress[1])
    }
    
    const [ sellOffers , setSellOffers ] = useState([])

    const [ OffersDetails, setOffersDetails] = useState(null);
 
    const openoffersDetails = () => {
        setOffersDetails(current => !current)
    }

    const [ account, setAccount ] = useState()

    const headerToOthers1 = (addressData) => {
        setAccount(addressData)
    }

    useEffect(() => {
        const renderOffers = async () => {
            try {
                const query = await axios.post(
                    "https://api.thegraph.com/subgraphs/name/staa99/p2pfi-mumbai",
                    {
                        query: `{
                            sellOrders {
                                id
                                token {
                                    id
                                }
                                seller {
                                    id
                                }
                                preDisputeDuration
                                totalAmount
                                minimumBuyAmount
                                filledAmount
                                dateCreated
                                status
                                metadataURI
                              }
                        }`,
                    }
                );
                

                setSellOffers(query.data.data.sellOrders);               
                // console.log(sellOffers)
    
            } catch(err) {
                console.log(err)
            }
        }
        renderOffers()
    })


    const filteredOrders = sellOffers.filter((data) => {
        return data.status === "Initialized"
    })

    const filteredOrders2 = filteredOrders.filter((data) => {
        return data.seller.id !== account
    })


    const viewOffers = filteredOrders2.map((data) => {

        let newTotalAmount
        if (data.filledAmount == 0) {
            newTotalAmount = data.totalAmount
        } else if(data.filledAmount != 0) {
            newTotalAmount = data.totalAmount - data.filledAmount
        }

        return (
            <div key={data.id}>
                <div className="buy--offer-list" id="buy--offer-list">
                    <div className="buy--offer-div" id="buyOfferList">
                        <div className="w3-left buy--offer-div-up">
                            <i className="buy--offer-icon fa-regular fa-circle-user"></i>
                            <span className="buy--offer-tt1">{data.seller.id}</span><br/><span className="buy--offer-tt2">Order ID: {data.id}</span>
                        </div>
                        <div className="w3-right buy--offer-div-up">
                            <span  className="buy--offer-ttt1">{newTotalAmount} ETH</span><br/><span className="buy--offer-ttt2">Lowest Purchase Amount: {data.minimumBuyAmount} ETH</span>
                        </div>
                        <hr className=""/>
                        <div className="w3-left">
                            <button className="buy--offer-div-btn1">Bank TRF</button>
                        </div>
                        <div className="w3-right">
                            <button onClick={() => setOffersDetails(OffersDetails => OffersDetails === data.id ? null : data.id)} 
                        className="buy--offer-div-btn2">View Offer</button>
                        </div>
                    </div>
                </div>

                {
                    OffersDetails === data.id && <OfferDetails
                                        key = {data.id}
                                        orderID = {data.id}
                                        sellerID = {data.seller.id}
                                        lowestPurchaseAmount = {data.minimumBuyAmount}
                                        totalPurchaseAmount = {data.totalAmount}
                                        // paymentOption = {data.id}
                                        metadataURI = {data.metadataURI}
                                        account = {account}
                                        balance = {props.balance}
                                        newToToken = {data.token.id}
                                    />
                }

                    
            </div>
        )
    })


    


    return(
        <>  
            <Home_Header 
                account = {account}
                balance = {props.balance}
                balance2 = {props.balance2}
                headerToOthers1 = {headerToOthers1}
                apiProfileImage = {props.apiProfileImage}
                apiUsername = {props.apiUsername}
            />
                <div className="buy--offer-header" align="center">
                    <span className="buy--offer-header-head">Buy :</span>
                    <select className="buy--offer-header-list">
                        {/* <option value="">Select Coin</option> */}
                        {/* <option value="POLYGON">Polygon (Matic)</option> */}
                        <option value="USDC">USDC</option>
                    </select>
                    <span className="buy--offer-header-head">Payment method :</span>
                    <select className="buy--offer-header-list">
                        {/* <option value="">Select Method</option> */}
                        <option value="Bank Transfer">Bank Transfer</option>
                        {/* <option value="Abeg">Abeg</option> */}
                    </select>
                    <span className="buy--offer-header-head">Display value :</span>
                    <select className="buy--offer-header-list">
                        {/* <option value="">Select Display Value</option> */}
                        <option value="USDC">USDC</option>
                        {/* <option value="MATIC">MATIC</option> */}
                    </select>
                    <button className="buy--offer-header-btn">Find Offer</button>
                </div>

            <div className="buy--offer">
                <p className="buy--offer-t1">All Offers</p>
                    <select className="buy--offer-h-r w3-right">
                    <option value="">All Offers</option>
                        {/* <option value="Newest">Newest</option> */}
                        {/* <option value="Oldest">Oldest</option> */}
                    </select>

                    {viewOffers}
                
            </div>

            <Home_Footer />
        </>
    )
}

export default BuyOffer