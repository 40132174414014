import React, { useState } from "react";
import Home_Header from "../../components/Home_Header"
import Home_Footer from "../../components/Home_Footer"
import { Link } from "react-router-dom"

function Terms(props) {
    const [ account, setAccount ] = useState()

    const headerToOthers1 = (addressData) => {
        setAccount(addressData)
    }

    return(
        <> 
            <Home_Header 
                account = {account}
                balance = {props.balance}
                balance2 = {props.balance2}
                headerToOthers1 = {headerToOthers1}
                apiProfileImage = {props.apiProfileImage}
                apiUsername = {props.apiUsername}
            />
                <div className="legals">
                    <div className="w3-row">
                        <div className="w3-col l3 legals--col1">
                            <p className="legals--t1">LEGAL</p>
                            <div className="legals--sidebar">
                            <Link to="../privacy" className="link"><p className="legals--sidebar-text">Privacy Policy</p></Link>
                            <Link to="../terms" className="link"><p className="legals--sidebar-text legals--sidebar-text-active">Terms of Use</p></Link>
                            <Link to="../risk-warning" className="link"><p className="legals--sidebar-text">Risk Warning</p></Link>
                            </div>
                        </div>
                        <div className="w3-col l9 legals--col2">
                            <p className="legals--t2 w3-animate-top">Terms of Use</p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>ASSENT OF TERMS OF USE</p>
                            <p className="legals--t3 w3-animate-left">Accessing this Site, and onward registration to use our (“Our” “We”) service/product, you ("You") are consenting to accept and comply with the terms and conditions of use stated herein ("Terms of Use"). You are enjoined to carefully read the Terms of Use in its entirety prior using this web site ("Site", “App”) or any of the services of this Site. As referred herein, "P2PFi" refers to the Company, including without limitation thereby, its owners, directors, investors, employees, affiliates or other related parties. Subject to context, "P2PFi" may also refer to the products, services, site, content or other resources provided by “P2PFi”. The Service provided by “P2PFi” among others allows Users/Traders ("Traders"), to buy and sell the Digital Asset/Currency known as "Crypto currency”. 
                            <br/><br/>
                            Our Service equally allows all registered users/traders of the Service ("Traders") to:
                            <br/>
                            <ul>
                                <li>Digital Exchange: You or other users/traders can transfer Crypto outside the P2PFi site/app.
                                </li>
                                <li>Use Crypto for payment of goods and services.
                                </li>
                            </ul>
                            Subject to the policy of your country of residence, you may be restricted to use all the functions of the site/app. We are not responsible for strict adherence of such; it is your responsibility to adhere to the policy/rules of your country of residence and/or the country in which you access this Site/App and Services.
                            <br/><br/>
                            Consenting to these Terms of Use, “P2PFi” grant you a personal, non-exclusive, non-transferable, non-alienable right to register and use the Site/App products and service. 
                            <br/><br/>
                            <strong>IF YOU DO NOT ACCEPT THE TERMS OF USE AND CONDITIONS HEREIN, DO NOT ACCESS THIS SITE AND DO NOT USE THIS SERVICE.</strong>
                            <br/><br/>
                            Mere creating an account to use the Service ("Account"), You expressly represent and warrant: 
                            <ul>
                                <li>You have accepted these Terms of Use; and  </li>
                                <li>You are at least 18 years of age and have the full capacity to accept these Terms of Use.</li>
                            </ul>
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>LIMITED RIGHT OF USE</p>
                            <p className="legals--t3 w3-animate-left">
                            <ul>
                                <li>Save otherwise specified, all resources on this Site are the property of “P2PFi” and are protected by copyright, trademark and other applicable laws. You may view, print and/or download a copy of the resources from this Site on any single computer solely for your personal consumption, informational, non-commercial use, provided you keep intact all copyright and other proprietary notices. 
                                </li>
                                <li>The trademarks, service marks and logos of “P2PFi” and others used in this Site ("Trademarks") are the property of “P2PFi” and their respective owners. The software, text, images, graphics, data, prices, trades, charts, graphs, video and audio used on this Site belong to “P2PFi”. The Trademarks and resource should not be copied, reproduced, modified, republished, uploaded, posted, transmitted, scraped, collected or distributed in any form or by any means, whether manual or automated. The use of any such resources on any other Site or networked computer environment for any other purpose is strictly prohibited; any such unauthorized use may violate copyright, trademark and other applicable laws and could result in criminal or civil penalties. </li>
                            </ul>       
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>RISKS</p>
                            <p className="legals--t3 w3-animate-left">The trading of goods and products, real or virtual, as well as virtual currencies is volatile and involves significant risk. Prices fluctuate and can be irregular on any given day. Owing to such price fluctuations, you may experience increase or lose value in your assets at any given time. 
                            <br/><br/>
                            Cypto trading also has its peculiarities and not generally shared with official currencies or goods or commodities in a market. Unlike most currencies, which are backed by governments or other legal entities, or other commodities such as gold or silver, crypto is a unique kind of "fiat" currency, backed by technology and trust. There is no central financial institution that may take corrective measure to protect the value of crypto in crisis or issue more currency.  
                            <br/><br/>
                            Crypto trading is susceptible to irrational bubbles or loss of confidence, which could collapse demand relative to supply. For example, confidence might collapse in crypto because of unexpected changes imposed by the software developers or others, a government clampdown, the creation of superior competing alternative currencies, or a deflationary or inflationary spiral. Confidence might also collapse because of technical glitches. 
                            <br/><br/>
                            These identified risks as stated in these Terms of Use are inexhaustible.
                            <br/><br/>
                            You may wish to carefully assess whether your financial wherewithal and level of tolerance for risk is suitable for buying, selling or trading crypto. 
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>MAINTAINING YOUR ACCOUNT: OUR POLICY</p>
                            <p className="legals--t3 w3-animate-left">This Site/Web/App is for your personal use only. We are vigilant and tactical in maintaining the security of our Site and the Service. By registering with us, you agree to provide “P2PFi” with current, accurate, and complete information about Yourself as required by the registration process, and to keep such information updated. You further agree that You will not use any Account other than Yours, or access the Account of any other User at any time, or assist others in gaining unauthorized access. 
                            <br/><br/>
                            The creation or use of Accounts without obtaining the prior express permission from Us will result in the immediate suspension of the said Accounts, as well as all pending purchase/sale orders. “P2PFi” may choose to take further action against You. 
                            <br/><br/>
                            You are solely responsible for maintaining the confidentiality of Your Account information, including your password, safeguarding your own Crypto assets, and for all activity including Transactions posted on Your Account. If there is suspicious activity on your Account, we may, but are not obligated, to request additional information from You, including authenticating documents, and to freeze any transactions pending the escalation. You are obligated to comply with these security requests, or accept termination of Your Account. You are required to notify Us immediately of any unauthorized use of Your Account or password, or any other breach of security by email addressed to hello@p2pfi.co. Any User who violates these rules may be terminated, and thereafter held liable for losses incurred by “P2PFi” or any user of the Site and Product. 
                            <br/><br/>
                            You agree that You will not use the Service to perform or orchestrate criminal activity of any sort, including but not limited to, money laundering, illegal gambling operations, terrorist financing, or malicious hacking. We shall report all criminal activities to the law enforcement agencies accordingly.
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>TERMINATION</p>
                            <p className="legals--t3 w3-animate-left">You may terminate this agreement with “P2PFi”, and close your Account at any time, following settlement of any pending transactions.  
                            <br/><br/>
                            You also agree that “P2PFi” may, upon notice, subject to its sole discretion terminate Your access to the Site/Web/App and to Your Account, including without limitation, our right to: limit, suspend or terminate the service and Users' Accounts, prohibit access to the Site and its content, services and tools, delay or remove hosted content, and take technical and legal steps to keep Users off the Site and the Service.
                            Upon termination, User shall communicate a valid bank account to allow for the transfer of any currencies credited to their account. The said bank account shall be held by the User. Crypto may be transferred to a valid bank account only after conversion into a currency. 
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>WARRANTY</p>
                            <p className="legals--t3 w3-animate-left">All services are provided without warranty of any kind, either express or implied. We do not represent that this Site/Web/App will be 100% optimally available all the time to meet your standard and needs. We will continually work round the clock to provide You with the Service as soon as possible but there are no guarantees that access will not be interrupted, or that there will be no delays, failures, errors, omissions or loss of transmitted information.   
                            <br/><br/>
                            We may suspend use of the Site/Web/App for maintenance and will make reasonable efforts to give you prompt notice. 
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>APIS AND WIDGETS</p>
                            <p className="legals--t3 w3-animate-left">We may provide access to certain parties to access specific data and information through our API (Application Programming Interface) or through widgets. We also may provide widgets for your use to put our data on your Site. You are free to use these in their original unmodified and un-altered state. 
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>FINANCIAL ADVICE & REGULATION</p>
                            <p className="legals--t3 w3-animate-left">For the avoidance of doubt we do not provide any investment advice in connection with the Services envisaged by these Terms of Use. We may provide information on the price, range, volatility of Crypto and events that have affected the price of Crypto but this is not considered investment advice and should not be construed as such. Any decision to purchase or sell Crypto is strictly and solely yours and We will not be liable for any loss suffered. 
                            <br/><br/>
                            For the avoidance of doubt we do not provide any investment advice in connection with the Services envisaged by these Terms of Use. We may provide information on the price, range, volatility of Crypto and events that have affected the price of Crypto but this is not considered investment advice and should not be construed as such. Any decision to purchase or sell Crypto is strictly and solely yours and We will not be liable for any loss suffered. 
                            <br/><br/>
                            Our business model, and our Service, consists of facilitating the buying, selling and trading of Crypto and their use to purchase/exchange goods in an unregulated, international open payments system. 
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>LIMITATION OF LIABILITY</p>
                            <p className="legals--t3 w3-animate-left">To the extent permitted by law, “P2PFi” will not be held liable for any damages, loss of profit, loss of revenue, loss of business, loss of opportunity, loss of data, indirect or consequential loss unless the loss suffered arising from negligence or willful deceit or fraud. Nothing in these terms excludes or limits the liability of either party for fraud, death or personal injury caused by its negligence, breach of terms implied by operation of law, or any other liability which may not by law be limited or excluded. 
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>ACCOUNT RESTRICTION</p>
                            <p className="legals--t3 w3-animate-left">We reserve the right to block, restrict or frozen the customers' account provided the circumstances warrant such; the request of the government authorities in accordance to the law; a mistake found in the fund of the customer\'s account or at the request of the money transfer service supplier; upon suspicion of fraudulent activities or law violations; notification from one of the other users about trades; have unresolved disputes in old trades; association with another account that has been frozen without being clarified... 
                            <br/><br/>
                            All funds from accounts related to fraud, law violation or inability to clarify the suspicious activities will be confiscated totally. The fund will be used to compensate the victims per warrant of seizure or court orders, instruction in the form of fraudulent incoming crypto currencies. In case the amount of money collected is less than the sum of all the victims, all the money will be distributed to the victims. 
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>INDEMNITY</p>
                            <p className="legals--t3 w3-animate-left">To such extent permitted by applicable law, You hereby agree to indemnify “P2PFi”, its agents, affiliates and partners against any action, liability, cost, claim, loss, damage, proceeding or expense suffered or incurred if direct or not directly arising from your use of P2PFi's Web/App, products, Your use of the Service, or from your violation of these Terms of Use. 
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>MISCELLANEOUS</p>
                            <p className="legals--t3 w3-animate-left">In the event We are unable to perform/facilitate the Services as contained herein in this Terms of Use due to factors beyond our control including but not limited to an event of Force Majeure, change of law or sanctions policy we will not be liable to You with respect to the Services provided under this agreement and for such time period coincident with the event. 
                            <br/><br/>
                            These Terms constitute the entire agreement between you and P2PFi relating to your access to and use of the Service. These Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you without the prior written consent of P2PFi, and P2PFi’s failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision. No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. 
                            <br/><br/>
                            The Service is operated by us in Nigeria, West Africa.  Whoever chooses to access the Service from locations outside the United States do so at their own initiative and are responsible for compliance with applicable local laws. You and P2PFi agree that the United Nations Convention on Contracts for the International Sale of Goods will not apply to the interpretation or construction of these Terms.
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>TERMS MODIFICATION</p>
                            <p className="legals--t3 w3-animate-left">P2PFi reserves the absolute right to change, review, add or remove any clauses of these Terms, at any time, in an exercise of its sole discretion. You will be notified of any changes in advance through your Account. Upon such notification, it is your responsibility to review the amended Terms. Your continued use of the Site following the posting of a notice of changes to the Terms signifies that you accept and agree to the changes, and that all subsequent transactions by you will be subject to the amended Terms. 
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>CONTACT US</p>
                            <p className="legals--t3 w3-animate-left">If you have any questions relating to these Terms of Use, your rights and obligations arising from these Terms and/or your use of the Site and the Service, your Account, or any other matter, please contact <a href="mailto:hello@p2pfi.co">hello@p2pfi.co</a>.
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>TRADING POLICY</p>
                            <p className="legals--t3 w3-animate-left">While we thank you for using P2PFi service for trading crypto. You may wish to take the following into consideration: 
                            <ul>
                                <li>A trade on P2PFi is an exchange between the maker/seller (who makes an advertisement/listing) and the taker/buyer (who responses to that advertisement/listing). The taker/buyer will be charged 0.3% fee on order creation.</li>
                                <li>The price is solely determined by the maker without any interference from P2PFi. Vice versa, the maker is fully responsible for the price posted. We will not cancel any advertisement/listing that has been taken due to reasons of mistaken price or unwillingness to proceed from taker, we also won’t provide the contact information, supporter can reopen the trade to disputed and ask traders to deal together. The disputed trade should be closed within 2 hours.</li>
                                <li>The buyer is required to pay the correct amount and we reserve the right to cancel the trade if the buyer intentionally fails to pay the correct amount as instructed for personal purposes. In addition, the buyer need not include bank transfer narration, the seller is allowed to request additional verification or refund and refuse the trade if the buyer violates.</li>
                                <li>At P2PFi, we pride in our integrity, fairness and respectfulness. Any proof of scamming, spamming or deliberate tricking will result in banned account without any justification/reason and quarantined remaining balance. You may be required to resolve your old trades before continuing using the service. Please conscious and courteous of your language when interacting with the other users or our supporters.</li>
                            </ul>
                            P2PFi reserves the right to deny or grant access to a particular account/ID upon suspicious fraudulent transaction.
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>CUSTOMER SUPPORTS</p>
                            <p className="legals--t3 w3-animate-left">Trades older than 1 week (from the time the transaction was created to the present) are not checked from customer support. 
                            <ul>
                                <li>Crypto is a non-refundable asset. You need to confirm your payment carefully before releasing the trade or you can open a dispute and ask more proof from buyer. It is for our best interest to protect the right user, however you are responsible first and for most to be a cautious trader. We have the responsibility to process Crypto to your provided wallet, and all Crypto transactions are transparent. </li>
                                <li>In disputed trades, we expect the seller and buyer provide proof of payment, necessary information to resolve via evidence-based discussion in chat section. Our supporters will take action if no consensus can be reached or response out of permitted time.</li>
                            </ul>
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>POLICY FOR DISPUTED TRADES</p>
                            <p className="legals--t3 w3-animate-left">Before you proceed, please take the following timelines into account:
                            <ul>
                                <li>If the seller does not add to sufficient amount of crypto within 24 hours, or if the buyer does not send payment within payment window (default setting is 15 minutes), the trade will be cancelled automatically. </li>
                                <li>When the buyer marks trade status as disputed, the buyer must upload the proof of transfer immediately. The seller has to respond/react as soon as possible. Our supporter will call to remind (in case seller provide verified phone number). After 12 hours without any response from seller, we will release the crypto to buyer. </li>
                                <li>If the seller marks trade status as disputed, the buyer must upload the proof of transfer immediately. The timeline for response of new user buyer (who with less than 10 successful trades in P2PFi) is 2 hours and that of other buyer user is 8 hours. If there is no response within this timeline, the trade will be cancelled and the crypto is unfrozen. </li>
                                <li>During a trade dispute, if you do not respond within 72hours without providing a reasonable reason, we will resolve the disputed trade based on the available data and the partner in that trade is favored. </li>
                            </ul>
                            Our support staff will resolve disputed trades within 72hours save and except in some circumstances.
                            </p>

                            <p className="legals--t3 w3-animate-left">
                            If the buyer and seller of a Crypto trade are in disagreement over a trade either party or P2PFi may initiate a dispute. Disputed trades are resolved by P2PFi.com support staff. 
                            </p>

                            <p className="legals--t3 w3-animate-left">
                            P2PFi.co support, resolves disputes by carefully evaluating the terms of trade, payment evidence, trade chat messages, user reputation, other data submitted to us by our users and data collected by us as part of the privacy policy.  
                            </p>

                            <p className="legals--t3 w3-animate-left">
                            When sellers turn unresponsive, we will resolve the dispute to the buyer if we are confident the seller had received valid payment from the buyer. If the buyer is unresponsive the seller may dispute the trade and we will resolve the dispute to the seller. 
                            </p>

                            <p className="legals--t3 w3-animate-left">
                            After the trade has been released by the seller the trade is considered finished by us and cannot be disputed, reversed or altered or otherwise.
                            </p>

                            <p className="legals--t3 w3-animate-left">
                            Providing fraudulent information or documents in a dispute or making false claims or otherwise trying to force a certain outcome of a disputed trade is considered a violation of this agreement and will be dealt with accordingly.
                            </p>

                        </div>
                    </div>
                </div>
            <Home_Footer />
        </>
    )
}

export default Terms