import React from "react";
import { Link } from "react-router-dom"

const UpdateDisputeSuccess = (props) => {

    const closePaymentConfirm = () => {
        window.location.reload()
        // document.getElementById("payment-confirmation").style.display = "none"
    }

    return(
        <>
            <div className="w3-modal" id="payment-confirmation">
                <div className="offer--details-modal2 w3-modal-content w3-animate-bottom">
                    <i className="offer--details-modal2-icon1 fa-solid fa-thumbs-up"></i>
                    <p className="offer--details-modal2-t1">Details Updated Successfully</p>
                    <p className="offer--details-modal2-t2">Please wait while P2PFi confirms the details and release the coins to you.</p>
                    <Link to="../profile" className="link"><button className="offer--details-modal2-btn1">View Transaction History</button></Link>
                    <button onClick={props.openedUpdatedDisputeSuccess} className="offer--details-modal2-btn2">Close</button>
                </div>
            </div>
        </>
    )
}

export default UpdateDisputeSuccess