import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import HomePage_Header from "../../components/HomePage_Header";
import Home_Footer from "../../components/Home_Footer";
import axios from "axios"

import BuyOrder_Accepted from "../../components/modals/BuyOrder_Accepted"
import BuyOrder_Confirmed from "../../components/modals/BuyOrder_Confirmed"
import Offer_Bank_Modal from "../../components/modals/Offer_Bank_Modal"
import SellOrder_Cancelled from "../../components/modals/SellOrder_Cancelled";
import AllErros from "../../components/modals/BuyOrder_Error";


let newApiMonth
let newApiYear


function Address(props) {

    const [ newID, setNewID ]= useState("")
    const [ newUsername, setNewUsername ]= useState("")
    const [ newEmail, setNewEmail ]= useState("")
    const [ newBankName, setNewBankName ]= useState("")
    const [ newAccountNumber, setNewAccountNumber ]= useState("")
    const [ newAccountName, setNewAccountName ]= useState("")
    const [ newTwitterUrl, setNewTwitterUrl ]= useState("")
    const [ newCreatedAt, setNewCreatedAt ]= useState("")
    const [ newProfileImg, setNewProfileImg ]= useState("")
    const [ newBannerImg, setNewBannerImg ]= useState("")
    const [ newWalletAddress, setNewWalletAddress ]= useState("")

    const [ shareIcon , setShareIcon ] = useState("fa-solid fa-share-nodes")

    const baseURL = "https://p2pfi.xyz"
    const urlAddress = window.location.pathname.split("/");
    console.log(urlAddress[2])

    const getByID = async () => {
        await axios.get(baseURL + "/api/users/search/" + urlAddress[2])
        .then((response) => {
            console.log(response)
            console.log(response?.data)
            response?.data.forEach(data => {
                console.log(data.username)
                setNewID(data.id)
                setNewUsername(data.username)
                setNewEmail(data.email)
                setNewBankName(data.bankName1)
                setNewAccountNumber(data.accountNumber1)
                setNewAccountName(data.accountName1)
                setNewTwitterUrl(data.twitterURL)
                setNewCreatedAt(data.created_at)
                setNewProfileImg(data.profileImage)
                setNewBannerImg(data.bannerImage)
                setNewWalletAddress(data.walletAddress)
            })

        }, (error) => {
            console.log(error)
        })
    }
    getByID()

    const copyProfile = () => {
        navigator.clipboard.writeText(`https://p2pfi.vercel.app/address/${newWalletAddress}`);
        console.log("copied")
        setShareIcon("fa-solid fa-check")
        setTimeout(() => {
            setShareIcon("fa-solid fa-share-nodes")
        }, 2000)
        
    }

    const shareProfile = () => {
        if (navigator.share) {
            navigator.share({
                title: "Wallet Address",
                url: `https://p2pfi.vercel.app/address/${newWalletAddress}`
            })
            .then(() => {
                setShareIcon("fa-solid fa-check")
                setTimeout(() => {
                    setShareIcon("fa-solid fa-share-nodes")
                }, 2000)
            })
        } else {
            copyProfile()
        }
    }

    const [ openBuyOrderAccepted, setOpenBuyOrderAccepted ] = useState(false)
    const [ openBuyOrderPay, setOpenBuyOrderPay ] = useState(false)
    const [ openBuyOrderConfirmed, setOpenBuyOrderConfirmed ] = useState(false)

    const [ openSellOrderCancel, setOpenSellOrderCancel ] = useState(false)


    const [ openAllErrors, setOpenAllErrors ] = useState(false)



    const openedBuyOrderAccepted = () => {
        setOpenBuyOrderAccepted(current => !current);
    }

    const openedBuyOrderPay = () => {
        setOpenBuyOrderPay(current => !current);
    }

    const openedBuyOrderConfirmed = () => {
        setOpenBuyOrderConfirmed(current => !current);
    }



    const openedSellOrderCancel = () => {
        setOpenSellOrderCancel(current => !current);
    }



    const openedAllErrors = () => {
        setOpenAllErrors(current => !current);
    }



    const newed = async () => {

        const myDate = await newCreatedAt;
        const arr = myDate.split(/ |-/);

        console.log('date: ', arr[0]);
        console.log('month: ', arr[1]);
        console.log('year: ', arr[2]);

        const apiMonth = arr[1];

        console.log("The Month", apiMonth)

        newApiYear = arr[0];

        const getDate = () => {
            if(apiMonth == 1) {
                newApiMonth = "January";
            } else if(apiMonth == 2) {
                newApiMonth = "February";
            } else if(apiMonth == 3) {
                newApiMonth = "March";
            } else if(apiMonth == 4) {
                newApiMonth = "April";
            } else if(apiMonth == 5) {
                newApiMonth = "May";
            } else if(apiMonth == 6) {
                newApiMonth = "June";
            } else if(apiMonth == 7) {
                newApiMonth = "July";
            } else if(apiMonth == 8) {
                newApiMonth = "August";
            } else if(apiMonth == 9) {
                newApiMonth = "September";
            } else if(apiMonth == 10) {
                newApiMonth = "October";
            } else if(apiMonth == 11) {
                newApiMonth = "November";
            } else if(apiMonth == 12) {
                newApiMonth = "December";
            }
        }

        getDate()
        console.log(newApiMonth);

    }
    newed()


    const color1 = () => {
        document.getElementById("profile--hero").style.backgroundColor = "#F7F8F9";
    }

    const color2 = () => {
        document.getElementById("profile--hero").style.backgroundColor = "green";
    }

    const color3 = () => {
        document.getElementById("profile--hero").style.backgroundColor = "blue";
    }

    const color4 = () => {
        document.getElementById("profile--hero").style.backgroundColor = "yellow";
    }

    const color5 = () => {
        document.getElementById("profile--hero").style.backgroundColor = "orange";
    }




    const profileColor1 = () => {
        document.getElementById("profile-img").style.backgroundColor = "#F7F8F9";
    }

    const profileColor2 = () => {
        document.getElementById("profile-img").style.backgroundColor = "green";
    }

    const profileColor3 = () => {
        document.getElementById("profile-img").style.backgroundColor = "blue";
    }

    const profileColor4 = () => {
        document.getElementById("profile-img").style.backgroundColor = "yellow";
    }

    const profileColor5 = () => {
        document.getElementById("profile-img").style.backgroundColor = "orange";
    }



    useEffect(() => {
        if(newBannerImg === "green") {
            color2()
        } else if(newBannerImg === "blue") {
            color3()
        } else if(newBannerImg === "yellow") {
            color4()
        } else if(newBannerImg === "orange") {
            color5()
        } else {
            color1()
        }
    })

    useEffect(() => {
        if(newProfileImg === "green") {
            profileColor2()
        } else if(newProfileImg === "blue") {
            profileColor3()
        } else if(newProfileImg === "yellow") {
            profileColor4()
        } else if(newProfileImg === "orange") {
            profileColor5()
        } else {
            profileColor1()
        }
    })

    const sell_orders = () => {
        document.getElementById("sell-orders").style.display = "block"
        document.getElementById("buy-orders").style.display = "none"
        document.getElementById("activities").style.display = "none"
        document.getElementById("pending-orders").style.display = "none"
        document.getElementById("sell-orders1").focus();
    }

    const buy_orders = () => {
        document.getElementById("sell-orders").style.display = "none"
        document.getElementById("buy-orders").style.display = "block"
        document.getElementById("activities").style.display = "none"
        document.getElementById("pending-orders").style.display = "none"
        document.getElementById("buy-orders1").focus();
    }

    const activities = () => {
        document.getElementById("sell-orders").style.display = "none"
        document.getElementById("buy-orders").style.display = "none"
        document.getElementById("pending-orders").style.display = "none"
        document.getElementById("activities").style.display = "block"
        document.getElementById("activities1").focus();
    }

    const completed_orders = () => {
        document.getElementById("sell-orders").style.display = "none"
        document.getElementById("buy-orders").style.display = "none"
        document.getElementById("activities").style.display = "none"
        document.getElementById("pending-orders").style.display = "block"
        document.getElementById("pending-orders1").focus();
    }

    const open_profile_menu = () => {
        var x = document.getElementById("profile--open_profile_menu");
        if (x.className.indexOf("w3-show") == -1) {
          x.className += " w3-show";
        } else {
          x.className = x.className.replace(" w3-show", "");
        }
    }


    const [ newSellOffers , setNewSellOffers ] = useState([]);

    useEffect(() => {
        const renderOffers = async () => {
            try {
                const query = await axios.post(
                    "https://api.thegraph.com/subgraphs/name/staa99/p2pfi-mumbai",
                    {
                        query: `{
                            sellOrders {
                                id
                                token {
                                    id
                                    name
                                }
                                seller {
                                    id
                                }
                                preDisputeDuration
                                totalAmount
                                minimumBuyAmount
                                filledAmount
                                dateCreated
                                status
                                feeRate
                                metadataURI
                                buyOrders {
                                    id
                                    status
                                    amount
                                    metadataUri
                                    buyer {
                                        id
                                    }
                                    token {
                                        id
                                        name
                                    }
                                    sellOrder {
                                        id
                                        totalAmount
                                        seller {
                                            id
                                        }
                                    }
                                }
                              }
                        }`,
                    },
                        {
                            headers: {
                                        "Content-Type": "application/xml"
                                    },
                            withCredentials: false,
                        }
                );


                setNewSellOffers(query.data.data.sellOrders);
                // console.log(sellOffers)

            } catch(err) {
                console.log(err)
            }
        }
        renderOffers()
    }, [])

    const newFilteredOrders = newSellOffers.filter((data) => {
        return data.status === "Initialized"
    })

    const newNewFilteredOrders = newFilteredOrders.filter((data) => {
        return data.seller.id === newWalletAddress
    })








    // Buy Orders List1

    let mySeller;
    let myTotal;

    const newNewFilteredOrders2 = newNewFilteredOrders.map((data) => {
        mySeller = data.seller.id
        myTotal = data.totalAmount
        return data.buyOrders
    })
    console.log(newNewFilteredOrders2);

    const newNewFilteredOrders2b = newNewFilteredOrders2.filter((data) => {
        if (Object.keys(data).length !== 0) {
            return true;
          }
          return false;
    })
    console.log(newNewFilteredOrders2b);

    const newNewFilteredOrders2c = newNewFilteredOrders2b.map((data) => {
        console.log(data)
        return data
    })

    // Concat and apply method for []
    const newNewFilteredOrders2d = [].concat.apply([], newNewFilteredOrders2c)

    console.log(newNewFilteredOrders2d);

    const newNewFilteredOrders2dxx = newNewFilteredOrders2d.filter((data) => {
        return data.status !== "Accepted"
    })



    const newNewFilteredOrders3 = newNewFilteredOrders2dxx.map((data) => {


        return (
            <>
                <tr>
                    <td className="profile--table-row">{data.status}</td>
                    <td className="profile--table-row">{data.token.name}</td>
                    <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "140px",  "paddingBottom": "38px", "paddingLeft": "62px"}} className="profile--table-row">{data.id}</td>
                    <td className="profile--table-row">{(data.sellOrder.totalAmount * 1).toLocaleString("en-US")}</td>
                    <td className="profile--table-row" style={{"paddingLeft": "52px"}}>{(data.amount * 1).toLocaleString("en-US")}</td>
                    <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "200px", "paddingBottom": "38px", "paddingLeft": "14px"}} className="profile--table-row">{data.buyer.id}</td>
                </tr>
                {
                    openBuyOrderAccepted && <BuyOrder_Accepted />
                }
                {
                    openBuyOrderConfirmed && <BuyOrder_Confirmed />
                }
                {
                openAllErrors && <AllErros
                                    errorID = {data.id}
                                />
                }
            </>
        )
    })











        // Buy Orders List2




        let mySeller2x = "";
        let myTotal2x = "";

        const newNewFilteredOrders2x = newFilteredOrders.map((data) => {
            mySeller2x = data.seller.id
            myTotal2x = data.totalAmount
            return data.buyOrders
        })
        console.log(newNewFilteredOrders2);

        const newNewFilteredOrders2bx = newNewFilteredOrders2x.filter((data) => {
            if (Object.keys(data).length !== 0) {
                return true;
              }
              return false;
        })
        console.log(newNewFilteredOrders2bx);

        const newNewFilteredOrders2cx = newNewFilteredOrders2bx.map((data) => {
            console.log(data)
            return data
        })

        // Concat and apply method for []
        const newNewFilteredOrders2dx = [].concat.apply([], newNewFilteredOrders2cx)

        console.log(newNewFilteredOrders2dx)


        const newNewFilteredOrders2ex = newNewFilteredOrders2dx.filter((data) => {
            return data.buyer.id === newWalletAddress
        })

        const newNewFilteredOrders2fx = newNewFilteredOrders2ex.filter((data) => {
            return data.status === "Accepted"
        })

        const newNewFilteredOrders2gx = newNewFilteredOrders2ex.filter((data) => {
            return data.status === "Completed"
        })






        const newNewFilteredOrders3x = newNewFilteredOrders2fx.map((data) => {
            return (
                <>
                    <tr>
                        <td className="profile--table-row">{data.status}</td>
                        <td className="profile--table-row">{data.token.name}</td>
                        <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "140px",  "paddingBottom": "38px", "paddingLeft": "62px"}} className="profile--table-row">{data.id}</td>
                        <td className="profile--table-row">{(data.sellOrder.totalAmount * 1).toLocaleString("en-US")}</td>
                        <td className="profile--table-row" style={{"paddingLeft": "52px"}}>{(data.amount * 1).toLocaleString("en-US")}</td>
                        <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "200px", "paddingBottom": "38px", "paddingLeft": "14px"}} className="profile--table-row">{data.buyer.id}</td>
                    </tr>


                    {
                        openBuyOrderPay && <Offer_Bank_Modal
                                                buyOrderID = {data.id}
                                                buyAmount = {data.amount}
                                                sellerAddress = {data.sellOrder.seller.id}
                                                orderDuration = {data.sellOrder.preDisputeDuration}
                                            />
                    }
                </>
            )
        })







    // Completed Orders List


    const newBuyOrderList = newNewFilteredOrders2d.filter((data) => {
        return data.status === "Completed"
    })

    const newBuyOrderListB = newNewFilteredOrders2fx.filter((data) => {
        return data.status === "Completed"
    })

    const newBuyOrderListC = newNewFilteredOrders2gx.filter((data) => {
        return data.status === "Completed"
    })

    const concatnewBuyOrderList = newBuyOrderList.concat(newBuyOrderListB, newBuyOrderListC);

     console.log(concatnewBuyOrderList)

    const newBuyOrderList2 = concatnewBuyOrderList.map((data) => {
        return (
            <>
                <tr>
                <td className="profile--table-row">{data.status}</td>
                    <td className="profile--table-row">{data.token.name}</td>
                    <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "140px",  "paddingBottom": "38px", "paddingLeft": "62px"}} className="profile--table-row">{data.id}</td>
                    <td className="profile--table-row">{(data.sellOrder.totalAmount * 1).toLocaleString("en-US")}</td>
                    <td className="profile--table-row" style={{"paddingLeft": "52px"}}>{(data.amount * 1).toLocaleString("en-US")}</td>
                    <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "200px", "paddingBottom": "38px", "paddingLeft": "14px"}} className="profile--table-row">{data.buyer.id}</td>
                </tr>
            </>
        )
    })








    // Sell Orders List


    const trueItem = () => {
        document.getElementById("sell-orders--true-item").style.display = "block";
        document.getElementById("sell-orders--no-item").style.display = "none";
    }

    const noItem = () => {
        document.getElementById("sell-orders--true-item").style.display = "none";
        document.getElementById("sell-orders--no-item").style.display = "block";
    }

    const newListOrders = newNewFilteredOrders.map((data) => {

        if (newNewFilteredOrders !== undefined) {
            trueItem()
            return(
                <>
                    <tr>
                        <td className="profile--table-row" style={{"paddingLeft": "44px"}}><i className="fa-brands fa-nfc-symbol profile--table-icon"></i>{data.id}</td>
                        <td className="profile--table-row">{data.token.name}</td>
                        <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "140px",  "paddingBottom": "38px"}} className="profile--table-row">{"₦" + " " + (data.totalAmount * 0.9 * 720).toLocaleString("en-US")}</td>
                        <td className="profile--table-row">{(data.totalAmount * 1).toLocaleString("en-US")}</td>
                        <td className="profile--table-row" style={{"paddingLeft": "10px"}}>{(data.filledAmount * 1).toLocaleString("en-US")}</td>
                        <td style={{"display": "inline-block", "whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "200px", "paddingBottom": "38px", "paddingLeft": "14px"}} className="profile--table-row">{data.seller.id}</td>
                        <td style={{"whiteSpace": "nowrap", "overflow": "hidden", "textOverflow": "ellipsis", "width": "200px", "paddingLeft": "32px"}} className="profile--table-row">
                            <button onClick={buy_orders} style={{"border": "none", "backgroundColor": "green", "color": "white", "padding": "2px 12px", "borderRadius": "4px", "marginRight": "6px"}}>
                                View
                            </button>
                        </td>
                    </tr>
                    {
                        openSellOrderCancel && <SellOrder_Cancelled
                                                    mySellOrderID = {data.id}
                                                />
                    }
                    {
                    openAllErrors && <AllErros
                                        errorID = {data.id}
                                    />
                    }
                </>
            )
        }
        else {
            noItem()
        }

    })



    const [ search1, setSearch1] = useState('')
    const [ search2, setSearch2] = useState('')
    const [ search3, setSearch3] = useState('')
    const [ search4, setSearch4] = useState('')
    const [ recentlyBought, setRecentlyBought ] = useState('')
    const [ recentlySold, setRecentlySold ] = useState('')
    const [ recentlyBoughtOrSold, setRecentlyBoughtOrSold ] = useState('')
    const [ pendingOrders, setPendingOrders ] = useState('')
    const [ error, setError ] = useState('');

    useEffect(() => {
        setError('');
      }, [search1, recentlyBought])

      useEffect(() => {
        setError('');
      }, [search2, recentlySold])

      useEffect(() => {
        setError('');
      }, [search3, recentlyBoughtOrSold])

      useEffect(() => {
        setError('');
      }, [search4, pendingOrders])

      const handleSubmit1 = async (e) => {
        e.preventDefault();
        console.log(search1, recentlyBought)
        setSearch1('')
        setRecentlyBought('')
      }

      const handleSubmit2 = async (e) => {
        e.preventDefault();
        console.log(search2, recentlyBought)
        setSearch2('')
        setRecentlySold('')
      }

      const handleSubmit3 = async (e) => {
        e.preventDefault();
        console.log(search3, recentlyBought)
        setSearch3('')
        setRecentlyBoughtOrSold('')
      }

      const handleSubmit4 = async (e) => {
        e.preventDefault();
        console.log(search4, pendingOrders)
        setSearch4('')
        setPendingOrders('')
      }

    return(
        <>
            <HomePage_Header />
            <div className="profile--hero" id="profile--hero">
                <img src=""
                className="profile--img"
                id="profile-img"
                />
                
                {
                    newUsername == "" ? <p>Unknown</p> : <p className="profile--t1">{newUsername}</p>
                }
                <div className="w3-left profile--left">
                    <i className="fa-brands fa-ethereum profile--icon1"></i>
                    <span className="profile--t2">{newWalletAddress}</span>
                    <span className="profile--t2b">Joined {newApiMonth + " " + newApiYear}</span>
                </div>
                <div className="w3-right profile--right w3-hide-small">
                    <a href={"https://" + newTwitterUrl} target="_blank"><i className="fa-brands fa-twitter profile--icon2"></i></a>
                    <i onClick={shareProfile} className={shareIcon + " profile--icon2"}></i>
                    
                    
                    {/* <Link to="/settings" className="link"><i className="fa-solid fa-gear profile--icon2"></i></Link> */}

                    {/* Dropdown with menu button */}

                     {/* <i onClick={open_profile_menu} className="fa-solid fa-ellipsis-vertical profile--icon2b"></i>


                    <div id="profile--open_profile_menu" className="profile--menu w3-dropdown-content w3-bar-block w3-card-4 w3-animate-zoom">
                        <Link to="/settings" className="link"><p className="w3-bar-item"><i className="fa fa-gear profile--icon2c"></i><span className="profile--menu-t">Setting</span></p></Link>
                        <hr/>
                        <Link to="/report" className="link"><p className="w3-bar-item"><i className="fa fa-flag profile--icon2c"></i><span className="profile--menu-t">Report</span></p></Link>
                        <hr/>
                        <Link to={"/address/" + newWalletAddress} target="_blank" className="link"><p className="w3-bar-item"><i className="profile--icon2cc profile--icon2c fa-solid fa-share-nodes"></i><span className="profile--menu-t">Featured items</span></p></Link>
                    </div> */}

                </div>

                <div className="w3-hide-medium w3-hide-large">
                    <i onClick={open_profile_menu} className="w3-right fa-solid fa-ellipsis-vertical profile--icon2b"></i>


                    <div id="profile--open_profile_menu" className="profile--menu w3-dropdown-content w3-bar-block w3-card-4 w3-animate-zoom">
                        {/* <Link to="/settings" className="link"><p className="w3-bar-item"><i className="fa fa-gear profile--icon2c"></i><span className="profile--menu-t">Setting</span></p></Link>
                        <hr/> */}
                        <a href={"https://" + newTwitterUrl} className="link" target="_blank"><p className="w3-bar-item"><i className="fa-brands fa-twitter profile--icon2c"></i><span className="profile--menu-t">Twitter</span></p></a>
                        <hr/>
                        <p className="w3-bar-item" onClick={shareProfile}><i className={"profile--icon2cc profile--icon2c " + shareIcon}></i><span className="profile--menu-t">Share Profile</span></p>
                    </div>
                </div>

            </div>

            <div className="profile--section">
                <div className="profile--t3-base">
                    <span onClick={sell_orders} tabIndex="0" className="profile--t3 orderss" id="sell-orders1">Sell Orders</span>
                    <span onClick={buy_orders} tabIndex="-1" className="profile--t3 orderss" id="buy-orders1">Buy Orders</span>
                    <span onClick={activities} tabIndex="-2" className="profile--t3 orderss" id="activities1">Activities</span>
                    <span onClick={completed_orders} tabIndex="-3" className="profile--t3 orderss" id="pending-orders1">Completed Orders</span>
                </div>
                <hr/>


                <div className="profile--sell-orders orderss" id="sell-orders">
                    <form onSubmit={handleSubmit1} className="sell--orders-header">
                        <img src="/images/profile_filter.png"
                        className="profile--icon3"
                        />
                        <input
                        className="profile--input-1"
                        type="search"
                        placeholder="Search"
                        onChange={(e) => setSearch1(e.target.value)}
                        value={search1}
                        />
                        <input
                        className="profile--input-2"
                        type="search"
                        placeholder="Recently Sold"
                        onChange={(e) => setRecentlySold(e.target.value)}
                        value={recentlySold}
                        />
                    </form>

                    <div className="profile--table-div">
                        <table className="profile--table" id="sell-orders--true-item">
                            <tbody>

                                <tr>
                                    <th className="profile--table-head-h"><span className="profile--table-head-h-h">Sell OrderID</span></th>
                                    <th className="profile--table-head" style={{"paddingLeft": "14px"}}>Coin Name</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "38px"}}>Price</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "12px"}}>Quantity</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Filled Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "42px"}}>From</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "42px"}}>Orders</th>
                                </tr>
                                {newListOrders}
                            </tbody>
                        </table>
                    </div>

                    <div id="sell-orders--no-item" className="sell--orders-list">
                        <p className="profile--t4">No Items to Display</p>
                    </div>

                </div>

                <div className="profile--buy-orders orderss" id="buy-orders">
                    <form onSubmit={handleSubmit2} className="sell--orders-header">
                        <img src="/images/profile_filter.png"
                        className="profile--icon3"
                        />
                        <input
                        className="profile--input-1"
                        type="search"
                        placeholder="Search"
                        onChange={(e) => setSearch2(e.target.value)}
                        value={search2}
                        />
                        <input
                        className="profile--input-2"
                        type="search"
                        placeholder="Recently Bought"
                        onChange={(e) => setRecentlyBought(e.target.value)}
                        value={recentlySold}
                        />
                    </form>

                    {/* <div className="sell--orders-list">
                        <p className="profile--t4">No Items to Display</p>
                    </div> */}
                    <div className="profile--table-div">
                        <table className="profile--table">
                            <tbody>
                                <tr>
                                    <th className="profile--table-head-h"><span className="profile--table-head-h-h">Status</span></th>
                                    <th className="profile--table-head" style={{"paddingLeft": "14px"}}>Coin Name</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "38px"}}>Buy OrderID</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Total Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "20px"}}>Order Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "32px"}}>Buyer</th>
                                    {/* <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Accept | Confirm | Pay</th> */}
                                    {/* <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Accept | Confirm | Pay</th> */}
                                </tr>
                                {newNewFilteredOrders3}
                                {newNewFilteredOrders3x}
                            </tbody>
                        </table>
                    </div>

                </div>

                <div className="profile--activities orderss" id="activities">
                    <form onSubmit={handleSubmit3} className="sell--orders-header">
                        <img src="/images/profile_filter.png"
                        className="profile--icon3"
                        />
                        <input
                        className="profile--input-1"
                        type="search"
                        placeholder="Search"
                        onChange={(e) => setSearch3(e.target.value)}
                        value={search3}
                        />
                        <input
                        className="profile--input-2"
                        type="search"
                        placeholder="Recently Bought or Sold"
                        onChange={(e) => setRecentlyBoughtOrSold(e.target.value)}
                        value={recentlyBoughtOrSold}
                        />
                    </form>

                    <div className="profile--table-div">
                        <table className="profile--table">
                            <tbody>
                                <strong style={{"fontSize": "20px"}}>Sell Orders</strong>
                                <br/><br/>
                                <tr>
                                    <th className="profile--table-head-h"><span className="profile--table-head-h-h">Sell OrderID</span></th>
                                    <th className="profile--table-head" style={{"paddingLeft": "14px"}}>Coin Name</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "38px"}}>Price</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "12px"}}>Quantity</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Filled Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "42px"}}>From</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "42px"}}>Orders</th>
                                </tr>
                                {newListOrders}
                                <br/><br/><br/><br/>
                                <strong style={{"fontSize": "20px"}}>Buy Orders</strong>
                                <br/><br/>
                                <tr>
                                    <th className="profile--table-head-h"><span className="profile--table-head-h-h">Status</span></th>
                                    <th className="profile--table-head" style={{"paddingLeft": "14px"}}>Coin Name</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "38px"}}>Buy OrderID</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Total Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "20px"}}>Order Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "32px"}}>Buyer</th>
                                    {/* <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Accept | Confirm | Pay</th> */}
                                    {/* <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Accept | Confirm | Pay</th> */}
                                </tr>
                                {newNewFilteredOrders3}
                                {newNewFilteredOrders3x}
                            </tbody>
                        </table>
                    </div>

                </div>

                <div className="profile--pending-orders orderss" id="pending-orders">
                    <form onSubmit={handleSubmit4} className="sell--orders-header">
                        <img src="/images/profile_filter.png"
                        className="profile--icon3"
                        />
                        <input
                        className="profile--input-1"
                        type="search"
                        placeholder="Search"
                        onChange={(e) => setSearch4(e.target.value)}
                        value={search4}
                        />
                        <input
                        className="profile--input-2"
                        type="search"
                        placeholder="Pending Orders"
                        onChange={(e) => setPendingOrders(e.target.value)}
                        value={pendingOrders}
                        />
                    </form>

                    {/* <div className="sell--orders-list">
                        <p className="profile--t4">No Items to Display</p>
                    </div> */}
                    <div className="profile--table-div">
                        <table className="profile--table">
                            <tbody>
                                <tr>
                                    <th className="profile--table-head-h"><span className="profile--table-head-h-h">Status</span></th>
                                    <th className="profile--table-head" style={{"paddingLeft": "14px"}}>Coin Name</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "38px"}}>Order ID</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "20px"}}>Total Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "32px"}}>Order Amount</th>
                                    <th className="profile--table-head" style={{"paddingLeft": "42px"}}>Buyer</th>
                                    {/* <th className="profile--table-head" style={{"paddingLeft": "0px"}}>Accept | Confirm | Pay</th> */}
                                </tr>
                                {newBuyOrderList2}

                            </tbody>
                        </table>
                    </div>

                </div>






            </div>







            <Home_Footer />
        </>
    )
}

export default Address

// call "placeBuyOrder" to make Order === "Initialized" (on buyer end);
// If Order === "Initialized", call "acceptBuyOrder" on seller end;
// If Order === "Accepted", call "payBuyOrder" on Buyer end;
// If Order === "Paid", call "confirmBuyOrder" on Seller end;


// Use general list for pending orders with buyer ID == props.account and seller ID == props.account

// !!Buy Order for personal buy orders
// !!sell order for personal sell order
// !!activities for sell && buy orders
// !!so you can remove pending orders after that