import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import HomeSideBar from "./Home SideBar";
import { useAccount, useBalance } from "wagmi"
import { cryptoExchangeABI } from "../abi/cryptoExchangeABI";
import { useProvider, useContract } from 'wagmi'
import { contractAddress } from "../App";


function Home_Header(props) {

    const [ profileImage, setProfileImage ] = useState("#F7F8F9")

    useEffect(() => {
        if(props.apiProfileImage === "green") {
            setProfileImage("green")
        } else if(props.apiProfileImage === "blue") {
            setProfileImage("blue")
        } else if(props.apiProfileImage === "yellow") {
            setProfileImage("yellow")
        } else if(props.apiProfileImage === "orange") {
            setProfileImage("orange")
        } else {
            setProfileImage("#F7F8F9")
        }
    })

    const [ sidebar, setSidebar ] = useState()
    const [ account, setAccount ] = useState()
    const [ balance1, setBalance1 ] = useState()
    const [ token1, setToken1 ] = useState()
    const [ realBalance, setRealBalance ] = useState()
    const [ theBalance2, setTheBalance2 ] = useState(    
        <>
          <span className="loader3" style={{"position": "relative", "top": "26px", "left": "47%"}}></span>
      </>
    )

    const { address, isConnected, isDisconnected } = useAccount()
    const balance = useBalance({
        address: address,
        onSuccess(data) {
            console.log('Success', data)
            setBalance1(data?.formatted)
        },
    })

    const balance2 = useBalance({
        address: address,
        token: "0xe11a86849d99f524cac3e7a0ec1241828e332c62",
        onSuccess(data) {
            console.log('Success', data)
            setToken1(data?.formatted)
        },
      })

    const provider = useProvider()
 
    const contract = useContract({
      address: contractAddress,
      abi: cryptoExchangeABI,
      signerOrProvider: provider,
    })

    const tokenAdd = "0xe11a86849d99f524cac3e7a0ec1241828e332c62"

    const getBalance2 = () => {
        contract.balanceOf(address, tokenAdd).then(
            (data) => {
                const bladata = data._hex;
                const theBalance = parseInt(bladata, 16);
                setRealBalance(theBalance);
                console.log(theBalance)
                setTheBalance2(
                    <>
                        <div className="">
                            <div className="w3-left" style={{ "position": "relative", "top": "6px" }}>
                                <i className="fa-solid fa-wallet home--sidebar-icon1" style={{ "fontSize": "20px", "position": "relative", "top": "-4px" }}></i>
                                <span className="home--sidebar-t6" style={{"left": "-2px"}}>USDC (Approved)</span><br /><span className="home--sidebar-t7">Polygon (MATIC)</span>
                            </div>
                            <div className="w3-right">
                                <span className="home--sidebar-t8">{theBalance}</span><br /><span className="home--sidebar-t9">{(theBalance * 0.00001 * 740).toFixed(2)} NGN</span>
                                <span><i className="fa-solid fa-ellipsis-vertical home--sidebar-icon2"></i></span>
                            </div>
                            <br /><br />
                        </div>
                    </>
                );
            }
        );

    }
    

    useEffect(() => {
        if (isConnected) {
            setAccount(address)
            getBalance2()
            props.headerToOthers1(address)
            console.log(address)
            console.log(balance1)
            console.log(token1)
            console.log(realBalance)
          }
    }, [])

    const ethRate = 0.9415;
    const usdcRate = 0.00001;


    const tb1 = balance1 * ethRate;
    const tb2 = realBalance * usdcRate;
    const tb3 = token1 * usdcRate;
    const totalBalance = +tb1 + +tb2 + +tb3;













    const openSidebar = () => {
        setSidebar(current => !current)
    }

    const openNotification = () => {
        var x = document.getElementById("home-notifications");
        if (x.className.indexOf("w3-show") == -1) {
            x.className += " w3-show";
          } else { 
            x.className = x.className.replace(" w3-show", "");
          }
    }

    // const closeNotification = () => {
    //     document.getElementById("home-notifications").style.display = "none"
    // }

    const openHomeSide = () => {
        var x = document.getElementById("home-side");
        if (x.className.indexOf("w3-show") == -1) {
            x.className += " w3-show";
          } else { 
            x.className = x.className.replace(" w3-show", "");
          }
    }

    return(
        <>
            <div className="home--header">
                <div className="w3-left home--header-l">
                    <Link to=".." className="link"><img src="/images/main-lg.png"
                    className="home--lg-1 w3-animate-left"
                    alt="P2PFi"
                    /></Link>
                </div>
                <div className="w3-right home--header-r w3-hide-small">
                    <p><Link to="../sell-offers" className="link"><span className="home--t1 w3-animate-top">Create Order</span></Link><a href="/buy-offers" className="link"><span className="home--t1 w3-animate-top">View Offers</span></a></p>
                    <i onClick={openNotification} className="fa-solid fa-bell home--icon-1-1 w3-animate-top"></i>
                    {
                        isDisconnected ? <Link to="../connect"><i className="fa-solid fa-wallet home--icon-1 w3-animate-top"></i></Link> : <Link to="../profile"><i className="fa-regular fa-circle-user home--icon-1 w3-animate-top"></i></Link>
                    }
                    
                    <img src=""
                    style={{"background": `${profileImage}`}}
                    className="home--lg-3 w3-animate-top"
                    onClick={openSidebar}
                    />
                </div>

                <div onClick={openHomeSide} className="w3-right w3-hide-large w3-hide-medium">
                    <i className="fa-solid fa-bars" style={{"fontSize": "28px", "marginTop": "6px"}}></i>
                </div>

                {/*Sidebar on mobile */}

                <div id="home-side" className="w3-sidebar home-side w3-hide-large w3-hide-medium w3-animate-right ">
                    <Link to="../sell-offers" className="link"><p className="home-side-t"><span><i className="fa-solid fa-circle-plus home-side-icon"></i></span>Create Order</p></Link>
                    <Link to="../buy-offers" className="link"><p className="home-side-t"><span><i className="fa-solid fa-eye home-side-icon"></i></span>View Offers</p></Link>
                    <p onClick={openNotification} className="home-side-t"><span><i className="fa-solid fa-bell home-side-icon"></i></span>Notifications</p>
                    <Link to="../connect" className="link"><p className="home-side-t"><span><i className="fa-solid fa-wallet home-side-icon"></i></span>Connect Wallet</p></Link>
                    <Link to="../profile" className="link"><p className="home-side-t"><span><i className="fa-solid fa-circle-user home-side-icon"></i></span>Profile</p></Link>
                </div>

                {
                    sidebar && <HomeSideBar 
                        account = {account}
                        balance = {balance1}
                        token1 = {token1}
                        balance2 = {theBalance2}
                        openSidebar = {openSidebar}
                        openNotification = {openNotification}
                        totalBalance = {totalBalance}
                        profileImage = {profileImage}
                        apiUsername = {props.apiUsername}
                    />
                }

                <div id="home-notifications" className="w3-row w3-sidebar home--sidebar2 w3-right w3-animate-right" style={{"width": "100%", "background": 'transparent', "marginTop": "0", "height": "100vh"}}>
                    <div onClick={openNotification} className="w3-left w3-col l9 s0" style={{"background": "transparent", "height": "100vh"}}>
                        &nbsp;
                    </div>

                    <div className="w3-col s12 home--sidebar w3-right w3-animate-right" style={{"background": "#ffffff", "width": "400px", "height": "100vh"}}>
                        <div className="w3-col w3-left home--sidebar2-left">
                            <img src=""
                            className="home--sidebar2-img"
                            onClick={openNotification}
                            style={{"background": `${profileImage}`}}
                            />
                            <p className="home--sidebar2-t1">Notifications</p>
                        </div>
                        <div className="w3-right" style={{"marginTop": "-47px"}}>
                            <p className="home--sidebar2-t2">{props.account}</p>
                        </div>
                        <br/><br/><br/>
                        <div className="" style={{"marginBottom": "18px"}}>
                            <i className="fa-solid fa-bell my-notifications-icon"></i>
                            <span className="my-notifications-t2"><strong>xxx.eth</strong> confirmed your payment. <strong style={{"cursor": "pointer"}}>Rate Now</strong></span>
                        </div>
                        <div className="" style={{"marginBottom": "18px"}}>
                            <i className="fa-solid fa-bell my-notifications-icon"></i>
                            <span className="my-notifications-t2"><strong>xxx.eth</strong> made a dispute on trade <strong style={{"cursor": "pointer"}}>#xxxxxx</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home_Header