import React, { useState } from "react";
import Home_Header from "../../components/Home_Header"
import Home_Footer from "../../components/Home_Footer"
import { Link } from "react-router-dom"

function Privacy(props) {
    const [ account, setAccount ] = useState()

    const headerToOthers1 = (addressData) => {
        setAccount(addressData)
    }
    return(
        <> 
            <Home_Header 
                account = {account}
                balance = {props.balance}
                balance2 = {props.balance2}
                headerToOthers1 = {headerToOthers1}
                apiProfileImage = {props.apiProfileImage}
                apiUsername = {props.apiUsername}
            />
                <div className="legals">
                    <div className="w3-row">
                        <div className="w3-col l3 legals--col1">
                            <p className="legals--t1">LEGAL</p>
                            <div className="legals--sidebar">
                                <Link to="../privacy" className="link" onClick={() => {window.scroll({top: 0, left: 0, behavior: "smooth",})}}><p className="legals--sidebar-text legals--sidebar-text-active">Privacy Policy</p></Link>
                                <Link to="../terms" className="link" onClick={() => {window.scroll({top: 0, left: 0, behavior: "smooth",})}}><p className="legals--sidebar-text">Terms of Use</p></Link>
                                <Link to="../risk-warning" className="link" onClick={() => {window.scroll({top: 0, left: 0, behavior: "smooth",})}}><p className="legals--sidebar-text">Risk Warning</p></Link>
                            </div>
                        </div>
                        <div className="w3-col l9 legals--col2">
                            <p className="legals--t2 w3-animate-top">Privacy Policy</p>
                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>INTRODUCTION</p>
                            <p className="legals--t3 w3-animate-left"> As a customer, user, be assured that at P2P FINANCE LTD. (hereinafter called “P2PFi”, “us”, “we”), we are dedicated to protecting your privacy and providing you with the highest level of security at any point of dealings with us. Hence, why we want you to engage with us knowing that we value your Personal Data and that we protect same. This Privacy Policy explains what information we collect, how we collect, share, use, and protect your personal information when you visit or use this site and other services offered by P2PFi. You are also informed of your rights regarding the information we process and how you can contact us. <br/>
                            By continuing to visit our website (<a href="https://www.p2pfi.co">www.p2pfi.co</a>) and other P2PFi customer/user terminals, you accept and consent to the practices described in this policy.
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>PERSONAL INFORMATION COLLECTED AND COLLECTION METHODS</p>
                            <p className="legals--t3 w3-animate-left">Personal information refers to data that could identify a specific individual such as names, addresses, e-mail addresses, and telephone numbers. We collect information about you from various sources such as websites visits, applications, identification documents, personal financial statements, interactions with our customer information service officers, and other third parties (payment gateways etc.) and other written or electronic communications.
                            <br/>
                            Subject to your medium of interaction with P2PFi, we collect various types of information from you, as described below.<br/>
                            <ul>
                                <li>Personal details (e.g. name, date of birth, passport information or other identification information)</li>
                                <li>Contact details (e.g. phone number, email address, postal address or mobile number);</li>
                                <li>Transactional details (e.g. payments you make and receive)</li>
                                <li>Biometric information (e.g. fingerprints, facial recognition or voice recognition)</li>
                                <li>Financial information (e.g. bank account number, debit card numbers, financial history)
                                including information you provide to deliver payment initiation services and account information services regarding accounts you hold with other providers.
                                </li>
                                <li>Education and employment information;</li>
                                <li>Information about your family, lifestyle and social circumstances (such as dependents,
                                marital status, next of kin and contact details);
                                </li>
                                <li>Visual images and personal appearance (such as copies of passports or CCTV images);</li>
                                <li>Online profile and social media information and activity, subject to your interaction with us and our websites and applications, including for example your banking profile and login information, Internet Protocol (IP) address, smart device information, location coordinates, online and mobile banking security authentication, mobile phone network information, searches, and site visits.</li>
                            </ul>
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>CONDITION FOR PROCESSING PERSONAL DATA</p>
                            <p className="legals--t3 w3-animate-left">P2PFi personnel or any third party acting on its behalf shall only process your personal data if at least one of these conditions are met:
                            <ul>
                                <li>Consent: this refers to any freely given, specific, informed and unambiguous indication through a statement or a clear affirmative action that signifies your agreement to the processing of your Personal Data by P2PFi. We shall not seek consent that may engender direct or indirect propagation of atrocities, hate, criminal acts and anti-social conducts.</li>
                                <li>Contract: processing is necessary for the performance of a contract or entering into a contract at your request.</li>
                                <li>Legal obligation: processing is necessary for compliance with a legal obligation to which P2PFi is subject.</li>
                                <li>Public interest: processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in P2PFi.</li>
                                <li>Vendors, Consultants and Third-party Service Providers: processing is required for legitimate interests of P2PFi or a third party in as much as this does not conflict with the requirements for the protection of your personal data.</li>
                            </ul>
                            
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>HOW WE USE YOUR PERSONAL DATA</p>
                            <p className="legals--t3 w3-animate-left">To the extent permissible under applicable law, we may use your information for the following legitimate actions:
                            <ul>
                                <li>Providing and operating the products and services you have requested</li>
                                <li>For other related purposes which may include updating and enhancing P2PFi records, understanding your financial needs, conducting credit checks, reviewing credit worthiness and assisting other financial institutions to conduct credit checks</li>
                                <li>Identifying and informing you about other products or services that we think might interest you.</li>
                                <li>For crime/fraud prevention and debt collection purposes</li>
                                <li>To plan, execute and monitor P2PFi’s business</li>
                                <li>For improving the design and marketing of our range of services and related products for customer/user use.</li>
                                <li>Compare information for accuracy and to verify it with third parties/publicly available information.</li>
                                <li>Monitor activities at our facilities, including compliance with applicable policies</li>
                                <li>For purposes required by law or regulation</li>
                                <br/>
                                Without your personal information, we may not be able to provide or continue to provide you with the products or services that you may need.
                            </ul>
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>TRANSFER OF PERSONAL DATA</p>
                            <p className="legals--t3 w3-animate-left">Personal data collected by P2PFi may be transferred among its various affiliates. Other than to those individuals and entities listed below, your details will not be revealed by P2PFi to any external body unless P2PFi has your permission or is under either a legal obligation or any other duty to do so.<br/>
                            For the purposes detailed above, your information may be disclosed to:
                            <ul>
                                <li>Any regulatory, supervisory, governmental or quasi-governmental authority with jurisdiction over P2PFi and its staffs.</li>
                                <li>Any agent, contractor or third-party service provider, professional adviser or any other person under a duty of confidentiality to P2PFi</li>
                                <li>Credit reference agencies and, in the event of default, debt collection agencies.</li>
                                <li>Any financial institution with which P2P Finance Ltd. has or proposes to have dealings. The above disclosures may require the transfer of your information to parties located in countries that do not offer the same level of data protection as your home country. <br/>However, P2P Finance Ltd. will ensure that parties to whom your details are transferred treat your information securely and confidentially.</li>
                            </ul>
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>PROTECTION OF YOUR PERSONAL DATA </p>
                            <p className="legals--t3 w3-animate-left">We have implemented appropriate organizational and technical measures (including physical access controls and secure software and operating environments) to keep your Personal Data confidential and secure. Please note, however, that these protections do not apply to information you choose to share in public areas such as third-party social networks. Where we have provided you (or where you have chosen) a password which grants you access to specific areas on our site, you are responsible for keeping this password confidential. We request that you do not to share your password or other authentication details (e.g. token generated codes) with anyone.
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>PERSONAL DATA BREACH NOTIFICATION</p>
                            <p className="legals--t3 w3-animate-left">P2PFi will at any point in time inform relevant authorities and if necessary affected individuals of personal data breach within 72 hours of being aware of the breach, where Personal Breach refers to a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, personal data. This includes breaches that are the result of both accidental and deliberate causes. Remedies shall include but not limited to investigating and reporting to appropriate authorities, recovering the personal data, correcting it and/or enhancing controls around it.
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>YOUR RIGHTS</p>
                            <p className="legals--t3 w3-animate-left">P2P Finance Ltd. would like to make sure you are fully aware of all your data protection rights. Every customer/user is entitled to the following:
                            <ul>
                                <li>The right to be informed - To emphasize the need for transparency over the usage of personal data, we ensure fair processing of information typically through this privacy policy.</li>
                                <li>The rights to access - You reserve the right to request from P2PFi for copies of your personal data where those requests are reasonable and permitted by law or regulation. We may charge you a fee for this service.</li>
                                <li>The right to rectification - You reserve the right to request that P2PFi correct any information you believe is inaccurate. You also have the right to request P2PFi to complete information you believe is incomplete.</li>
                                <li>The right to restrict processing - You have a right to ‘block’ or withdraw your consent to our processing of your information, which you can do at any time. When processing is restricted, we are permitted to store the personal data, but not further process it.</li>
                                <li>- The right to erasure - You reserve the right to request the deletion or removal of personal data where there is no compelling legal or regulatory requirement for its continued processing. P2PFi will ensure that this right is protected.</li>
                                <li>The right to data portability - You have the right to request that the bank transfer the data that we have collected to another organization, or directly to you, under certain conditions. We will ensure that personal data is moved, copied or transferred easily from one IT environment to another in a safe and secure way, without hindrance to usability.</li>
                                <li>The right to object - You have the right to object to our processing of your information if there are compelling legitimate grounds to do so and to the extent permitted by law or regulation.</li>
                                <br/>
                                To exercise your right(s), please contact the Legal & Compliance Officer of P2P Finance Ltd.
                                <br/>
                                You also have the right to:<br/><br/>
                                <li>The right to receive your Personal Data in a commonly used and machine-readable format and the right to transmit these data to another Data Controller when the processing is based on (explicit) consent or when the processing is necessary for the performance of a contract.</li>
                                <li>The right to lodge a complaint with the National Information Technology Development Agency (NITDA) where you believe our processing of your data violates the requirements of the Nigeria Data Protection Regulation 2019 (NDPR).</li>
                            </ul>
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>PERSONAL DATA RETENTION PENDENCY</p>
                            <p className="legals--t3 w3-animate-left">Information held about you is retained if the purpose for which the information was collected continues. The information is then destroyed unless its retention is required to satisfy legal, regulatory or accounting requirements or to protect P2PFi’s interest. Please note that regulations may require the P2PFi to retain your personal data for a specified period of time even after the end of your dealing and/or relationship with us. It is your responsibility to maintain the secrecy of any user ID and login password you hold.
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>MAINTAINING ACCURATE INFORMATION</p>
                            <p className="legals--t3 w3-animate-left">You are responsible for making sure the information provided to us is accurate and should inform us on any changes as it occurs, this will enable us to update your information with us.
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>. ELECTRONIC COMMUNICATION</p>
                            <p className="legals--t3 w3-animate-left">In order to maintain the security of our systems, protect our staff, record transactions, and, in certain circumstances, to prevent and detect crime or unauthorized activities, P2PFi reserves the right to monitor all electronic communications to make sure that they comply with our legal and regulatory responsibilities and internal policies.
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>SOCIALS</p>
                            <p className="legals--t3 w3-animate-left">We operate and communicate through our designated channels, pages and accounts on some social media sites to inform, help and engage with our customers. We monitor and record comments and posts made about us on these channels so that we can improve our services. The general public can access and read any information posted on these sites. Please note that any content you post to such social media platforms is subject to the applicable social media platform’s terms of use and privacy policies. We recommend that you review the information carefully in order to better understand your rights and obligations regarding such content.
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>COMMUNICATION</p>
                            <p className="legals--t3 w3-animate-left">In providing your telephone, facsimile number, postal and e-mail address or similar details, you agree that P2P Finance Ltd. may contact you by these methods to keep you informed about P2PFi products and services or for any other reason. If you prefer not to be kept informed of P2PFi products and services, please contact us by E-mail (hello@p2pfi.co) or through any of our socials
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>LINKS TO OTHER WEBSITES</p>
                            <p className="legals--t3 w3-animate-left">Our website, related websites and mobile applications may have links to or from other websites. Although we try to link only to websites that also have high privacy standards, we are not responsible for their security, privacy practices or content. We recommend that you always read the privacy and security statements on these websites.
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>POLICY UPDATES</p>
                            <p className="legals--t3 w3-animate-left">We reserve the right to amend our prevailing Data Protection and Privacy Statement at any time and will publish such amendments on our website (www.p2pfi.co). The latest version of our privacy statement will replace all earlier versions, unless it says otherwise. You may wish to check frequently to see any updates or changes to our Notice. This policy is not intended to, nor does it, create any contractual rights whatsoever or any other legal rights, nor does it create any obligations on P2P Finance Ltd. in respect of any other party or on behalf of any party.
                            </p>

                            <p className="w3-animate-right" style={{"fontWeight": "600", "fontSize": "24px"}}>PRIVACY CONTACT INFORMATION</p>
                            <p className="legals--t3 w3-animate-left">If you have any questions, concerns, or comments about our privacy policy, you may contact our Legal & Compliance Officer on <a href="mailto:legal@p2pfi.co">legal@p2pfi.co</a>
                            <br/><br/>
                            If you have any further questions or comments about us or our policies, please do not hesitate to contact us.
                            <br/><br/>
                            Email us at: <a href="mailto:legal@p2pfi.co">hello@p2pfi.co</a>
                            </p>
    
                        </div>
                    </div>
                </div>
            <Home_Footer />
        </>
    )
}

export default Privacy