import React from "react";
import { open_s_p } from "../pages/settings/Settings"
import { open_s_n } from "../pages/settings/Settings"
import { open_s_a } from "../pages/settings/Settings"
import { open_s_b } from "../pages/settings/Settings"

function Settings_Sidebar() {

        const openSide = () => {
            var x = document.getElementById("settings-side")
            if (x.className.indexOf("w3-show") == -1) {
                x.className += " w3-show";
              } else { 
                x.className = x.className.replace(" w3-show", "");
              }
        }


    return(
        <>
            <div className="w3-sidebar w3-bar-block settings--sidebar w3-animate-left" id="settings-side">
                <p className="settings--sidebar-t1">SETTINGS</p>
                <div id="scripted" tabIndex={0} className="w3-bar-item settings--sidebar-div scripted" onClick={open_s_p}>
                <i className="fa-solid fa-user settings--sidebar-icon"></i>
                    <span className="settings--sidebar-t2">Profile</span>
                </div>
                <div id="scripted2" tabIndex={-1}  className="w3-bar-item settings--sidebar-div scripted " onClick={open_s_n}>
                    <i className="fa-solid fa-bell settings--sidebar-icon"></i>
                    <span className="settings--sidebar-t2">Notifications</span>
                </div>
                <div id="scripted3" tabIndex={-2}  className="w3-bar-item settings--sidebar-div scripted" onClick={open_s_a}>
                    <i className="fa-solid fa-shield settings--sidebar-icon"></i>
                    <span className="settings--sidebar-t2">Account Support</span>
                </div>
                <div id="scripted4" tabIndex={-3}  className="w3-bar-item settings--sidebar-div scripted" onClick={open_s_b}>
                    <i className="fa-solid fa-building-columns settings--sidebar-icon"></i>
                    <span className="settings--sidebar-t2">Bank Account</span>
                </div>
            </div>

            <div className="">
                <i onClick={openSide} className="fa-solid fa-bars w3-hide-large settings--side-bar"></i>
            </div>

        </>
    )
}

export default Settings_Sidebar