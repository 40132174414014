import React from "react";
import { Link } from "react-router-dom"

function Home_Footer() {

    const accordion = () => {
        var acc = document.getElementsByClassName("accordion");
        var i;

        for (i = 0; i < acc.length; i++) {
        acc[i].addEventListener("click", function() {
            this.classList.toggle("active");
            var panel = this.nextElementSibling;
            if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
            } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
            }
        });
        }
    }

    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/634378dc37898912e96db7b4/1gevp084e';
    s1.charset='UTF-8'; 
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();

    return(
        <>
            <div className="home--footer w3-hide-small w3-hide-medium">
                <div className="">
                    <p className="home--t5">Business</p>
                    {/* <p className="home--t6">About Us</p> */}
                    <Link to="../roadmap" className="link" onClick={() => {window.scroll({top: 0, left: 0, behavior: "smooth",})}}><p className="home--t6">Road Map</p></Link>
                    {/* <p className="home--t6">Careers</p> */}
                    {/* <p className="home--t6">Community</p> */}
                    <p className="home--t6"><a href="https://medium.com/@p2pfi" target="_blank" rel="noreferrer" className="link">Blog</a></p>
                </div>
                <div className="">
                    <p className="home--t5">Product</p>
                    <Link to="../home" className="link" onClick={() => {window.scroll({top: 0, left: 0, behavior: "smooth",})}}><p className="home--t6">Exchange</p></Link>
                    {/* <p className="home--t6">Academy</p> */}
                    {/* <p className="home--t6">Charity</p> */}
                </div>
                <div className="">
                    <p className="home--t5">Support</p>
                    {/* <p className="home--t6">Give us Feedback</p> */}
                    <p className="home--t6"><a href="https://p2pFi.tawk.help" target="_blank" rel="noreferrer" className="link">Help Center</a></p>
                    {/* <p className="home--t6">Live Chat</p> */}
                </div>
                <div className="">
                    <p className="home--t5">Legal</p>
                    <Link to="../terms" className="link" onClick={() => {window.scroll({top: 0, left: 0, behavior: "smooth",})}}><p className="home--t6">Terms of Use</p></Link>
                    <Link to="../privacy" className="link" onClick={() => {window.scroll({top: 0, left: 0, behavior: "smooth",})}}><p className="home--t6">Privacy Policy</p></Link>
                    <Link to="../risk-warning" className="link" onClick={() => {window.scroll({top: 0, left: 0, behavior: "smooth",})}}><p className="home--t6">Risk Warning</p></Link>
                </div>
                <div className="">
                    <p className="home--t5">Contact</p>
                    <p className="home--t6"><a href="mailto:hello@p2pfi.co" className="link">hello@p2pfi.co</a></p>
                </div>
            </div>
            <div className="home--footer-foot w3-hide-small w3-hide-medium">
                <div className="w3-left">
                    <p>© P2PFi™</p>
                </div>
                <div className="w3-right home--footer-icons">
                    <span><a href="https://t.me/p2pfi" target="_blank" rel="noreferrer"><i className="fa-brands fa-telegram home--footer-icon"></i></a></span>
                    <span><a href="https://www.instagram.com/p2pfi/" target="_blank" rel="noreferrer"><i className="fa-brands fa-square-instagram home--footer-icon"></i></a></span>
                    {/* <span><a href="#"><i className="fa-brands fa-discord home--footer-icon"></i></a></span> */}
                    <span><a href="https://twitter.com/p2pfi" target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter home--footer-icon"></i></a></span>
                    <span><a href="https://www.reddit.com/r/p2pfi/" target="_blank" rel="noreferrer"><i className="fa-brands fa-reddit home--footer-icon"></i></a></span>
                    {/* <span><a href="#"><i className="fa-brands fa-youtube home--footer-icon"></i></a></span> */}
                </div>
            </div>

            <div className="accordions w3-hide-large">
                <button onClick={accordion} className="accordion">Business</button>
                <div className="panel">
                    {/* <p className="home--t6-2">About Us</p> */}
                    <Link to="../roadmap" className="link" onClick={() => {window.scroll({top: 0, left: 0, behavior: "smooth",})}}><p className="home--t6-2">Road Map</p></Link>
                    {/* <p className="home--t6-2">Careers</p> */}
                    {/* <p className="home--t6-2">Community</p> */}
                    <p className="home--t6-2"><a href="https://medium.com/@p2pfi" target="_blank" rel="noreferrer" className="link">Blog</a></p>
                </div>

                <button onClick={accordion} className="accordion">Product</button>
                <div className="panel">
                <Link to="../home" className="link" onClick={() => {window.scroll({top: 0, left: 0, behavior: "smooth",})}}><p className="home--t6-2">Exchange</p></Link>
                    {/* <p className="home--t6-2">Academy</p> */}
                    {/* <p className="home--t6-2">Charity</p> */}
                </div>

                <button onClick={accordion} className="accordion">Support</button>
                <div className="panel">
                {/* <p className="home--t6-2">Give us Feedback</p> */}
                <p className="home--t6-2"><a href="https://p2pFi.tawk.help" target="_blank" rel="noreferrer" className="link">Help Center</a></p>
                {/* <p className="home--t6-2">Live Chat</p> */}
                </div>

                <button onClick={accordion} className="accordion">Legal</button>
                <div className="panel">
                    <Link to="../terms" className="link" onClick={() => {window.scroll({top: 0, left: 0, behavior: "smooth",})}}><p className="home--t6-2">Terms of Use</p></Link>
                    <Link to="../privacy" className="link" onClick={() => {window.scroll({top: 0, left: 0, behavior: "smooth",})}}><p className="home--t6-2">Privacy Policy</p></Link>
                    <Link to="../risk-warning" className="link" onClick={() => {window.scroll({top: 0, left: 0, behavior: "smooth",})}}><p className="home--t6-2">Risk Warning</p></Link>
                </div>

                <button onClick={accordion} className="accordion">Contact</button>
                <div className="panel">
                <p className="home--t6-2"><a href="mailto:hello@p2pfi.co" className="link">hello@p2pfi.co</a></p>
                </div>

                <button onClick={accordion} className="accordion">Socials</button>
                <div className="panel">
                    <div className="home--footer-icons2">
                    <span><a href="https://t.me/p2pfi" target="_blank" rel="noreferrer"><i className="fa-brands fa-telegram home--footer-icon2"></i></a></span>
                        <span><a href="https://www.instagram.com/p2pfi/" target="_blank" rel="noreferrer"><i className="fa-brands fa-square-instagram home--footer-icon2"></i></a></span>
                        {/* <span><a href="#"><i className="fa-brands fa-discord home--footer-icon2"></i></a></span> */}
                        <span><a href="https://twitter.com/p2pfi" target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter home--footer-icon2"></i></a></span>
                        <span><a href="https://www.reddit.com/r/p2pfi/" target="_blank" rel="noreferrer"><i className="fa-brands fa-reddit home--footer-icon2"></i></a></span>
                        {/* <span><a href="#"><i className="fa-brands fa-youtube home--footer-icon2"></i></a></span> */}
                    </div>
                </div>

                <p className="home--t6-2-2">© P2PFI™</p>

            </div>

        </>
    )
}

export default Home_Footer