import React from "react";
import Main from "./pages/Main";
import { useEffect, useState } from "react";
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { configureChains, createClient, WagmiConfig, createStorage  } from "wagmi";
import { mainnet, polygon, optimism, polygonMumbai } from 'wagmi/chains'
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'

import "./w3.css"
import "./style.css"
import "./styles/home.css"
import "./styles/profile.css"
import "./styles/settings.css"
import "./styles/coming-soon.css"
import "./styles/legals.css"
import "./styles/roadmap.css"
import "./styles/connectwallet.css"
import "./styles/sell-offers.css"
import "./styles/buy-offers.css"
import "./styles/offer-details.css"
import "./styles/nopage.css"
import axios from "axios";

import { Buffer } from "buffer";

export const contractAddress = "0x7e9174D86E615af58bccb8b8f368D817c3A6537A"

window.Buffer = window.Buffer || Buffer;

function App() {

  const { chains, provider, webSocketProvider  } = configureChains(
    [polygonMumbai],
    [alchemyProvider({ alchemyId: "HebaGukTIIciqBAS5dHaaN1hqejM19O1" }), publicProvider()]
  );

  const client = createClient({
    autoConnect: true,
    connectors: [
      new MetaMaskConnector({ chains }),
      new CoinbaseWalletConnector({
        chains,
        options: {
          appName: 'wagmi',
        },
      }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
        },
      }),
      new InjectedConnector({
        chains,
        options: {
          name: 'Injected',
          shimDisconnect: true,
        },
      }),
    ],
    provider,
    storage: createStorage({ storage: window.localStorage }),
    webSocketProvider,
  })

  return (
    <>
      <WagmiConfig client={client}>
        <Main />
      </WagmiConfig>
    </>
  );
}

export default App;
