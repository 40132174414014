import React, { useState } from "react";
import { Link } from "react-router-dom"
import Home_Header from "../components/Home_Header";
import Home_Footer from "../components/Home_Footer";

function NoPage(props) {

    const [ account, setAccount ] = useState()

    const headerToOthers1 = (addressData) => {
        setAccount(addressData)
    }

    return(
        <>
            <Home_Header 
                account = {account}
                balance = {props.balance}
                balance2 = {props.balance2}
                headerToOthers1 = {headerToOthers1}
                apiProfileImage = {props.apiProfileImage}
                apiUsername = {props.apiUsername}
            />
                <div className="no--page">
                    <div className="w3-row">
                        <div className="w3-col l6 m6 s12 no--page-col1">
                            <p className="no--page-t1">OOPS!</p>
                            <p className="no--page-t2">We couldn’t find that page.</p>
                            <Link to="/profile" className="link"><button className="no--page-btn">Back to home</button></Link>
                        </div>
                        <div className="w30-col l6 m6 s12 no--page-col2">
                            <img src="/images/404.png"
                            className="no--page-img"
                            alt="404"
                            />
                        </div>
                    </div>


                    {/* <select onChange={e => setMethod(e.target.value)} value={method}>
                        <option value="">Select Method</option>
                        <option value="Bank Account">Bank Account</option>
                        <option value="Abeg">Abeg</option>
                    </select>

                    <input 
                    placeholder="enter description"
                    onChange={e => setDescription(e.target.value)}
                    value={description}
                    />

                    <button onClick={handleBla}>HandleBla</button>
                    <br/>
                    <a href={"https://p2pfiv1.infura-ipfs.io/ipfs/" + metaDataUri} target="_blank">{metaDataUri}</a> */}
                </div>
            <Home_Footer />
        </>
    )
}

export default NoPage