import React, { useState } from "react";
import { useDisconnect, useAccount } from "wagmi"

function HomeSideBar(props) {

    const { isDisconnected } = useAccount()
    const { disconnect } = useDisconnect()

    const [ copyClass, setCopyClass ] = useState("fa-regular fa-clipboard")

    const add_funds = () => {
        document.getElementById("add-funds").style.display = "block"
        document.getElementById("sidebar").style.display = "none"
    }

    const close_add_funds = () => {
        document.getElementById("add-funds").style.display = "none"
        document.getElementById("sidebar").style.display = "block"
    }

    function copyFunc() {
        /* Get the text field */
        var copyText = document.getElementById("myInput");
      
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /* For mobile devices */
      
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.value);
        setCopyClass("fa-solid fa-check")
        setTimeout(() => {
            setCopyClass("fa-regular fa-clipboard")
        }, 2000)
      }

       function OnChange() {
        document.getElementById("myInput").ariaReadOnly = "true";
       }

      const imageURL = "https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=" + "https://mumbai.polygonscan.com/address/" + props.account + "&choe=UTF-8"

    return (
        <>

            {/* Sidebar on Desktop */}
            <div className="w3-row w3-sidebar home--sidebar w3-bar-block" id="sidebar" style={{"display": "block", "width": "100%", "background": 'transparent'}}>
                <div onClick={props.openSidebar} className="w3-left w3-col l9 w3-animate-right" style={{"backgroundColor": "transparent", "height": "100vh"}}>
                    &nbsp;
                </div>

                <div className="w3-col home--sidebar w3-right w3-animate-right" style={{"background": "#ffffff", "height": "90vh"}}>
                    <div className="w3-left home--sidebar-left">
                        <img src=""
                        className="home--sidebar-img"
                        style={{"background": `${props.profileImage}`}}
                        />
                        <p className="home--sidebar-t1">{props.apiUsername ? props.apiUsername : "My Wallet"}</p>
                    </div>
                    <div className="w3-right">
                        <p className="home--sidebar-t2">{props.account}</p>
                    </div>
                    <div className="home--sidebar-hero">
                        <p className="home--sidebar-t3">Total Balance</p>
                        <p className="home--sidebar-t4">₦ {(props.totalBalance * 740).toFixed(2)}</p>
                        <p onClick={add_funds} className="home--sidebar-t5" style={{"cursor": "pointer"}}>Add Funds</p>
                    </div>

                    <div className="">
                        <div className="w3-left" style={{"position": "relative", "top": "24px"}}>
                        <i className="">
                                <img src="/images/matic.png"
                                className=""
                                style={{"width": "28px"}}
                                />
                            </i>
                            <span style={{"left": "8px", "top": "-8px"}} className="home--sidebar-t6">MATIC</span><br/><span style={{"top": "-18px"}} className="home--sidebar-t7">Polygon (MATIC)</span>
                        </div>
                        <div className="w3-right">
                            <span className="home--sidebar-t8">{props.balance}</span><br/><span className="home--sidebar-t9">{(props.balance * 0.9415 * 740).toFixed(1)} NGN</span>
                            <span><i className="fa-solid fa-ellipsis-vertical home--sidebar-icon2"></i></span>
                        </div>
                        <br/><br/>
                    </div>

                    <hr style={{"marginTop": "28px", "marginBottom": "0"}}/>

                    <div className="">
                        <div className="w3-left" style={{"position": "relative", "top": "24px"}}>
                            <i className="">
                                <img src="/images/usdc.png"
                                className=""
                                style={{"width": "28px"}}
                                />
                            </i>
                            <span className="home--sidebar-t6" style={{"left": "8px", "top": "-8px"}}>USDC</span><br/><span style={{"top": "-18px"}} className="home--sidebar-t7">Polygon (MATIC)</span>
                        </div>
                        <div className="w3-right">
                            <span className="home--sidebar-t8">{props.token1}</span><br/><span className="home--sidebar-t9">{(props.token1 * 0.00001 * 740).toFixed(1)} NGN</span>
                            <span><i className="fa-solid fa-ellipsis-vertical home--sidebar-icon2"></i></span>
                        </div>

                        <div id="profile--open_profile_menu" className="profile--menu w3-dropdown-content w3-bar-block w3-card-4 w3-animate-zoom">
                            <p className="w3-bar-item"><i className="fa fa-gear profile--icon2c"></i><span className="profile--menu-t">Setting</span></p>
                            <hr/>
                            <p className="w3-bar-item"><i className="fa fa-flag profile--icon2c"></i><span className="profile--menu-t">Report</span></p>
                            <hr/>
                            <p className="w3-bar-item"><img src="images/profile_featured.png" className="profile--icon2cc profile--icon2c"/><span className="profile--menu-t">Featured items</span></p>
                        </div>
                        <br/><br/>
                    </div>
                    
                    <hr style={{"marginTop": "28px", "fontSize": "20px", "border": "1px solid #00000080", "borderRadius": "20px"}}/>
                    
                    <div style={{"marginTop": "-24px"}}>
                        {props.balance2}
                    </div>
                    <br/><br/>
                    {
                        isDisconnected ? "" : <button onClick={disconnect} align="right" style={{"width": "100%", "border": "none", "backgroundColor": "#FA9334", "color": "#000", "padding": "12px 0", "borderRadius": "6px"}}>Disconnect</button>
                    }
                </div>


                

            </div>



            <div className="w3-modal" id="add-funds">
                <div className="w3-modal-content w3-bar-block home--add-funds w3-animate-right">
                    <i onClick={close_add_funds} className="fa-solid fa-angles-left join-wait--icon"></i>
                    <p className="home--add-funds-t1">Add Funds</p>
                    <img src={imageURL}
                    className="home--add-funds-img"
                    alt="QR"
                    />
                    <p className="home--add-funds-t2">Transfer funds from an exchange info or another wallet to your wallet address below:</p>
                    <input
                    type="text"
                    className="home--add-funds-input"
                    placeholder={props.account}
                    onChange={OnChange}
                    disabled
                    readOnly
                    />
                    {/*Copied Input */}
                    <input
                    id="myInput"
                    placeholder={props.account}
                    style={{"display": "none"}}
                    disabled
                    readOnly
                    />

                    <span><i onClick={copyFunc} className={copyClass + " home--add-funds-icon"}></i></span>
                </div>
            </div>
        </>
    )
}

export default HomeSideBar